import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPercentage } from "../../Slices/data";
import Progress from "../steper_components/Progress";

const UploadingProgress = ({ handleBtn }) => {
  const [progress, setProgress] = useState(0);
  const [int, setInt] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 20);
    }, 100);
    setInt(interval);
  }, []);
  useEffect(() => {
    if (progress == 80) {
      return () => {
        clearInterval(int);
        navigate("/collection-detail")
      };
    }
  }, [progress]);

  return (
    <div className="border-4 border-spacing-4 border-dashed  mb-6 ">
      <div className="flex flex-col justify-center items-center md:h-[450px]">
        <div className="flex justify-center items-center">
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={progress}
              text={`${progress}%`}
              strokeWidth={2}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `#F05428`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "square",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: "#d6d6d6",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the text
                text: {
                  // Text color
                  fill: "#F05428",
                  // Text size
                  fontSize: "16px",
                },
                // Customize background - only used when the `background` prop is true
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadingProgress;
