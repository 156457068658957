import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { byteCode } from "./constants/byteCode";
import { abi } from "./constants/deployContract";
import { connectWeb3, getProjects } from "../../Slices/data";
import { useSelector } from "react-redux";
import { openCustomNotificationWithIcon } from "../../utils/Notification";
import { ClipLoader } from "react-spinners";
import noProject from "../../assets/noProject.png";
import {
  addDeployContractAddressInDB,
  createSolFile,
  getByteCodeAndAbi,
} from "../../Slices/contract";
import { cloneDeep } from "lodash";
import { CopyOutlined } from "@ant-design/icons";
import { user } from "../../Slices/Auth";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import { getAccounts } from "./connectWeb3";
import Web3 from "web3";
import DynamicFIeld from "./DynamicFIeld";

const ContractForm = ({ cid, cancel, externalContract, projectId }) => {
  const cidRef = useRef(null);
  const dispatch = useDispatch();
  const acc = useSelector((state) => state.storefiles.address);
  const solFile = useSelector((state) => state.smartContract.solidityFileData);
  const user = useSelector((state) => state.auth.user);
  const [inputValue, setInputValue] = useState({
    cid: cid || "",
    collectionName: "",
    collectionSymbol: "",
    maxSupply: null,
    maxMintAmount: null,
    preSalePrice: null,
    publicSalePrice: null,
    preSaleLimit: null,
    publicSaleLimit: null,
    addresses: [{ address: "", percentage: 0 }] || [],
  });
  let [verifyLink, setVerifyLink] = useState("");
  let [isVerify, setIsVerify] = useState(false);
  let [isLoading, setLoading] = useState(false);
  let [isWalletConnect, setIsWalletConnect] = useState(false);
  let [titleCopy, setTitleCopy] = useState("Click to Copy");
  let [deployAddress, setDeployAddress] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(0);

  const navigate = useNavigate();

  console.log(+selectedNetwork, "selectedNetwork");

  const onNetworkChange = (value) => {
    setSelectedNetwork(value);
  };

  console.log(acc, "connect acccc");
  console.log(inputValue, "inputFieldssss");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      number: "${label} is not a valid number!",
      email: "${label} is not a valid email!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChangeHandler = async (e) => {
    // if(e.target.name === "publicSalePrice"){
    //   setInputValue({ ...inputValue, [e.target.name]: parseFloat(e.target.value) });
    //   return
    // }
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const getWalletAddress = () => {
    console.log("getWalletAddress");
    dispatch(connectWeb3(+selectedNetwork))
      .unwrap()
      .then(async (x) => {
        console.log(x, "connectWeb3");
        if (x.success === false) {
          openCustomNotificationWithIcon("error", x.message);
          return;
        } else {
          openCustomNotificationWithIcon("success", x.message);
          getAccounts()
            .then((x) => {
              console.log(x, "accountadresss");
            })
            .catch((err) => console.log(err, "ereoweaad"));
          setIsWalletConnect(true);
        }
      });
  };

  const onSubmit = async () => {
    console.log(inputValue, "ASDASDADAAAAAAAAAA");
    setLoading(true);
    dispatch(createSolFile(inputValue))
      .unwrap()
      .then(async (data) => {
        console.log(data, "onSubmitContForm");
        await getWalletAddress();
        setLoading(false);
      });
  };

  const deployContract = async () => {
    if (acc.success === false) {
      openCustomNotificationWithIcon("error", acc.message);
    } else {
      try {
        const web3 = new Web3(window.ethereum);
        let {
          collectionName,
          collectionSymbol,
          maxMintAmount,
          maxSupply,
          preSaleLimit,
          preSalePrice,
          publicSaleLimit,
          publicSalePrice,
        } = inputValue;

        // dispatch(getByteCodeAndAbi(solFile)).unwrap().then(res => {
        //   console.log(res, "ContactFormByteCodeResponse");
        // })
        console.log(solFile.output, "solidityfile");
        const genAbi = solFile?.output?.contracts["GENFTY.sol"]["GENFTY"]?.abi;
        const genByteCode =
          solFile?.output?.contracts["GENFTY.sol"]["GENFTY"]?.evm?.bytecode
            ?.object;
        console.log(genAbi, "genAbi");
        console.log(genByteCode, "genByteCode");

        console.log(web3, "WEB3");
        let preSalePrice_ = web3.utils?.toWei(preSalePrice);
        let publicSalePrice_ = web3.utils?.toWei(publicSalePrice);
        // console.log(publicSalePrice_, "publicSalePrice_");
        const neAbi = genAbi?.map((x) => {
          return cloneDeep(x);
        });

        const contract = new web3.eth.Contract(neAbi);
        console.log(contract, "contractttt");
        // console.log("deployContract:contract", acc);
        // const prefixURI_ =
        // "https://gateway.pinata.cloud/ipfs/QmerrQyPBz7rZw1wLrLMBDaV8s6bTS57LoRSqHJQEHy4T6/";

        setLoading(() => true);
        let res = await contract
          .deploy({
            data: genByteCode,
            arguments: [],
          })
          .send({
            from: acc.accounts,
          });

        // contract.options.address = res._address
        // await contract.methods.setSaleStarted(true).send({ from: acc });

        // let res = await contract
        //   .deploy({
        //     data: byteCode,
        //     arguments: [
        //       collection_Name,
        //       collection_Symbol,
        //       totalSupply,
        //       MAX_MINT_PER_TX,
        //       prefixURI_,
        //       // JSON.parse(whiteList),
        //       // PreSaleLimit,
        //       publicLimit,
        //       preSalePrice_,
        //       publicSalePrice_,
        //     ],
        //   })
        //   .send(
        //     {
        //       from: acc,
        //       // gas: 1500000,
        //       // gasPrice: "30000000000000",
        //     },
        //     function (error, transactionHash) {
        //       console.log("into then", error, transactionHash);
        //     }
        //   )
        //   .on("error", function (error) {
        //     console.log(error, "error");
        //   })
        //   .on("transactionHash", function (transactionHash) {
        //     console.log("transactionHash", transactionHash);
        //   })
        //   .on("receipt", function (receipt) {
        //     console.log("receipt", receipt.contractAddress); // contains the new contract address
        //   })
        //   .on("confirmation", function (confirmationNumber, receipt) {
        //     console.log("confirmationNumber", confirmationNumber);
        //   })
        //   .then(function (newContractInstance) {
        //     console.log(
        //       "newContractInstance",
        //       newContractInstance.options.address
        //     );
        //   });
        // console.log("resss", JSON.strigfy(res));

        // un comment
        console.log("res", res);
        let { _address } = res;
        let explorerUrl;
        if (+selectedNetwork === 1) {
          explorerUrl = `https://etherscan.io/address/${_address}`; // Mainnet Etherscan URL
        } else if (+selectedNetwork === 137) {
          explorerUrl = `https://polygonscan.com/address/${_address}`; // Ropsten Testnet Etherscan URL
        } else if (+selectedNetwork === 56) {
          explorerUrl = `https://bscscan.com/address/${_address}`; // Rinkeby Testnet Etherscan URL
        } else {
          explorerUrl = `https://sepolia.etherscan.io/tx/${_address}`;
        }
        // Add more conditions for other networks if needed

        //   let {_address} = res.Contracts
        // console.log("_address", _address);
        // https://testnet.bscscan.com//verifyContract?a=0xDd4fA0Ef2C0E42Cd227c4DC94C1DD40B53d5AD90

        // un comment
        dispatch(
          addDeployContractAddressInDB({
            id: user.id,
            cid: inputValue.cid,
            address: _address,
            layout: 1,
            projectId: projectId,
          })
        );
        openCustomNotificationWithIcon("success", "Project is live now");
        console.log("contract", _address);

        setVerifyLink(explorerUrl);
        setIsVerify(true);
        setDeployAddress(_address);
        dispatch(getProjects());
        setLoading(() => false);
      } catch (error) {
        console.log(error, "ERRRRORRORORRO");
        if (error.code === 4001) {
          openCustomNotificationWithIcon(
            "warning",
            "For Contract Deploy, Accept the Transaction",
            3
          );
        } else {
          openCustomNotificationWithIcon(
            "error",
            "Can't Deploy Contract, Please Try Later",
            3
          );
        }
        setLoading(() => false);
      }
    }
  };

  const cancelBeforeDeploy = () => {
    if (isLoading) return;
    setIsWalletConnect(false);
    cancel();
  };

  const cancelAfterDeploy = () => {
    setIsVerify(false);
    setVerifyLink("");
    cancel();
  };

  const handleCopyText = (cid) => {
    navigator.clipboard.writeText(cid);
    setTitleCopy("Copied");
  };

  if (isVerify) {
    // if(true){
    return (
      <div
        className="fixed z-50 top-0 left-0 bg-gray-200 h-full min-h-screen w-full flex justify-center items-center"
        style={{ background: "rgba(0,0,0,0.2)" }}
      >
        <div className="md:w-7/12 relative bg-white w-9/12 min-h-40 h-fit p-6 pr-10 rounded overflow-y-auto mt-8 mb-8 flex flex-col items-center">
          <div
            className={`absolute right-6 cursor-pointer top-6 ${
              isLoading ? "" : "hover:text-red-600"
            }`}
            onClick={cancelAfterDeploy}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <img src={noProject} alt="" width={300} />
          <div className="flex justify-center mt-8 mb-4">
            <button
              onClick={() => {
                navigate("/mint-page-select", {
                  state: { cid: inputValue.cid, address: deployAddress },
                });
              }}
              className={`h-fit flex mr-4 justify-end rounded-2xl border mt-2 border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm ${
                isLoading
                  ? ""
                  : "hover:bg-gray-700 focus:bg-gray-700 focus:ring-gray-500"
              } focus:outline-none focus:ring-2 focus:ring-offset-2`}
            >
              Create Mint
            </button>
            <a
              href={verifyLink}
              rel="noreferrer"
              target="_blank"
              className={`h-fit flex justify-end rounded-2xl border mt-2 border-transparent hover:text-white bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Your Deployed Contract
            </a>
          </div>
        </div>
      </div>
    );
  }
  if (isWalletConnect) {
    return (
      <div
        className="fixed z-50 top-0 left-0 bg-gray-200 h-full min-h-screen w-full flex justify-center items-center"
        style={{ background: "rgba(0,0,0,0.2)" }}
      >
        <div className="md:w-7/12 relative bg-white w-9/12 min-h-40 h-fit p-6 pr-10 rounded overflow-y-auto mt-8 mb-8 flex flex-col items-center">
          <div
            className={`absolute right-6 cursor-pointer top-6 ${
              isLoading ? "" : "hover:text-red-600"
            }`}
            onClick={cancelBeforeDeploy}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <img src={noProject} alt="" width={300} />
          <div className="flex justify-center mt-8 mb-4">
            <button
              disabled={isLoading}
              onClick={() => {
                setIsWalletConnect(false);
              }}
              className={`h-fit flex mr-4 justify-end rounded-2xl border mt-2 border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm ${
                isLoading
                  ? ""
                  : "hover:bg-gray-700 focus:bg-gray-700 focus:ring-gray-500"
              } focus:outline-none focus:ring-2 focus:ring-offset-2`}
            >
              Back
            </button>
            <button
              disabled={isLoading}
              onClick={deployContract}
              className={`h-fit flex justify-end rounded-2xl border mt-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Deploy Contract
              <span>
                <ClipLoader
                  cssOverride={{
                    display: "block",
                    marginLeft: "1rem",
                    borderColor: "white",
                  }}
                  color={"black"}
                  loading={isLoading}
                  size={20}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  let {
    collectionName,
    collectionSymbol,
    maxMintAmount,
    maxSupply,
    preSaleLimit,
    preSalePrice,
    publicSaleLimit,
    publicSalePrice,
  } = inputValue;
  return (
    <div
      className="fixed z-50 top-0 left-0 h-full min-h-screen w-full flex justify-center items-center"
      style={{ background: "rgba(0,0,0,0.2)" }}
    >
      <div className="md:w-4/5  w-4/5 bg-gray-50 h-full p-6 pr-10 rounded overflow-y-auto mt-16 mb-8">
        <h2 className="text-3xl font-bold">Connect Wallet</h2>
        <Form
          initialValues={inputValue}
          // initialValue={inputValue}
          className="space-y-6"
          validateMessages={validateMessages}
          onFinish={onSubmit}
          // layout="inline"
        >
          <Row>
            <Col
              xs={{
                span: 5,
                offset: 1,
              }}
              lg={{
                span: 6,
                offset: 2,
              }}
            >
              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="cid"
                rules={[{ required: true }]}
                label={
                  <span className="text-gray-800 font-medium">IPFS CID</span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-l flex">
                  <Input
                    name="cid"
                    size="large"
                    placeholder={"Enter CID"}
                    disabled={cid ? true : false}
                    style={{
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      width: "90%",
                    }}
                    onChange={onChangeHandler}
                    value={inputValue.cid}
                  />
                  <Tooltip title={titleCopy}>
                    <Button
                      onClick={() => handleCopyText(inputValue.cid)}
                      style={{
                        height: "40px",
                        display: "inline-block",
                        width: "10%",
                      }}
                      icon={<CopyOutlined />}
                    />
                  </Tooltip>
                </div>
              </Form.Item>
              <Form.Item
                name="Blockchain"
                label={
                  <span className="text-gray-800 font-medium">BlockChain</span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
                // style={{ width: "35%" }}
                size="small"
              >
                <Select onChange={onNetworkChange} value={selectedNetwork}>
                  <Option value="137">Polygon</Option>
                  <Option value="56">Binance</Option>
                  <Option value="1">Ethereum</Option>
                </Select>
              </Form.Item>
              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Collection Name"
                rules={[{ required: true }]}
                initialValue={collectionName}
                label={
                  <span className="text-gray-800 font-medium">
                    Collection Name
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg flex">
                  <Input
                    name="collectionName"
                    size="large"
                    placeholder="Enter Collection Name Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={collectionName}
                  />
                </div>
              </Form.Item>
              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Max Supply"
                rules={[{ required: true }]}
                initialValue={maxSupply}
                label={
                  <span className="text-gray-800 font-medium">Max Supply</span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg">
                  <Input
                    name="maxSupply"
                    type="number"
                    min={0}
                    size="large"
                    placeholder="Enter Max Supply here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={maxSupply}
                  />
                </div>
              </Form.Item>

              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Collection Symbol"
                initialValue={collectionSymbol}
                rules={[{ required: true }]}
                label={
                  <span className="text-gray-800 font-medium">
                    Collection Symbol
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lgflex">
                  <Input
                    name="collectionSymbol"
                    size="large"
                    placeholder="Enter Collection Name Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={collectionSymbol}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col
            className="ml-4"
              xs={{
                span: 5,
                // offset: 1,
              }}
              lg={{
                span: 6,
                // offset: 2,
              }}
            >
              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Presale Price"
                rules={[{ required: true }]}
                initialValue={preSalePrice}
                label={
                  <span className="text-gray-800 font-medium">
                    Presale Price
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg">
                  <Input
                    name="preSalePrice"
                    size="large"
                    type="number"
                    min={0}
                    placeholder="Enter Presale Price Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={preSalePrice}
                  />
                </div>
              </Form.Item>

              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="PreSale Limit"
                initialValue={preSaleLimit}
                rules={[{ required: true }]}
                label={
                  <span className="text-gray-800 font-medium">
                    Presale Limit
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lgflex">
                  <Input
                    name="preSaleLimit"
                    size="large"
                    type="number"
                    min={0}
                    placeholder="Enter Presale Limit Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={preSaleLimit}
                  />
                </div>
              </Form.Item>
              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Public Sale Price"
                rules={[{ required: true }]}
                initialValue={publicSalePrice}
                label={
                  <span className="text-gray-800 font-medium">
                    Public Sale Price
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg">
                  <Input
                    name="publicSalePrice"
                    size="large"
                    type="number"
                    // min={0}
                    placeholder="Enter Public Sale Price Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={publicSalePrice}
                  />
                </div>
              </Form.Item>

              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Public Sale Limit"
                rules={[{ required: true }]}
                initialValue={publicSaleLimit}
                label={
                  <span className="text-gray-800 font-medium">
                    Public Sale Limit
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg">
                  <Input
                    name="publicSaleLimit"
                    size="large"
                    type="number"
                    min={0}
                    placeholder="Enter Public Sale Limit Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={publicSaleLimit}
                  />
                </div>
              </Form.Item>

              <Form.Item
                // style={{ width: "35%" }}
                size="small"
                name="Max Mint Amount"
                // name="maxMintAmount"
                initialValue={maxMintAmount}
                rules={[{ required: true }]}
                label={
                  <span className="text-gray-800 font-medium">
                    Max Mint Amount
                  </span>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="rounded-lg">
                  <Input
                    name="maxMintAmount"
                    type="number"
                    min={0}
                    size="large"
                    placeholder="Enter Mint Cost Here..."
                    style={{ borderRadius: "5px" }}
                    onChange={onChangeHandler}
                    value={maxMintAmount}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col
             className="ml-4 "
             xs={{
               span: 8,
               // offset: 1,
             }}
             lg={{
               span: 8,
               // offset: 2,
             }}
            >
            <DynamicFIeld
              setInputValue={setInputValue}
              inputValue={inputValue}
            />
            </Col>
          </Row>
          {/* Button */}
          <div
            style={{ marginTop: 15, marginBottom: 0 }}
            className="cotract flex justify-end w-full"
          >
            <button
              className={`flex justify-end rounded-2xl border mt-2 border-transparent
                            bg-gray-400 mr-2 py-2 px-4 text-sm font-medium text-white shadow-sm 
                            hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 
                            focus:ring-offset-2`}
              onClick={cancel}
            >
              Cancel
            </button>
            {/* {acc !== "No Wallet" &&
            acc !== "Connect Wallet" &&
            acc !== "Wrong Network" &&
            acc !== undefined ? ( */}
            <button
              type="submit"
              disabled={isLoading || selectedNetwork == 0}
              className={`flex justify-end rounded-2xl border mt-2 border-transparent ${
                isLoading || selectedNetwork == 0
                  ? "bg-gray-500 "
                  : "bg-indigo-600"
              } py-2 px-4 text-sm font-medium text-white shadow-sm ${
                isLoading || selectedNetwork == 0
                  ? "hover:bg-none "
                  : "hover:bg-indigo-700"
              }focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Connect Wallet
              <ClipLoader
                cssOverride={{
                  display: "block",
                  marginLeft: "1rem",
                  borderColor: "white",
                }}
                color={"black"}
                loading={isLoading}
                size={20}
              />
              {/* {acc === "No Wallet"
                  ? "Connect Wallet"
                  : acc === "Connect Wallet"
                  ? "Connect Wallet"
                  : acc === "Wrong Network"
                  ? acc
                  : acc === undefined
                  ? "Connect Wallet"
                  : acc.substring(0, 3) + "..." + acc.substring(acc.length - 3)} */}
            </button>
            {/* ) : (
              <button
                className={`flex justify-end rounded-2xl border mt-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                onClick={getWalletAddress}
              >
                {acc === "No Wallet"
                  ? "Connect Wallet"
                  : acc === "Connect Wallet"
                  ? "Connect Wallet"
                  : acc === "Wrong Network"
                  ? acc
                  : acc == undefined
                  ? "Connect Wallet"
                  : acc.substring(0, 3) + "..." + acc.substring(acc.length - 3)}
              </button>
            )} */}
            {/* <button
                            type="submit"
                            className={`flex justify-end rounded-2xl border mt-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                        >
                            Connect Wallet
                        </button> */}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContractForm;
