import React from "react";
import loaderIMg from "../../assets/NftLoader.svg";
import { Modal, Progress } from "antd";
import { useSelector } from "react-redux";
import { countSelector, generatedImageCount } from "../../Slices/GeneratorSlice";

const NftLoader = ({ loading }) => {
  // flex items-center
  //      justify-center
  let numOfImageGen = useSelector(generatedImageCount);
  let totalNumOfImage = useSelector(countSelector);
  return (
    <Modal
      title="Basic Modal"
      open={loading === true}
      onCancel={loading === false}
      width={800}
    >
      <div
        style={{
          width: "100%",
          margin: "auto",
          backgroundColor: "#FFFFFF",
          padding: "30px",
          textAlign: "center",
        }}
      >
        <img src={loaderIMg} style={{ margin: "auto" }} alt=""/>
        <h3
          class="text-3xl font-extrabold dark:text-black"
          style={{ fontWeight: "800", letterSpacing: "1px" }}
        >
          Generating NFT's
        </h3>
        <p style={{ fontWeight: "600" }}>
          This may take a while depending on your computer’s performance <br />{" "}
          and the number of NFTs you want to generate.
        </p>

        <div
          style={{
            width: "60%",
            textAlign: "center",
            margin: "auto",
            padding: "30px 0 10px 0",
          }}
        >
          <div class="w-full p-2">
            <Progress percent={numOfImageGen*100/totalNumOfImage} status="active" />
          </div>

          {/* <div style={{display:"flex" , justifyContent:"space-between"}} >
            <p style={{fontWeight:"600"}} >2 min</p>
            <p style={{fontWeight:"600"}}>Collection size: 354MB </p>
            
        </div> */}
        </div>

        <p style={{ fontWeight: "600" }}>
          {/* <strong>87956 </strong> of 10000 NFTs Generated <br />{" "} */}
          <p> {numOfImageGen} of {totalNumOfImage} Image Generated </p>{" "}
        </p>
      </div>
    </Modal>
  );
};

export default NftLoader;
