import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { user } from "../../Slices/Auth";
import {
  createSessionThunk,
  getPriceList,
  getSubsListThunk,
  priceList,
  subscriptionList,
  upSub,
} from "../../Slices/Stripe";
import { centsToDollar } from "../../utils/centsToDollar";

const UpgradeStripeModal = ({subsList,pricesL}) => {
  const [open, setOpen] = useState(false);
  
  const [prices, setPrices] = useState([]);
  const { user: userdata } = useSelector(user);
 

  console.log(subsList, "FORM MMODAL");
  const dispatch = useDispatch();

  useEffect(() => {
    // if(pricesL.length>0 && subsList.length>0)
    calSubsClasses();
  }, [pricesL, subsList]);


  const calSubsClasses = () => {
    const classVal = "border-double border-4 border-sky-500";
    // border-double border-4 border-sky-500
    if (subsList?.length === 0) {
      const tempPrices = pricesL.map((x) => {
        if (x.name === "Beginner") {
          return { ...x, classList: classVal };
        } else {
          return x;
        }
      });
      console.log("IFFFFFFF");
      setPrices(tempPrices);
    } else {
      const tempPrices = pricesL.map((x) => {
        if (x?.default_price === subsList[0]?.plan?.id) {
          return { ...x, classList: classVal };
        } else {
          return x;
        }
      });
      setPrices(tempPrices);
      console.log(tempPrices, "TEMP PRICESSS");
    }
  };

  const handleCheckout = (priceItem) => {
    dispatch(createSessionThunk({ priceItem, userId: userdata.id })).then(
      (data) => {
        window.location.href = data?.payload?.data?.url;
      }
    );
  };

  const handleUpgrade = (subscriptionId, priceId) => {
    //APPIII

    dispatch(upSub({ subscriptionId, priceId })).then(data=>{
          console.log(data,"DATATATATATAT")
    });
  };
  return (
    <>
      {/* <Button type="primary" onClick={() => setOpen(true)}>
        Open Modal of 1000px width
      </Button> */}
      <button
        type="button"
        onClick={() => setOpen(true)}
        //   onClick={handlefolder}
        className="inline-flex items-center rounded-3xl border mt-4 bg-blue-700 text-white  px-4 py-2 text-sm font-medium leading-4 border-gray-300 "
      >
        Upgrade
        <span class="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-white bg-red-500 rounded-full">
          !
        </span>
      </button>
      <Modal
        title="Upgrade your plan"
        centered
        open={open}
        // onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={700}
      >
        <div
          className="w-11/12 mx-auto scroll-edit overflow-y-scroll p-5 "
          style={{ height: "600px" }}
        >
          {prices
            .sort((a, b) => a.priceItem.unit_amount - b.priceItem.unit_amount)
            ?.map((x) => {
              return (
                <div
                  className={`relative  rounded-lg p-5 px-3 pt-6 mb-5 bg-blue-50 ${x?.classList} `}
                >
                  {/* card header */}

                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-3xl font-semibold text-blue-800 mb-0">
                        {x?.name}
                      </p>
                      <p className="mb-2  ">{/* {x.description} */}</p>
                    </div>

                    <div className="flex justify-end">
                      <div className=" font-normal text-gray-500 flex flex-col ">
                        <span>
                          <span className="font-bold text-2xl text-black">
                            {centsToDollar(x?.priceItem?.unit_amount)}/
                          </span>
                          <span className="text-2xl">month</span>
                        </span>{" "}
                        {!x?.classList && (
                          <button
                            onClick={
                              subsList?.length === 0
                                ? () => handleCheckout(x?.priceItem?.id)
                                : () => handleUpgrade( subsList[0]?.id,x?.priceItem?.id)
                            }
                            className={`bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg  `}
                          >
                            {`${subsList?.length > 0 ? "Upgrade" : "Pay Now" }`}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between "></div>
                  <div className="flex w-4/2 justify-between">
                    {x?.description.split(",").map((str) => (
                      <p className="mt-3 flex items-center">
                        <span className="text-xl mr-1">
                          <BsCheck2 />
                        </span>{" "}
                        {str}
                      </p>
                    ))}
                  </div>
                  <div className="flex justify-end align-bottom"></div>
                </div>
              );
            })}

          {/* <div className=" border-2 rounded-lg">
          <h1>Free</h1>
        </div>
        <div className=" border-2 rounded-lg">
          <h1>Free</h1>
        </div> */}
        </div>
      </Modal>
    </>
  );
};

export default UpgradeStripeModal;
