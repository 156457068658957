import axios from "axios";
import authHeader from "./auth-header";
import authApi from "./axiosConfig";

const API_URL = `http://localhost:8080/api/`;


class StripeServices {
  //   getPublicContent() {
  //     return axios.get(API_URL + 'all');
  //   }

  
  getAllPrices() {
    return authApi.get("prices", { headers: authHeader() });
  }

  getSubsList(stripeCustomerId) {
    return authApi.get(`subscriptionList/${stripeCustomerId}`, { headers: authHeader() });
  }
  
  createSession({priceItem,userId}) {
    return authApi.post("session",{priceId:priceItem,id:userId}, { headers: authHeader() });
  }
  createCustomSession({ amount, id }) {
    return authApi.post("customSession", { amount:amount, id:id }, { headers: authHeader() });
  }

  UpdateSubscription({subscriptionId,priceId}) {
    return authApi.post("update_subscription",{subscriptionId,priceId}, { headers: authHeader() });
  }

  // For Admin
  getAllSubsList() {
    return authApi.get(`allSubscriptionList`, { headers: authHeader() });
  }
}

export default new StripeServices();
