import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import authHeader from "../Services/auth-header";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addLayerMarketPlace } from "../Slices/layer";

const LayerFormMarketP = () => {
  const [inputValues, setInputValues] = useState([""]);
  const [layerList, setLayerList] = useState({});
  const [imageData, setImageData] = useState({ preview: false, fileList: [] });
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [data, setData] = useState({
    foldername: "",
    description: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(inputValues, "valueeess");


  const handleInputChange = (index, event) => {
    const values = [...inputValues];
    values[index] = event.target.value;
    setInputValues(values);
    setShowUploadButton(values[index] !== "");
  };

  const handleAddInput = () => {
    const values = [...inputValues];
    values.push(values.length + 1);
    setInputValues(values);
  };
  const handleRemoveInput = (index, value) => {
    // const values = [...inputValues];
    // values.splice(index, 1);
    // const filterData = Object.keys(layerList).filter(x => x !== value)
    // console.log(filterData, "inputValues");
    // setInputValues(values)
  };
  const handleChange = (info, value) => {
    console.log(info, "infoooooooooooooooooooooo");
    console.log(value, "vaaaallluuuueeeee");
    const abc = info.fileList.map((x) => x.originFileObj);
    console.log(abc, "abcccccccccc");

    setLayerList((prev) => ({
      ...prev,
      [value]: abc,
    }));
  };

  console.log("layer Lists: ", layerList);

  const handleSubmit = () => {
    console.log("Hello World");
    const formData = new FormData();

    const keys = Object.keys(layerList);
    keys.forEach((key) => {
      layerList[key].forEach((image) => {
        formData.append(key, image);
      });
    });
    formData.append("folderName", data.foldername);
    formData.append("gif", imageData?.fileList[0]?.originFileObj);
    formData.append("desc", data.description);

    console.log(...formData, "layerssss");

    dispatch(addLayerMarketPlace(formData))
      .unwrap()
      .then((x) => {
        if (x) {
          navigate("/dashboard");
        }
      })
      .catch((x) => console.log(x));

    console.log(imageData, "imageeeedata");
  };

  const props = {
    name: "avatar",
    headers: {
      authorization: "authorization-text",
    },
    accept: "image/png, image/jpeg, image/jpg",
    maxCount: 1,
    // listType: "picture",
    beforeUpload: () => false,
    fileList: imageData.fileList,
    onChange: ({ fileList }) => {
      setImageData((prev) => ({
        ...prev,
        fileList,
        preview: URL.createObjectURL(fileList[0].originFileObj),
      }));
    },
  };
  return (
    <div className="p-4 flex-col flex justify-center items-center w-full bg-blue-500">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        S
        autoComplete="off"
      >
        <Form.Item
          label="Folder Name:"
          name="foldername"
          rules={[{ required: true, message: "Please input folder name" }]}
        >
          <input
            onChange={(e) =>
              setData((prev) => ({ ...prev, foldername: e.target.value }))
            }
          />
        </Form.Item>
        <Form.Item label="Description">
          <TextArea
            rows={4}
            onChange={(e) =>
              setData((prev) => ({ ...prev, description: e.target.value }))
            }
          />
        </Form.Item>
        <Form.Item>
          <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
            {imageData.preview ? (
              <img src={imageData.preview} alt="" className="w-/4 h-1/4" />
            ) : (
              <svg
                className="h-1/4 w-1/4 text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            )}
          </span>
        </Form.Item>
        <Form.Item>
          <Upload {...props}>
            <Button style={{ borderRadius: "6px", marginLeft: "15px" }}>
              Upload GIf
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
        //   label="Username"
        //   name="username"
        //   rules={[{ required: true, message: "Please input your username!" }]}
        >
          {inputValues.map((value, index) => (
            <div key={index} className="flex justify-center items-center ">
              <h1>Layer Name:</h1>
              <input
              type="text"
              onChange={(event) => handleInputChange(index, event)}
              className="px-2 py-2 m-2"
              />

              {showUploadButton ? (
                <>
                  <Upload
                    // {...props}
                    name={value}
                    multiple
                    onChange={(info) => handleChange(info, value)}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />} className="ml-1">
                      Click to Upload
                    </Button>
                  </Upload>
                  <Button
                    type="primary"
                    onClick={() => handleRemoveInput(index, value)}
                    className="ml-2 p-4"
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" onClick={handleAddInput} className="p-4 ">
            Add
          </Button>
          <Button type="primary" onClick={handleSubmit} className="ml-4 p-4">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LayerFormMarketP;
