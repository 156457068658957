import { Input, message, Steps, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUploadingFormAssets } from "../../Slices/data";

const UploadingForm = ({ onChange, current, handleBtn }) => {
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const handleChange = (info) => {
 
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    // newFileList = newFileList.slice(-2);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
    console.log(newFileList, "filessss");
    // const newFiles = newFileList.map((file) => file);

    dispatch(setUploadingFormAssets(newFileList));
    handleBtn();
  };

  const props = {
    action: "",
    onChange: handleChange,
    multiple: true,
    directory: true,
    listType: "file",
    name: "file",
  };
  return (
    <>
      <div className="border-4  border-dashed mb-6  ">
        <div className="flex flex-col justify-center items-center md:h-[450px]">
          <img src="/bulb.png" alt="" />
          <h1 className="text-2xl font-bold">
            Upload your assests or PSD files
          </h1>
          <Upload multiple {...props} beforeUpload={() => false}>
            <button
              type="file"
              className={`flex justify-end rounded-3xl border mt-4 border-transparent bg-indigo-600 py-2 px-6 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Choose Files
            </button>
          </Upload>
        </div>
      </div>
    </>
  );
};

export default UploadingForm;
