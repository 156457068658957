import authApi from "./axiosConfig";
import authHeader from "./auth-header";


class SmartContract {
    getSolidityFile(data){
        // console.log(data, "SM: getSolidityFile");
        return authApi.post("contract/getSolidityFile", data, { headers: authHeader() })
            .then(response => {
                console.log("InsidePOST:getSolidityFile", response);
                return response.data;
            })
    }

    getByteCodeAndAbi(file){
        // console.log(file, "SM: getByteCodeAndAbi");
        return authApi.post("contract/getByteCode", file, { headers: authHeader() })
            .then(response => {
                // console.log("POST:getByteCodeAndAbi", response);
                return response.data;
            });
    }

    addAddress({ id, address, cid, projectId }){
        console.log(id, "addAddress");
        return authApi.post(`contract/adddeployaddress`, { id, address, cid, projectId }, { headers: authHeader() });
    }
    
    addLayout({ layout, cid, address }) {
        return authApi.post(`contract/addlayout`, { layout, cid, address }, { headers: authHeader() });
    }
}


export default new SmartContract();