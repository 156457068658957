import { HomeIcon, PlusIcon, TvIcon } from "@heroicons/react/24/outline";
import { Breadcrumb } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DefaultLayer,
  getProjects,
  setCollectionForm,
  setDefaultLayer,
  setFoldername,
  setipfs,
} from "../Slices/data";
import { getLayerFromS3 } from "../Slices/layer";
import { ClipLoader } from "react-spinners";

const NFTArtGen = () => {
  const generatedData = useSelector((state) => state.generator.generatedData);
  const allLayers = useSelector((state) => state.Layers.layers);
  const projectList = useSelector((state) => state.storefiles.projects);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [layers, setLayers] = useState(allLayers);
  const [loading, setLoading] = useState(false);
  console.log(allLayers, "layerssssss");
  // console.log(projectList, "projectListInNFTArt");

  const [queryFetch] = useSearchParams();
  const ipfs = queryFetch.get("y");
  console.log(ipfs, "queryyyyyyy");
  const imageArray = [];
  let [color, setColor] = useState("black");

  useEffect(() => {
    setLoading(true)
    if (ipfs == "ipfs") {
      dispatch(getLayerFromS3()).unwrap().then((x) => {
        if(x.success) {
          setLoading(false)
        }
        console.log(x.success, "xxxxxxxxImage");
      }).catch(err => {
        setLoading(false)
        console.log(err, "errrrrrrrrrrrr");
      });
    }
  }, []);

  const handleData = (data, folderName) => {
    setLoading(true);
    console.log(data, "layersssdataaa", folderName ,"folderNameee");
    dispatch(DefaultLayer(data.images[0])).then((x) => {
      console.log(x, "thennnnnnnnnnnn");
      dispatch(setCollectionForm({name: folderName}))
      navigate("/collection-detail");
      setLoading(false);
    });
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  const colors = [
    "#FF5733",
    "#FFE933",
    "#A8FF33",
    "#FD33C6",
    "#FD3345",
    "#AA33FD",
    "#33FDA4",
    "#33D2FD",
    "#B2EE1D",
    "#B2EE1D",
    "#FFA07A",
    "#82E0AA",
    "#F5CBA7",
    "#7FB3D5",
    "#1ABC9C",
    "#9A7D0A",
    "#ECF0F1",
    "#B2BABB",
    "#1ABC9C",
    "#D35400",
    "#239B56",
  ];

  return (
    <div className="md:h-4/6 ">
      <div className="flex flex-col  justify-center items-center ">
        <div
          className={`md:grid ${
            loading === true ? "md:grid-cols-1 p-4" : "md:grid-cols-4"
          } md:w-9/12 gap-4  mt-20`}
        >
          <div className="md:col-span-1 absolute -mt-14 ">
            <Breadcrumb className="mb-4" separator=">">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item href="">NFT ART GENERATOR</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="font-bold text-xl -mt-1">NFT ART GENERATOR</h1>
          </div>
          {loading === true ? (
            <div className="w-full h-full  md:gap-4">
              <ClipLoader
                cssOverride={override}
                color={color}
                loading={loading}
                size={80}
              />
            </div>
          ) : (
            <>
              <div className=" bg-white p-4  cursor-pointer border-2 border-dashed h-full min-w-[200px] min-h-[150px] border-black">
                <div
                  className="flex h-full items-center justify-center flex-col"
                  onClick={() => {
                    navigate("/create-collection");
                  }}
                >
                  <PlusIcon width="40px" height="40px" />
                  <h1>Create New</h1>
                </div>
              </div>

              <>
                {allLayers?.allProducts?.map((x, i) => (
                  <>
                    <div
                      className=" bg-white cursor-pointer"
                      onClick={() => handleData(allLayers.data[i], x.name)}
                    >
                      <div className="flex items-center justify-center">
                        <img
                          className="flex items-center justify-center"
                          src={allLayers?.data[i]?.gif}
                          width="200px"
                          height="200px"
                          alt=""
                        />
                      </div>
                      <div className="flex mt-4 ml-3 justify-start">
                        <h3>{x?.name}</h3>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NFTArtGen;

// {projectList?.map((project, i) => {
//   if (
//     project?.name?.includes("(JSON)") ||
//     project?.name?.includes("(Metadata)")
//   )
//     return <></>;
//   else {
//     const rand =
//       colors[Math.floor(Math.random() * colors.length)];
//     return (
//       <a
//         key={i}
//         target="_blank"
//         rel="noreferrer"
//         href={`https://ipfs.io/ipfs/${project.CID}`}
//       >
//         <div
//           className="bg-white cursor-pointer h-44"
//           style={{ background: rand }}
//         >
//           <div
//             className={`flex justify-center items-center h-5/6`}
//           >
//             <div className="font-bold text-7xl text-white">
//               {project.name.slice(0, 2).toUpperCase()}
//             </div>
//             {/* <img
//           className="flex items-center justify-center"
//           src={``}
//           alt=""
//         /> */}
//           </div>
//           <div className="flex justify-center">
//             <h3>{project.name.replace("(Images)", "")}</h3>
//           </div>
//         </div>
//       </a>
//     );
//   }
// })}
