import axios from "axios";
import authApi from "./axiosConfig";

const API_URL = `http://localhost:8080/api/auth/`;
console.log("AUTTHHHHH", process.env.REACT_APP_BAKREE_API_URL);

class AuthService {
  login({ username, password }) {
    console.log(username, password, "ASDASDAD");
    return authApi
      .post("auth/signin", { username, password })
      .then((response) => {
        console.log(response, "FROM LOGINNNN");

        localStorage.setItem("user", JSON.stringify(response.data));

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register({ username, email, password, adCamp = false }) {
    console.log({ adCamp });
     return authApi
      .post("auth/signup", {
        username,
        email,
        password,
        adCamp,
      })
      .then((res) => {
        console.log(res, "resssss");
        if (res?.data?.adCamp === true) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err, "errorrrrrrr");
        throw err;
      });
  }
  forgetpassword({ email }) {
    console.log(email, "emaillllllll");
    return authApi.post("auth/forgot-password", {
      email,
    });
  }
  getforgetpassToken(search) {
    console.log(search, "tokeeeeen");
    return authApi.get(`auth/reset-password${search}`);
  }
  resetpassword(email, password, token) {
    return authApi.post(`auth/reset-password`, {
      email,
      password,
      token,
    });
  }
}

export default new AuthService();
