import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Details from "../Components/steper_components/Details";
import Upload from "../Components/steper_components/Upload";
import UploadNft from "../Components/steper_components/UploadNft";
import rocket from "../assets/steps_assets/steps_rocket.png";
import { useSelector, useDispatch } from "react-redux";
import Summary from "../Components/steper_components/Summary";
import { setFoldername, setPercentage, setUploadingFormAssets, setipfs } from "../Slices/data";
import { setGeneratedData } from "../Slices/GeneratorSlice";

const IPFSSteps = () => {
  const [url, seturl] = useState("");
  const [folderName, setfolderName] = useState("");
  const [progressVal, setprogressVal] = useState(0);
  const navigate = useNavigate();
  const loadingPopUp = useSelector((state) => state.storefiles.loadingPopup);
  const percentage = useSelector(state => state.storefiles.percentage)
  const generatedData = useSelector(state => state.generator.generatedData)

  const files = useSelector((state) => state.storefiles);
  const folder = useSelector((state) => state.storefiles.folderName);
  const dispatch = useDispatch()
  const [queryFetch] = useSearchParams()
  const manual = queryFetch.get("y")
  const failed = queryFetch.get("f")
  console.log(manual, "queryyyyyyy", failed);

  


  const handleCurrent = (step) => {
    let newSteps = [...steps];

    if (step === 1) {
      newSteps[0].status = "complete";
      newSteps[1].status = "current";
    }

    setsteps(newSteps);
  };

  const handleAddFolderName = (step) => {
    let newSteps = [...steps];

    if (step === 2) {
      newSteps[0].status = "complete";
      newSteps[1].status = "complete";
      newSteps[2].status = "current";
    }

    setsteps(newSteps);
  };
  const handleCheckPackage = (step) => {
    let newSteps = [...steps];

    if (step === 3) {
      newSteps[0].status = "complete";
      newSteps[1].status = "complete";
      newSteps[2].status = "complete";
      newSteps[3].status = "current";
    }

    setsteps(newSteps);
  };
  const setprogressval = (value) => {
    console.log(value, "PROGRESS");
    setprogressVal(value);
  };
  const [steps, setsteps] = useState([
    {
      id: "Step 01",
      name: "Upload NFT's",
      href: "#",
      status: "current",
      component: <UploadNft handleCurrent={handleCurrent} rocket={rocket} />,
    },
    {
      id: "Step 02",
      name: "Details",
      href: "#",
      status: "upcoming",
      component: <Details handleAddFolderName={handleAddFolderName} rocket={rocket} />,
    },
    {
      id: "Step 03",
      name: "Summary",
      href: "#",
      status: "upcoming",
      component: <Summary handleCheckPackage={handleCheckPackage} rocket={rocket}/>,
    },
    {
      id: "Step 04",
      name: "Uploading",
      href: "#",
      status: "upcoming",
      component: <Upload setprogressval={setprogressval} handleAddFolderName={handleAddFolderName} rocket={rocket} />,
    },
  ]);


  useEffect(() => {
    if(loadingPopUp === true) {
    // if(true) {
      const tempStep = [...steps]
      tempStep[0].status = "complete"
      tempStep[1].status = "complete"
      tempStep[2].status = "complete"
      tempStep[3].status = "current"
      setsteps(tempStep)
    }else {
      const tempStep = [...steps]
      tempStep[0].status = "current"
      tempStep[1].status = "upcoming"
      tempStep[2].status = "upcoming"
      tempStep[3].status = "upcoming"
      setsteps(tempStep)
    }
  }, []);

  useEffect(() => {
    if(generatedData.length !== 0){
    }
    
  }, []);
  
  useEffect(() => {
    if(manual == "manual"){
      // dispatch(setUploadingFormAssets([]))
      // dispatch(setGeneratedData([]))
      // // dispatch(setPercentage(0))
    }else if (generatedData?.images?.length !== 0){
      handleAddFolderName(2);
    }else if(generatedData?.images?.length !== 0 && percentage > 0){
    
    }
  }, [])

  return (
    <div className="px-3 pb-14 sm:pb-8 flex justify-center md:pt-6">
      <div className="bg-white w-full min-h-5/6 md:pt-4 md:min-h-[60vh] md:pb-[90px] max-w-[900px] flex flex-col md:flex-row md:items-center break-900">
        <div className="ml-5 hidden md:block min-w-[200px] md:ml-7 md:w-[30%]">
          <img src={rocket} width={300} height={300} alt="" />
        </div>
        <div className="md:w-[70%]">
          <div className="pl-6 pt-4 pb-4 pr-6 w-full flex justify-between sm:justify-center md:justify-start md:pl-2 break-804">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center">
              {steps.map((step) => (
                <div key={step.name} className="h-[60px] md:flex-1 mt-0 min-w-[110px] md:min-w-[100px] ml-1 my-1">
                  {step.status === "complete" ? (
                    <span className="group flex flex-col border-l-4 sm:border-l-0 sm:border-b-4 border-orange-500	 py-2 pl-4 hover:border-orange-700	 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0">
                      <span className="text-sm  font-medium border-orange-500	 group-hover:border-orange-700	">
                        {step.id}
                      </span>
                      <span className="text-sm font-medium text-orange-500">
                        {step.name}
                      </span>
                    </span>
                  ) : step.status === "current" ? (
                    <span
                      href={step.href}
                      className="flex flex-col border-l-4 sm:border-l-0 sm:border-b-4 border-orange-500	 py-2 pl-4 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0"
                      aria-current="step"
                    >
                      <span className="text-sm font-medium ">{step.id}</span>
                      <span className="text-sm font-medium text-orange-500">
                        {step.name}
                      </span>
                    </span>
                  ) : (
                    <span
                      href={step.href}
                      className="group flex flex-col border-l-4 sm:border-l-0 sm:border-b-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0"
                    >
                      <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        {step.id}
                      </span>
                      <span className="text-sm font-medium">{step.name}</span>
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-3 sm:hidden md:mr-2">
              <img src={rocket} width={200} height={200} alt="" />
            </div>
          </div>
          <div className="flex justify-center md:justify-start px-2 sm:px-0">
              {steps.map((x) => {
                if (x.status === "current") {
                  return x.component;
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-ipfs h-full">
      <div className="container bg-white min-h-5/6 w-5/6 mx-auto">
        <div className="grid grid-cols-8 gap-4 ">
          <div className={"imageBox" + " col-span-3"}>
            <img src={rocket} width={250} height={250} alt="" />
          </div>
          <div className="col-span-5 py-8 px-4 pr-16">
            <nav aria-label="Progress">
              <ol role="list" className="space-y-4 md:flex md:space-y-0 ">
                {steps.map((step) => (
                  <li key={step.name} className="md:flex-1">
                    {step.status === "complete" ? (
                      <span className="group flex flex-col border-l-4 border-orange-500	 py-4 pl-4 hover:border-orange-700	 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0">
                        <span className="text-sm  font-medium border-orange-500	 group-hover:border-orange-700	">
                          {step.id}
                        </span>
                        <span className="text-sm font-medium text-orange-500">
                          {step.name}
                        </span>
                      </span>
                    ) : step.status === "current" ? (
                      <span
                        href={step.href}
                        className="flex flex-col border-l-4 border-orange-500	 py-2 pl-4 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0"
                        aria-current="step"
                      >
                        <span className="text-sm font-medium ">{step.id}</span>
                        <span className="text-sm font-medium text-orange-500">
                          {step.name}
                        </span>
                      </span>
                    ) : (
                      <span
                        href={step.href}
                        className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pl-0 md:pb-4 md:pt-0"
                      >
                        <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          {step.id}
                        </span>
                        <span className="text-sm font-medium">{step.name}</span>
                      </span>
                    )}
                  </li>
                ))}
              </ol>
            </nav>

            {steps.map((x) => {
              if (x.status === "current") {
                return x.component;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPFSSteps;
