import React, { useState, useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { Badge, Divider, Upload } from "antd";
import { useDispatch } from "react-redux";
import { setCheckbox, setRender, setSelectedLayer } from "../../../Slices/data";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import {setImagesData} from "../../../Slices/data"

const CollectionSidebar = ({
  folder,
  setfilterData,
  filterData,
  name,
  setorignalDAta,
  handleUpload,
}) => {
  const dispatch = useDispatch();
  const [checkVal, setCheckVal] = useState(false);
  const checkbox = useSelector((state) => state.storefiles.checkbox);
  const folderManiplutaion = useSelector(
    (state) => state.storefiles.uploadingFormAssets
  );

  console.log(folder, "folderrrrrrrrr");
  const handleCheckbox = (e, value) => {
    console.log(value, "check");
    if (e.target.checked === true) {
      dispatch(setCheckbox(e.target.checked));
      console.log(e.target.name, "nameeeeeee", value);
      dispatch(setSelectedLayer(value));
      setCheckVal(true);
      setfilterData(value);
    } else {
      setCheckVal(false);
    }
  };
  const updateFolderManipulation = folderManiplutaion.map((x) => {
    return cloneDeep(x);
  });
  const handleChange = (info) => {
    console.log(info, "infooooo");
    console.log(folder, "images");
    let newFileList = [...info.fileList];
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    console.log(newFileList, "imagessFileeee");
    console.log(folderManiplutaion, "updatedFolder");
  
    for (let i = 0; i < updateFolderManipulation.length; i++) {
      if (updateFolderManipulation[i].folderName === folder.folderName) {
        updateFolderManipulation[i].imagesArray.push({
          name: newFileList[0].originFileObj.name,
          imageFile: newFileList[0].originFileObj,
          rarity: 1
        });
        break;
      }
    }
    console.log(updateFolderManipulation, "folderManiplutaion");

    // setFileList(newFileList);
    dispatch(setImagesData(updateFolderManipulation));
    dispatch(setRender(true))
    // setLoading(true)
  };

  const props = {
    action: "",
    listType: "file",
    name: "file",
  };
  return (
    <div className="flex justify-center items-center  mt-4">
      <input
        id={folder?.folderName}
        type="radio"
        checked={
          filterData?.folderName === folder.folderName && checkVal === checkbox
            ? true
            : false
        }
        name={folder?.folderName}
        value={folder?.folderName}
        onChange={(e) => handleCheckbox(e, folder)}
        class="w-4 h-4 mt-4"
      />
      <div className="w-5/6">
        <div className=" p-2  border-2 rounded-3xl mx-4 mt-4  ">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left  md:h-10 text-purple-900 bg-white rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <div className="flex justify-center">
                    <h1 className="text-xl mr-2">{folder?.folderName}</h1>
                    <Badge count={folder?.imagesArray?.length} />
                  </div>
                  {/* <input   value={`${filterData?.filterData?.imageNameArr?.imageurl?.rarety}%`} className="bg-white px-2 w-14 outline rounded-full text-sm outline-1"/> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-black font-semibold`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <ul>
                    {folder?.imagesArray?.map((imageName, i) => (
                      <div className="flex justify-between">
                        <li className="text-lg text-black font-normal mb-2">
                          {imageName?.name?.split(".")[0]}
                        </li>
                        <p className="text-black font-medium">
                          {imageName?.rarity} %
                        </p>
                      </div>
                    ))}
                  </ul>
                  <div>
                    <Divider />
                    <div className="flex underline text-md justify-center items-center font-semibold cursor-pointer">

                    <Upload {...props} onChange={handleChange} fileList={[]}>
                      <span className=" text-blue-500">
                        + Add a layer
                      </span>
                    </Upload>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <img src="/Dots.png" alt="image/" className="mt-4" />
    </div>
  );
};
export default CollectionSidebar;
