import { Form, Input, Upload, Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import userService from "../Services/user.service";
import { updatedUserInfo } from "../Slices/Auth";
import { openCustomNotificationWithIcon } from "../utils/Notification";
import { ClipLoader } from "react-spinners";
import MyOverLayLoading from '../Components/OverlayLoader';
import { motion as m } from "framer-motion";

const ProfileSettings = () => {
  const user = useSelector((state) => state.auth.user);
  console.log(user, "userrrrrrrr")
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const [imageData, setImageData] = useState({ preview: false, fileList: [] });
  const [loading, setLoading] = useState(false);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      number: "${label} is not a valid number!",
      email: "${label} is not a valid email!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const handleSetting = async (data) => {
    setLoading(prev => true);
    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] === undefined) {
        delete data[key];
      }
    });

    if(imageData.fileList.length !== 0){
      data.avatar = imageData.fileList[0].originFileObj;
    }
    console.log(user.id)
    dispatch(updatedUserInfo({data, id:user.id})).unwrap().then(x => {
      setLoading(prev => false);
    });
    console.log("setLoading = ", loading);
  // try {
  //     if(data) {
  //     // console.log(email,username, password, "dataaaaaaaaaa")
  //     const response = await userService.updateUser(data,user.id)
  //     if(response.status == 200){
  //       console.log(response)
  //         openCustomNotificationWithIcon(
  //           "success",
  //           response.data.message
  //           );
  //           localStorage.setItem("user", JSON.stringify(response.data));
          

  //     }
  //   }

    
  // } catch (error) {
  //   console.log(error.messages)
  //   openCustomNotificationWithIcon(
  //     "error",
  //     error.messages
  //   );
  // }



  };
  const PrevhandleButton = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
  } else {
      navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
  }
}

const props = {
  name: 'avatar',
  headers: {
    authorization: 'authorization-text',
  },
  accept: "image/png, image/jpeg, image/jpg",
  maxCount: 1,
  // listType: "picture",
  beforeUpload: () => false,
  fileList: imageData.fileList,
  onChange: ({ fileList }) => { 
    setImageData(prev => ({ ...prev, fileList, preview: URL.createObjectURL(fileList[0].originFileObj) })); 
  },
}


  return (
<m.div initial={{opacity: 0.0}} animate={{opacity: 2}} transition={{duration: 1, ease: "easeOut"}} className="container mx-auto bg-white  w-5/6 p-12 mt-4 mb-12">      {loading && 
      <MyOverLayLoading newOverlay={true}>
        {/* <h2  style={{fontSize:"14px", background: "black",color: 'white', padding: '5px 25px', borderRadius: '6px'}} >Please Wait...</h2> */}
        <ClipLoader
            cssOverride={{ display: "block", marginLeft: "1rem", borderColor: "white" }}
            color={"black"}
            loading={loading}
            size={20}
        />
      </MyOverLayLoading>}
      <Form
        className="space-y-8 divide-y divide-gray-200"
        validateMessages={validateMessages}
        onFinish={handleSetting}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Profile
              </h3>
              <div>
                <button
                  type="button"
                  onClick={PrevhandleButton}
                  className="inline-flex items-center rounded-3xl border  bg-gray-300  px-4 py-3 text-sm  leading-4 border-gray-300"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  disabled={loading}
                  className="inline-flex items-center rounded-3xl border ml-4  px-4 py-3 text-sm  bg-indigo-800 text-white leading-4 border-gray-300"
                >
                  Save
                </button>
              </div>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Photo
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center">
                    <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      {imageData.preview ? (<img src={imageData.preview} alt="" />):(
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </span>
                    <Upload {...props}>
                      <Button style={{ borderRadius: '6px', marginLeft: '15px' }}>Change</Button>
                    </Upload>
                    {/* <button
                      type="button"
                      className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Change
                    </button> */}
                    <button
                      type="button"
                      className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={()=>setImageData({ fileList: [], preview: false })}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Username
                </label>
                <Form.Item rules={[{  min: 6 }]} name="username">
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <Input
                        type="text"
                        // placeholder={user.username}
                        name="username"
                        id="username"
                        autoComplete="username"
                        defaultValue={user.username}
                        // placeholder="username"
                        size="large"
                        style={{ borderRadius: "10px" }}
                        className=" p-2  text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address
                </label>
                <Form.Item
                  rules={[{  min: 6, type: "email" }]}
                  name="email"
                >
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      size="large"
                      style={{ borderRadius: "10px" }}
                      defaultValue={user.email}
                      // placeholder={user.email}
                      // placeholder="user@mail.com"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Change Password
                </label>

                <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                  <div className="mr-2">
                    <Form.Item
                      name="password"
                      rules={[{  min: 6 }]}
                    >
                      <Input.Password
                        size="large"
                        style={{ borderRadius: "10px" }}
                        placeholder="New Password"
                        className="block w-2/6 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Password is not matched!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      style={{ borderRadius: "10px" }}
                      placeholder="Confirm Password"
                      className="block w-2/6 ml-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  About
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {/* <span> 🚀 Premium</span> */}
                  <button
                    onClick={() => navigate("/Billing")}
                    type="button"
                    className="inline-flex items-center rounded-3xl border ml-4  px-4 py-3 text-sm  bg-indigo-800 text-white leading-4 border-gray-300"
                  >
                    Change Plan
                  </button>
                </div>
              </div>

              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Account Preview
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-3xl border ml-4  px-4 py-3 text-sm  bg-indigo-800 text-white leading-4 border-gray-300"
                  >
                    Set Default Settings
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Form>
      
    </m.div>
  );
};

export default ProfileSettings;
