import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector , useDispatch} from "react-redux";
import {
  countSelector,
  generatedDataSelector,
  generatingSelector,
  layersSelector,
} from "../Slices/GeneratorSlice";
import NftLoader from "../Components/CreatingCollection/NftLoader";
import { Popover } from "@headlessui/react";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { useNavigate, useRoutes } from "react-router-dom";
import { setFoldername, setipfs } from "../Slices/data";
import useInfiniteScroll from "../utils/infiniteScroll";
// import MyOverLayLoading from "../Components/OverlayLoader";
import { ClipLoader } from "react-spinners";
import GenImageJsonModal from "../Components/CreatingCollection/GenImageJsonModal";
import DownloadBtn from "../Components/CreatingCollection/DownloadBtnCheckout";

function GeneratedResult() {
  const data = useSelector(generatedDataSelector);
  const isGenerating = useSelector(generatingSelector);
  const CollectionData = useSelector(
    (state) => state.storefiles.collectionForm
  );
  const loading = useSelector(state => state.storefiles.loading)
  
  const dispatch = useDispatch();

  console.log(loading, "loaddinggggg ipfssss");
  
  const [visible, setVisible] = useState(100);
  const jsonMetaData = useSelector(generatedDataSelector).metadata;

  
  // const user = {
  //   name: "Chelsea Hagon",
  //   email: "chelsea.hagon@example.com",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  // };
  const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Calendar", href: "#", current: false },
    { name: "Teams", href: "#", current: false },
    { name: "Directory", href: "#", current: false },
  ];
  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  const downloadImages = async () => {
    const zip = new JSZip();
    let Images = zip.folder("Images"); // folder name where all files will be placed in

    data?.images?.forEach((url, i) => {
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = `#${i + 1}.png`;
      Images.file(name, blobPromise, { createFolders: true });
    });

    const blob = await zip.generateAsync({ type: "blob" });
    saveAs(blob, "images");
  };

  const downloadJson = async () => {
    const zip = new JSZip();

    let json_files = zip.folder("json files"); // folder name where all files will be placed in

    data?.metadata?.forEach((url, i) => {
      const str = JSON.stringify(url);
      const bytes = new TextEncoder().encode(str);
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8",
      });

      const name = `#${i + 1}.json`;
      json_files.file(name, blob, { createFolders: true });
    });

    const blob = await zip.generateAsync({ type: "blob" });
    saveAs(blob, "json");
  };

  const downloadAfterPayment = async () => {
    if (!data?.images){
       return
      }else {
       await Promise.all([downloadImages(), downloadJson()])
    };
  };
 
  // data
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  

  const navigate = useNavigate();
  const uploadToIPFS = () => {
    // dispatch()
    const dataInFile = [];
    for (let i = 0; i < data.images.length; i++) {
      dataInFile[i] = dataURLtoFile(data.images[i], (i + 1) + ".png");
    }
    dispatch(setipfs(dataInFile))
    dispatch(setFoldername(CollectionData.name))
    navigate("/createproject/?y=ipfs");
  };

  
  // const [HasMore, setHasMore] = useState(data?.images?.length);
  // console.log("data.images.length = ", data?.images?.length, "visible = ", visible);
  const loadMoreImage = () => {
    // if(visible+100 > data?.images?.length){
    //   setHasMore(data?.images?.length > visible)
    // }
    setVisible(prev => prev+100);
  }
  
  const [lastElementRef] = useInfiniteScroll(
    data?.images?.length > visible ? loadMoreImage : () => {}, false
  );

  const totalCount = useSelector(countSelector);

  const override = {
    display: "block",
    marginLeft: "1rem",
    borderColor: "white",
  };  

  return (
    <div className="w-full">
      {isGenerating === true ? (
        <div className="w-full h-full ">
          <NftLoader loading={isGenerating} />
        </div>
      ) : (
        <div className="container mx-auto py-5 px-6 ">
          {/* header */}
          <>
            {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
            <Popover
              as="header"
              className={({ open }) =>
                classNames(
                  open ? "fixed inset-0 z-40 overflow-y-auto" : "",
                  "shadow-sm lg:static lg:overflow-y-visible"
                )
              }
            >
              {({ open }) => (
                <>
                  <div className="mx-auto px-4 sm:px-6 lg:px-8 py-10">
                    <div className=" justify-between gap-8 flex items-center">
                      <div className="flex flex-col inset-y-0 left-0 col-span-2">
                        <div className="flex flex-shrink-0 items-center text-3xl text-black font-medium">
                          {CollectionData.name}
                        </div>
                        <p className="flex flex-shrink-0 items-center text-sm text-black font-medium">
                          {totalCount} items
                        </p>
                      </div>
                      <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 ">
                        <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0"></div>
                      </div>

                      <div className="flex lg:items-center lg:justify-end xl:col-spa n-4 ">
                        <DownloadBtn downloadAfterPayment={downloadAfterPayment} uploadToIPFS={uploadToIPFS} />
                        <button
                          disabled={loading === true}
                          onClick={uploadToIPFS}
                          className={`ml-6 inline-flex items-center rounded-md border border-transparent ${loading === true ? "bg-gray-300" : " bg-indigo-600" } px-7 py-3 text-sm font-medium text-white shadow-sm ${loading === true ? "" : "hover:bg-indigo-700" } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                        >
                         {loading === true ? "Uploading...": "Upload to IPFS"}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Popover>
          </>

          {/* header  */}

          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8  py-6 px-6"
          >
            {data?.images?.slice(0, visible).map((file, i) => {
              if(data?.images?.slice(0, visible).length === i+1){
                return (
                  <li className="relative" ref={lastElementRef} key={i}>
                    <GenImageJsonModal img={file} jsonMetaData={jsonMetaData[i]} index={i+1} />
                      {/* <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        <img
                          src={file}
                          alt=""
                          className="pointer-events-none object-cover group-hover:opacity-75"
                        />
                      </div> */}
                  </li>
                );
              } 
              else {
                return(
                    <li className="relative" key={i}>
                      <GenImageJsonModal img={file} jsonMetaData={jsonMetaData[i]} index={i+1} />
                      {/* <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        <img
                          src={file}
                          alt=""
                          className="pointer-events-none object-cover group-hover:opacity-75"
                        />
                      </div> */}
                        {/* <button type="button" className="absolute inset-0 focus:outline-none">
                      <span className="sr-only">View details for {file.title}</span>
                    </button> */}
                      {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
                  <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
                    </li>
            )}})}
          </ul>
          {data?.images?.length > visible && 
          <div className="container mx-0 min-w-full flex flex-col items-center">
            <button 
                className="ml-6 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-7 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" 
                onClick={loadMoreImage}>
              Load More
              <span>
                    <ClipLoader
                      cssOverride={override}
                      color="black"
                      loading={true}
                      size={20}
                    />
                  </span>
            </button> 
          </div>}
        </div>
      )}
    </div>
  );
}

export default GeneratedResult;
