import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ContractBtn from "../Components/SmartContract/ContractBtn";
import ContractForm from "../Components/SmartContract/ContractForm";
import { Checkbox, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import {
  connectWeb3,
  disconnectWeb3,
  setCheckbox,
  setDisconnectWeb3,
} from "../Slices/data";
import { openCustomNotificationWithIcon } from "../utils/Notification";
import formatRelativeTime from "../utils/convertRelativeTime";
import Lottie from "lottie-react";
import ipfsStorageAnimation from "../assets/Lotties/Bakeree Website - Lotties files/Bakeree_s IPFS Storage.json";
import nftAnimation from "../assets/Lotties/Bakeree Website - Lotties files/Bakeree_s Art Generator.json";
import blockchainAnimation from "../assets/Lotties/Bakeree Website - Lotties files/Transparency and Security.json";
import smartContractAnimation from "../assets/Lotties/Bakeree Website - Lotties files/Permanent and Immutable.json";
import designAnimation from "../assets/Lotties/Bakeree Website - Lotties files/Supercharge Your Trading.json";

const DashBoard = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const address = useSelector((state) => state.storefiles.address);
  const [checkbox, setCheckbox] = useState(false);
  const notifications = useSelector(
    (state) => state.notifications.notification
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(checkbox, "checkbox");

  console.log(user, "userrrrrrrrrrrrr");

  useEffect(() => {
  try {
      const { ethereum } = window;
    if(ethereum?.selectedAddress != null && checkbox == false) {
      console.log(ethereum.selectedAddress, "ethereum.selectedAddress");
      setCheckbox(true)
    }else{
      setCheckbox(false)
    }
  } catch (error) {
    console.log(error, "errormetamaskkkk");
    openCustomNotificationWithIcon("Warning", "Please check or install Metamask")
  }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  console.log(address, "addresssssssssssssssssss");

  if (open) {
    return (
      <ContractForm
        cancel={() => {
          setOpen(false);
        }}
      />
    );
  }

  

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      dispatch(connectWeb3())
        .unwrap()
        .then((x) => {
          console.log(x, "connectWeb3Dash");
          if (x === "No Wallet") {
            openCustomNotificationWithIcon(
              "error",
              "Please install MetaMask First and then Refresh the Page Once."
            );
            return;
          } else if (x === "Wrong Network") {
            openCustomNotificationWithIcon(
              "error",
              "You are on a Wrong Network"
            );
            return;
          } else if (x.length === 42) {
            openCustomNotificationWithIcon(
              "success",
              "Wallet Successfully Connected"
            );
          } else {
            openCustomNotificationWithIcon(
              "error",
              "Can't Connect MetaMask at this time, please try Later"
            );
          }
        });
    } else {
      // dispatch(setDisconnectWeb3());
      dispatch(disconnectWeb3())
        .unwrap()
        .then((x) => {
          console.log(x, "disconnect");
          openCustomNotificationWithIcon(
            "success",
            "MetaMask Wallet Disconnected"
          );
        });
    }
  };
  const connectMetaMask = async (e) => {
    console.log(e.target.checked, "checkkkeddd");
    try {
      // Request access to the user's MetaMask wallet
      if (e.target.checked) {
        dispatch(connectWeb3())
          .unwrap()
          .then((x) => {
            console.log(x, "connectedMetamAskDash");
            if (x) {
              setCheckbox(e.target.checked);
              openCustomNotificationWithIcon(
                // await window.ethereum.request({ method: "eth_requestAccounts" });
                "success",
                "Wallet Successfully Connected"
              );
            }
          });
      } else {
        dispatch(disconnectWeb3())
          .unwrap()
          .then((x) => {
            console.log(x, "xxxxdisconnected");
            setCheckbox(false);
            openCustomNotificationWithIcon("success", x.message);
          });
      }

      // Wallet connected successfully
      // setIsWalletConnect(true);
    } catch (error) {
      // Wallet connection failed or rejected by the user
      openCustomNotificationWithIcon("error", "Failed to Connect Wallet");
    }
  };
  return (
    <div className="flex flex-col p-8 pt-6">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="h-[64px]">
          <p className="text-gray-400 mb-0 text-lg">Welcome Back 👋</p>
          <h2 className="text-3xl font-bold mb-0">{user.username}</h2>
        </div>
        <div className="flex h-[64px] items-center">
          <div className="mr-3">
            <Checkbox
              style={{ color: "gray" }}
              checked={checkbox}
              onChange={(e) => connectMetaMask(e)}
            >
              Connect Wallet
            </Checkbox>
          </div>
          {/* <p className="text-gray-400 mb-0">02 Wallets Connected</p> */}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
        <div
          className={`min-w-[250px] col-span-1  grow h-[340px] cursor-pointer ml-4 mt-6 ${
            loading ? "" : "bg-white"
          }`}
          onClick={() => {
            navigate(`/nft-generator/?y=ipfs`);
          }}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton.Image
                active
                style={{
                  height: "180px",
                  width: "170px",
                  marginBottom: "16px",
                }}
              />
              <Skeleton active />
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col">
              <div className="relative flex flex-col justify-center min-w-[400px] items-center">
                <div style={{ width: "250px", height: "200px" }}>
                  <Lottie animationData={nftAnimation} />
                </div>
                <div className="flex flex-col relative justify-start mt-4 ml-24 mr-20">
                  <p className=" text-[#202D37] text-xl font-bold mb-2">
                    Generate NFT
                  </p>
                  <p className=" text-gray-600">
                    Easily turn your digital masterpieces into unique and
                    one-of-a-kind assets quickly
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`min-w-[250px] col-span-1 grow h-[340px] cursor-pointer ml-4 mt-6 ${
            loading ? "" : "bg-white"
          }`}
          onClick={() => {
            navigate(`/createproject/?y=manual`);
          }}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton.Image
                active
                style={{
                  height: "180px",
                  width: "170px",
                  marginBottom: "16px",
                }}
              />
              <Skeleton active />
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col">
              <div className="flex flex-col justify-center items-center min-w-[400px]">
                <div style={{ width: "250px", height: "200px" }}>
                  <Lottie animationData={ipfsStorageAnimation} />
                </div>
                <div className="flex flex-col justify-start mt-4 ml-24 mr-20">
                  <p className="text-[#202D37] text-xl font-bold mb-2">
                    Upload To IPFS
                  </p>
                  <p className="text-gray-600">
                    Upload your digital assets to IPFS, ensuring secure and
                    decentralized storage
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`min-w-[250px] col-span-1 grow h-[340px] cursor-pointer ml-4 mt-6 ${
            loading ? "" : "bg-white"
          }`}
          onClick={() => {
            setOpen(true);
          }}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton.Image
                active
                style={{
                  height: "180px",
                  width: "170px",
                  marginBottom: "16px",
                }}
              />
              <Skeleton active />
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col">
              <div className="relative flex flex-col justify-center items-center min-w-[400px]">
                <div style={{ width: "250px", height: "200px" }}>
                  <Lottie animationData={blockchainAnimation} />
                </div>
                <div className="flex flex-col justify-start mt-4 ml-24 mr-20">
                  <p className="text-[#202D37] text-xl font-bold mb-2">
                    Blockchain Contract
                  </p>
                  <p className="text-gray-600">
                    Start minting your NFTs today in a efficient way with our
                    user-friendly interface
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`min-w-[250px] col-span-1 grow h-[340px] cursor-pointer ml-4 mt-6`}
          onClick={() => {
            navigate(`/design-nft-artwork`);
          }}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton.Image
                active
                style={{
                  height: "180px",
                  width: "170px",
                  marginBottom: "16px",
                }}
              />
              <Skeleton active />
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col ">
              <div
                className="relative flex flex-col items-center w-full h-[340px]"
                style={{
                  background:
                    "linear-gradient(146.67deg, #F79027 -20.81%, #F05428 115.19%)",
                }}
              >
                {/* <img
                className="flex items-center w-full justify-center min-h-[340px] h-full"
                src="/Rectangle_4.png"
                alt=""
              ></img> */}

                <div style={{ width: "250px", height: "200px" }}>
                  <Lottie animationData={designAnimation} />
                </div>
                <div className="flex flex-col justify-start mt-4 ml-24 mr-20 w-[200px] ">
                  <p className=" text-white text-xl font-bold mb-2">
                    Design your NFT Artwork
                  </p>
                  <p className="text-white">
                    Our best designer will deliver <br />
                    timely and quality work.
                  </p>
                </div>

                {/* <div className="flex flex-col absolute bottom-[30px]">
                  <p className=" text-white text-xl font-bold mb-2">
                    Design your NFT Artwork
                  </p>
                  <p className=" text-white">
                    Our best designer will deliver <br />
                    timely and quality work.
                  </p>
                </div> */}
              </div>
            </div>
          )}
        </div>
        <div
          className={`min-w-[250px] col-span-1 grow h-[340px] cursor-pointer ml-4 mt-6 ${
            loading ? "" : "bg-white"
          }`}
          onClick={() => {
            navigate(`/projects`);
          }}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton.Image
                active
                style={{
                  height: "180px",
                  width: "170px",
                  marginBottom: "16px",
                }}
              />
              <Skeleton active />
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col">
              <div className="relative flex flex-col justify-center min-w-[400px] items-center">
                <div style={{ width: "250px", height: "200px" }}>
                  <Lottie animationData={smartContractAnimation} />
                </div>
                <div className="flex flex-col justify-start mt-6 ml-24 mr-20">
                  <p className="text-[#202D37] text-xl font-bold mb-2">
                    Smart Contract
                  </p>
                  <p className="text-gray-600">
                    Create your own custom Smart Contracts for your digital
                    artwork without any hassle
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`col-span-1  h-[340px] cursor-pointer ml-4 mt-6 ${
            loading
              ? ""
              : "bg-white md:col-span-2 grow md:grid-cols-2 md:col-end-auto"
          } order-last lg:order-1`}
          onClick={() => {
            navigate(`/projects`);
          }}
        >
          <div className="flex items-center justify-center flex-col">
            <div className={`col-span-3 ${loading ? "" : "bg-white"}`}>
              <div>
                {loading ? (
                  <Skeleton.Input active size={"default"} block={true} />
                ) : (
                  <h1 className="p-4 text-center">Recent Activiy</h1>
                )}
              </div>
              <div className="flex flex-col text-center justify-center items-center p-4">
                {loading ? (
                  <Skeleton.Image
                    active
                    style={{
                      height: "180px",
                      marginBottom: "16px",
                      width: "170px",
                    }}
                  />
                ) : (
                  <img
                    width={"150px"}
                    className="text-center mb-4"
                    height={"100px"}
                    src="/Group_1.png"
                    alt=""
                  />
                )}
                {loading ? (
                  <Skeleton.Input active size={"default"} block={true} />
                ) : (
                  <h1 className="text-xl">No Activity</h1>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`min-w-[240px] col-span-1 grow h-[340px] cursor-pointer ml-4 mt-6 ${
            loading ? "" : "bg-white"
          }`}
        >
          {loading ? (
            <div className="w-full flex flex-col items-center py-4 p-3">
              <Skeleton active block={true} />
              <Skeleton active />
            </div>
          ) : (
            <div
              className="flex flex-col "
              style={{ maxHeight: "340px", overflowY: "auto" }}
            >
              <div className="flex w-full justify-between p-4 pt-6 sticky top-0 bg-white z-10 border-b-2 border-gray-200 mb-2">
                <p className=" text-[#202D37] text-xl font-bold mb-2 w-9/12">
                  Notifications
                </p>
                {/* <Link to="#" className=" text-blue-700 underline w-3/12">
                  View All
                </Link> */}
              </div>

              {notifications &&
                notifications
                  .slice() // create a copy of the array to avoid mutating the original
                  .reverse() // reverse the order of the array
                  .map((x) => {
                    return (
                      <div
                        key={x.id}
                        className="flex w-full justify-between pl-4 pr-4 pb-4"
                      >
                        <p className="text-black text-xs mb-2 w-9/12">
                          {x.content}
                        </p>
                        <p className="text-gray-500 text-xs mb-2 w-3/12">
                          {formatRelativeTime(x.createdAt)}
                        </p>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
