import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import background from "../assets/Group-180.png";
import SignupLandingPage from "./SignupLandingPage";
const LandingPage = () => {
  
  return (
    <div
      style={{ height: "108vh" }}
      className="bg-white overflow-y-auto absolute top-0 right-0 bottom-0 left-0 scroll-bar"
    >
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "100% auto",
          backgroundPosition: "center",
          minHeight: "100%",
          minWidth: "100%",
          height: "100vh",
        }}
        className="flex flex-col items-center relative bg-no-repeat bg-center mb-14 "
      >
        <a href="https://bakeree.io/">
          <img
            src="https://bakeree.io/wp-content/uploads/2023/05/Group-1.png"
            alt="Logo"
            className="pt-32 pr-0 w-78 h-22 logo-lp"
          />
        </a>
        <div style={{ fontFamily: "Plus Jakarta Sans, sans-serif" }}>
          <h2
            style={{ fontSize: "48px" }}
            className="pt-16 text-xl text-center header-text"
          >
            <b>Become an NFT Millionaire</b>
            <br />
            <br />
            <span>Launch Your NFT Collection with Bakeree</span>
            <br />
            <br />
            <span>and Watch Your Wealth Soar</span>
          </h2>
          <div style={{ fontSize: "20px", color: "#7c8087" }}>
            <p className="text-center paragraph-text">
              <br />
              Are you ready to turn your creative passion into a lucrative
              venture? Welcome to Bakeree,
              <br />
              the platform where art meets technology, and creativity knows no
              bounds. We believe in
              <br />
              empowering artists, creators, and visionaries to unleash their
              full potential in the digital
              <br />
              era, opening the doors to unlimited financial opportunities.
              <br />
              <br />
              For just $100, we offer you the unique opportunity to create 25
              NFTs from start to finish.
              <br />
              This is a chance to create and stake your claim in the rapidly
              growing world of digital art
              <br />
              and assets. No more traditional limitations, only unlimited
              possibilities with digital assets.
            </p>
          </div>
        </div>
        <Link to="/signup">
          <button
            style={{ fontSize: "16px", fontWeight: "600" }}
            className="buynow-button text-white rounded-full transition-colors ease-in-out duration-600 bg-orange-500 hover:bg-blue-800 px-16 py-4 text-center mr-2 mb-2 mt-11"
          >
            Buy Now
          </button>
        </Link>
      </div>
      {/*Section 1*/}
      <div
        className="flex gap-8 justify-center"
        style={{
          maxWidth: "1580px",
          marginLeft: "180px",
          marginRight: "160px",
        }}
      >
        <div className="w-[58.165%]">
          <div className="flex border border-gray-300 border-1 pl-12 pt-14 pr-8 pb-20 ">
            <img
              src="/Group_93.png"
              alt="NFT"
              style={{ width: "390px", height: "320px", marginLeft: "5px" }}
            />
            <div>
              <h2
                style={{ fontSize: "32px", fontWeight: "700" }}
                className="pt-8 text-xl leading-[1.25]"
              >
                Bakeree's Art Generator - Your Gateway to NFT Stardom
              </h2>
              <p style={{ fontSize: "16px", color: "#7c8087" }}>
                Tap into the booming NFT market and showcase your artistic
                brilliance. Bakeree's Art Generator helps you create stunning
                digital artwork that captivates collectors and investors,
                propelling your NFTs to new heights.
              </p>
            </div>
          </div>
        </div>
        <div className="w-[41.804%]">
          <div className="grid grid-cols-2 gap-4 border border-gray-300 border-1 pl-12 pt-14 pr-14 pb-14">
            <h2
              style={{ fontSize: "32px", fontWeight: "700" }}
              className="col-span-2 text-xl leading-[1.25]"
            >
              Bakeree's IPFS Storage: The Fort Knox for Your NFT Empire -
              Uncompromised
            </h2>
            <p
              style={{ fontSize: "16px", color: "#7c8087" }}
              className="col-start-1 col-end-2"
            >
              Securely store and manage your valuable NFTs with Bakeree's IPFS
              Storage. Rest easy knowing that your digital assets are protected
              and easily accessible while you focus on building your NFT empire.
            </p>
            <img
              src="/Group_94.png"
              alt="NFT"
              style={{ width: "240px", height: "193px" }}
              className="col-start-2 col-end-3"
            />
          </div>
        </div>
      </div>
      {/*Section 2*/}
      <div
        className="flex gap-8 justify-center mt-8 mb-8"
        style={{
          maxWidth: "1580px",
          marginLeft: "180px",
          marginRight: "160px",
        }}
      >
        <div className="w-[41.804%]">
          <div className="grid grid-cols-2 gap-2 border border-gray-300 border-1 pl-12 pt-12 pr-14 pb-24">
            <h2
              style={{ fontSize: "32px", fontWeight: "700" }}
              className="col-span-2 text-xl mr-8 leading-[1.25]"
            >
              Ensure Transparency and Security with Automatic Smart Contracts
            </h2>
            <p
              style={{ fontSize: "16px", color: "#7c8087" }}
              className="col-start-1 col-end-2"
            >
              Generate custom smart contracts effortlessly using Bakeree's
              Automatic Smart Contract Generator. Define the terms and
              conditions of your NFT sales clearly, protecting your interests
              and ensuring smooth transactions.
            </p>
            <img
              src="/Group_95.png"
              alt="NFT"
              style={{ width: "295px", height: "210px" }}
              className="col-start-2 col-end-3"
            />
          </div>
        </div>
        <div className="w-[58.165%]">
          <div className="flex border border-gray-300 border-1 pl-12 pt-16 pr-8 pb-8">
            <div className="w-[50%]">
              <h2
                style={{ fontSize: "32px", fontWeight: "700" }}
                className="pt-14 text-xl leading-[1.25]"
              >
                Mint Your Way to Success with Minting Page Generator{" "}
              </h2>
              <p style={{ fontSize: "16px", color: "#7c8087" }}>
                Easily mint and launch your NFTs with Bakeree's Minting Page
                Generator. No technical expertise is required! Create your NFTs
                in a few simple steps and share them with the world, fueling
                your journey to millionaire status.
              </p>
            </div>
            <div className="w-[50%]">
              <img
                src="https://bakeree.io/wp-content/uploads/2023/05/Group-174.png"
                alt="NFT"
                style={{ width: "390px", height: "400px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Section 3*/}
      <div
        className="flex gap-8 justify-center"
        style={{
          maxWidth: "1580px",
          marginLeft: "180px",
          marginRight: "160px",
        }}
      >
        <div className="w-[58.165%]">
          <div className="flex border border-gray-300 border-1 pl-16 pt-16 pr-16 pb-20">
            <img
              src="https://bakeree.io/wp-content/uploads/2023/05/Group-180-1.png"
              alt="NFT"
              style={{ width: "410px", height: "340px", marginLeft: "5px" }}
            />
            <div className="pt-4 pl-6">
              <h2
                style={{ fontSize: "32px", fontWeight: "700" }}
                className="pt-8 text-xl leading-[1.25]"
              >
                Join the Digital Marketplace for Artists and NFTs{" "}
              </h2>
              <p style={{ fontSize: "16px", color: "#7c8087" }}>
                Experience the vibrant Bakeree Marketplace, connecting you with
                a global community of buyers and collectors. Expand your reach,
                find your audience, and maximize your earning potential as an
                NFT creator.
              </p>
            </div>
          </div>
        </div>
        <div className="w-[41.804%]">
          <div className="grid grid-cols-2 gap-2 border border-gray-300 border-1 pl-12 pt-14 pr-14 pb-8">
            <h2
              style={{ fontSize: "32px", fontWeight: "700" }}
              className="col-span-2 text-xl leading-[1.25] pr-20"
            >
              Supercharge Your <br /> Trading <br /> with Trading Bots
            </h2>
            <p
              style={{ fontSize: "16px", color: "#7c8087" }}
              className="col-start-1 col-end-2"
            >
              Maximize your trading profits and stay ahead with Bakeree's
              Trading Bots. Let our cutting-edge algorithms analyze the market,
              execute trades, and optimize your trading strategy, giving you a
              competitive edge in cryptocurrency.
            </p>
            <img
              src="https://bakeree.io/wp-content/uploads/2023/05/Group-181.png"
              alt="NFT"
              style={{ width: "300px", height: "253px" }}
              className="col-start-2 col-end-3"
            />
          </div>
        </div>
      </div>
      {/*Section 4*/}
      <div
        className="flex gap-8 justify-center mt-8"
        style={{
          maxWidth: "1580px",
          marginLeft: "180px",
          marginRight: "160px",
        }}
      >
        <div className="w-[41.804%]">
          <div className="grid grid-cols-2 gap-2 border border-gray-300 border-1 pl-8 pt-6 pr-8 pb-8">
            <h2
              style={{ fontSize: "32px", fontWeight: "700" }}
              className="col-span-2 text-xl mr-8 leading-[1.25]"
            >
              Reinventing Creativity, <br /> One Layer at a Time{" "}
            </h2>
            <p
              style={{ fontSize: "16px", color: "#7c8087" }}
              className="col-start-1 col-end-2 pr-4"
            >
              Experience the future of art creation with Bakeree's AI Art
              Creator. Our advanced AI technology generates intricate layers for
              your artwork, unlocking endless possibilities and helping you
              create mesmerizing masterpieces that capture the attention of art
              enthusiasts worldwide.
            </p>
            <img
              src="https://bakeree.io/wp-content/uploads/2023/05/Group-182.png"
              alt="NFT"
              style={{ width: "400px", height: "240px" }}
              className="col-start-2 col-end-3 mt-52"
            />
          </div>
        </div>
        <div className="w-[58.165%]">
          <div className="flex border border-gray-300 border-1 pl-12 pt-32 pr-14 pb-32">
            <div className="w-[50%]">
              <h2
                style={{ fontSize: "32px", fontWeight: "700" }}
                className="pt-14 text-xl leading-[1.25]"
              >
                Seize the Future: Your Millionaire Journey Starts Here with
                Bakeree
              </h2>
              <p style={{ fontSize: "16px", color: "#7c8087" }}>
                Embark on your successful journey with Bakeree today! Begin your
                millionaire journey through NFTs. The future is here. Seize it
                with our all-inclusive services.
              </p>
            </div>
            <div className="w-[50%]">
              <img
                src="https://bakeree.io/wp-content/uploads/2023/05/Group-184.png"
                alt="NFT"
                style={{ width: "400px", height: "350px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Footer*/}
      <div>
        <div
          className="flex flex-col justify-center items-center"
          style={{ backgroundColor: "#485263", marginTop: "100px" }}
        >
          <a href="https://bakeree.io/">
            <img
              src="https://bakeree.io/wp-content/uploads/2023/05/Vector.png"
              alt="Logo"
              className="pt-24 pb-12 pr-0 w-78 h-22 "
            />
          </a>
          <div style={{ fontSize: "46px" }}>
            <h1 className="text-white">Start your journey towards success.</h1>
          </div>
          <Link to="/signup">
            <button
              style={{ fontSize: "16px", fontWeight: "600" }}
              className="text-white rounded-full transition-colors ease-in-out duration-600 bg-orange-500 hover:bg-blue-800 px-16 py-4 text-center mr-2 mb-24 mt-11"
            >
              Buy Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;