
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { downloadPrice } from '../../Slices/GeneratorSlice';
import MyOverLayLoading from '../OverlayLoader';
// import { Button, Modal } from 'antd';
// import './checkoutForm.css'
const CheckoutForm = ({ downloadAfterPayment, setPayPopup, closeModal, ...props }) => {
  const { intent } = props
  const stripe = useStripe();
  const elements = useElements();
  const amount = useSelector(downloadPrice);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setPayPopup();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `${window.location.origin}/projects/${submissionDetails.client_request_id}/submission/${submissionDetails.id}/${intent.id}/stripeConfirm`, //our return URL
        // return_url: 'https://example.com/success',
    },
      redirect: 'if_required',
    });
      
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        props.onHide();
        downloadAfterPayment();
      }
    };

    return (
        <MyOverLayLoading closeModal={closeModal}>
            <div>
                <h4 style={{ textAlign: 'center', fontSize: '1.3rem', fontWeight: 'bold' }}>Pay ${amount} Only</h4>
                <PaymentElement />
                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
                    <button 
                        disabled={!stripe}
                        onClick={handleSubmit}
                        className={`ml-6 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-7 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-offset-2`}
                    >Pay</button>
                    <button 
                        onClick={props.onHide}
                        className="ml-6 inline-flex items-center rounded-md border border-transparent bg-gray-200 px-7 py-3 text-sm font-medium text-black shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:bg-gray-300 focus:ring-offset-2"
                        >Cancel</button>
                </div>
                {/* <Modal
                    style={{position: 'absolute', top: 0, left:0 , background: 'white', margin: '0 auto'}}
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton >
                    <Modal.Title>
                        <h5>Payment</h5>

                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    <PaymentElement />
                    <Button
                        style={{ width: "100%", marginTop: '10px' }}
                        disabled={!stripe}
                        onClick={handleSubmit}
                    >
                        {stripe ? 'pay' : 'processing..'}
                    </Button>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={props.onHide} > Close</Button>
                    </Modal.Footer>

                </Modal> */}
            </div>
        </MyOverLayLoading>
    )
  };

export default CheckoutForm;