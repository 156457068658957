import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import GenericNotFound from "./Components/GenericNotFound";
import Nav from "./Components/Nav/Nav";
import Billing from "./Pages/Billing";
import DashBoard from "./Pages/DashBoard";
import IPFSSteps from "./Pages/IPFSSteps";
import NoProject from "./Pages/NoProject";
import ProfileSettings from "./Pages/ProfileSetting";
import ProjectList from "./Pages/ProjectList";
import SignIn from "./Pages/Signin";
import Signup from "./Pages/Signup";
import { user } from "./Slices/Auth";
import { ProtectedRoute } from "./utils/ProtectedRoutes";
import { Button, notification } from "antd";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import NFTArtGen from "./Pages/NFTArtGen";
import CreateCollection from "./Pages/CreateCollection";
import CollectionDetail from "./Components/CreatingCollection/CollectionDetail";
import GeneratedResult from "./Pages/GeneratedResult";
import UsersList from "./Pages/UsersList";
import SideDrawer from "./Components/SideDrawer/SideDrawer";
import DesignNftByDesigner from "./Pages/DesignNftByDesigner";
import MintSelection from "./Pages/MintSelection";
import TestImage from "./Pages/TestImage";
import LayerFormMarketP from "./Pages/LayerFormMarketP";
import LandingPage from "./Pages/LandingPage";
import SignupLandingPage from "./Pages/SignupLandingPage";
import socket from "./utils/socket";
import { openCustomNotificationWithIcon } from "./utils/Notification";
import { useDispatch } from "react-redux";
import { getAllNotification } from "./Slices/Notification";
// import {io} from "socket.io-client"

function App() {
  const loadingPopUp = useSelector((state) => state.storefiles.loadingPopup);
  const { isLoggedin, user: userdata } = useSelector(user);
  const dispatch = useDispatch();
  const queryFetch = ["manual", "ipfs"];

  const { pathname } = useLocation();
  console.log(pathname, "locatttion");

  // const location = useLocation()
  console.log(userdata, "useraDataa");

  useEffect(() => {
    if (userdata) {
      dispatch(getAllNotification({ userId: userdata?.id }));
    }
  }, [pathname, socket]);

  useEffect(() => {
    const userCreatedHandler = (data) => {
      console.log(data, "user-created");
      openCustomNotificationWithIcon("success", data.message);
      dispatch(getAllNotification({ userId: userdata?.id }));
    };

    const contractCreatedHandler = (data) => {
      console.log(data, "contract-created");
      openCustomNotificationWithIcon("success", data.message);
      dispatch(getAllNotification({ userId: userdata?.id }));
    };

    const projectCreatedHandler = (data) => {
      console.log(data, "project-created");
      openCustomNotificationWithIcon("success", data.message);
      dispatch(getAllNotification({ userId: userdata?.id }));
    };

    const paymentSuccessfulHandler = (data) => {
      openCustomNotificationWithIcon("success", data.message);
      dispatch(getAllNotification({ userId: userdata?.id }));
    };

    const profilePicUpdated = (data) => {
      // console.log(data, "dataaaaaaaaupdatePicture");
      console.log(data, "dataaaaaaaaupdatePicture");
      openCustomNotificationWithIcon("success", data.message);
      dispatch(getAllNotification({ userId: userdata?.id }));
    };

    socket?.on("user-created", userCreatedHandler);
    socket?.on("contract-created", contractCreatedHandler);
    socket?.on("project-created", projectCreatedHandler);
    socket?.on("payment-successful", paymentSuccessfulHandler);
    socket?.on("dp-uploaded", profilePicUpdated);
    socket?.on("message", (data) => {
      console.log(data, "hellowmessageegegege");
    });
    
    // Clean up event listeners
    return () => {
      socket?.off("user-created", userCreatedHandler);
      socket?.off("contract-created", contractCreatedHandler);
      socket?.off("project-created", projectCreatedHandler);
      socket?.off("payment-successful", paymentSuccessfulHandler);
      socket?.off("dp-uploaded", profilePicUpdated);
      socket?.off("message", (data) => {
        console.log(data, "hellowmessageegegege");
      });
    };
  }, [socket]);

  console.log(process.env.REACT_APP_BAKREE_API_URL, "ASDKASKDKASKDASLDALSD");

  console.log(isLoggedin, "ISLOGGEDIN");
  console.log(userdata, "USERDATA");

  useEffect(() => {
    let hide;
    if (loadingPopUp === true) {
      hide = notification.info({
        message: "Loading, Please wait",
        description: "This might take a while",
        placement: "bottomRight",
        duration: 0,
      });
      console.log(hide, "hideeeeeeeee");
    } else {
      hide = notification.destroy();
    }
  }, [loadingPopUp]);

  return (
    <div
      className={`${
        pathname === "/collection-detail-dummy1"
          ? "overflow-hidden h-screen"
          : ""
      }`}
    >
      {userdata && <SideDrawer />}
      {userdata && <Nav />}
      <div className="ml-[80px] mt-[80px]">
        <Routes>
          <Route path="/signup" element={<SignupLandingPage />} />
          <Route exact index element={<SignIn />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/passwordreset" element={<ForgetPassword />} />
          <Route path="/user/reset-password" element={<ResetPassword />} />
          {/* LAYOUT ROUTE */}
          <Route
            path={`/createproject`}
            element={
              <ProtectedRoute user={userdata}>
                <IPFSSteps />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/testImage`}
            element={
              <ProtectedRoute user={userdata}>
                <TestImage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/projects"
            element={
              <ProtectedRoute user={userdata}>
                <ProjectList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/noproject"
            element={
              <ProtectedRoute user={userdata}>
                <NoProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute user={userdata}>
                <ProfileSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <ProtectedRoute user={userdata}>
                <Billing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute user={userdata}>
                <DashBoard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nft-generator"
            element={
              <ProtectedRoute user={userdata}>
                <NFTArtGen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/generated-result"
            element={
              <ProtectedRoute user={userdata}>
                <GeneratedResult />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-collection"
            element={
              <ProtectedRoute user={userdata}>
                <CreateCollection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/collection-detail"
            element={
              <ProtectedRoute user={userdata}>
                <CollectionDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/design-nft-artwork"
            element={
              <ProtectedRoute user={userdata}>
                <DesignNftByDesigner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mint-page-select"
            element={
              <ProtectedRoute user={userdata}>
                <MintSelection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/layer-form-marketplace"
            element={
              <ProtectedRoute user={userdata}>
                <LayerFormMarketP />
              </ProtectedRoute>
            }
          />
          {/* GET ALL users for admin */}
          <Route
            path="/allusers"
            element={
              <ProtectedRoute user={userdata}>
                <UsersList />
              </ProtectedRoute>
            }
          />
          <Route path="/landing-page" element={<LandingPage />} />
          {/* {PROTECTED ROUTE} */}
          <Route
            path="*"
            element={
              <ProtectedRoute user={userdata}>
                <GenericNotFound />{" "}
              </ProtectedRoute>
            }
          />

          {/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
