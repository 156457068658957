import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { disconnectWallet, loadWeb3 } from "../Components/SmartContract/connectWeb3";
import projectService from "../Services/project.service";
const initialState = {
layers: []
};

export const getLayerFromS3 = createAsyncThunk(
  "layers/getLayers",
  async (data, thunkAPI) => {
    console.log("calllllllllll");
    const response = await projectService.getLayers();
    console.log(response, "USERRRRasdasasd");
    return response.data;
  }
);

export const addLayerMarketPlace = createAsyncThunk(
  "layers/getLayers",
  async (formData, thunkAPI) => {
    console.log("calllllllllll");
    const response = await projectService.AddLayers(formData);
    console.log(response, "USERRRRasdasasd");
    return response;
  }
);

export const Layers = createSlice({
  name: "layers",
  initialState,
  reducers: {
    setipfs: (state, action) => {
      console.log(action.payload, "payloaadddddd from data");
      state.file = action.payload;
      // console.log(action.payload);
    },
  },



  extraReducers: (builder) => {
    builder
      .addCase(getLayerFromS3.fulfilled, (state, { payload }) => {
        console.log(payload, "layersssss payloadddd" );
       
         state.layers = payload
      
      })
      
  },
});

// export const storefile = (state) => state.storefiles

// console.log("storefile", storefile);

// Action creators are generated for each case reducer function
export const {
    setipfs
} = Layers.actions;

export default Layers.reducer;
