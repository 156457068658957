import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const Stripe = (props) => {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: props.clientSecret,
    };

    return(
        <Elements stripe={stripePromise} options={options}>
            {props.children}
        </Elements>
    );
}



export default Stripe;