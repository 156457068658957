import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const initialState = {
  layers: [],
  generatedData: [],
  imagesInFile: [],
  layersInFile: [],
  generating: false,
  count: 100,
  rarity: [],
  checkedIpfsUploading: false,
  numOfGeneratedImage: 0,
  downloadPrice: 20,
};

export const generatorSlice = createSlice({
  name: "generatorSlice",
  initialState,
  reducers: {
    setLayers: (state, action) => {
      console.log(action.payload, "Layerrrrrrrrrr");
      // let data = action.payload.map((x) => {
      //   return _.cloneDeep(x);
      // });

      // let items = [];

      // for (let i = 0; i < data.length; i++) {
      //   let tempArray = [];
      //   let imagesCollection = data[i].images;
      //   for (let j = 0; j < imagesCollection.imageName.length; j++) {
      //     tempArray.push({
      //       name: imagesCollection.imageName[j],
      //       file: imagesCollection.imageurl[j],
      //       rarity: 1,
      //     });
      //   }
      //   items.push(tempArray.length);
      //   data[i].images = tempArray;
      // }

      // const layerConfigurations = [
      //   {
      //     growEditionSizeTo: 20,
      //     items: items,
      //     layersOrder: data,
      //   },
      // ];

      // console.log(layerConfigurations, "RESUls");
      // state.layers = layerConfigurations;
    },
    setGeneratedData: (state, action) => {
      state.generatedData = action.payload;
    },
    setGenerating: (state, action) => {
      state.generating = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setImageInFile: (state, action) => {
      state.imagesInFile = action.payload;
    },
    setLayerInFile: (state, action) => {
      state.layersInFile = action.payload;
    },
    setRarity: (state, action) => {
      state.rarity = action.payload;
    },
    setNumOfImageGenerated: (state, action) => {
      state.numOfGeneratedImage = action.payload;
    },
    setRarity: (state, action) => {
      state.rarity = action.payload;
    },
    setCheckIpfsLoading: (state, action) => {
      state.checkedIpfsUploading = action.payload;
    },
    // setDownloadable: (state, action) => {
    //   state.downloadable = action.payload;
    // }
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(createProjectThunk.fulfilled, (state, { payload }) => {
  //         console.log({ payload });
  //         if (payload === 200) {
  //           state.loadingPopup = false;
  //           console.log(state.loadingPopup, "Payloaaad");
  //           state.projectCreated = true;
  //         }
  //       })
  //       .addCase(getProjects.fulfilled, (state, { payload }) => {
  //         state.projects = payload;
  //       });
  //   },
});

export const layersSelector = (state) => state.generator.layers;
export const generatedDataSelector = (state) => state.generator.generatedData;
export const generatingSelector = (state) => state.generator.generating;
export const imageFileSelector = (state) => state.generator.imagesInFile;
export const countSelector = (state) => state.generator.count;
export const downloadPrice = (state) => state.generator.downloadPrice;
export const ipfsuploadchecking = (state) =>
  state.generator.checkedIpfsUploading;
  export const generatedImageCount = (state) =>
  state.generator.numOfGeneratedImage;
// console.log("storefile", storefile);

// Action creators are generated for each case reducer function
export const {
  setLayers,
  setGeneratedData,
  setGenerating,
  setImageInFile,
  setCount,
  setRarity,
  setCheckIpfsLoading,
  setNumOfImageGenerated,
  setLayerInFile,
  // setDownloadable,
} = generatorSlice.actions;

export default generatorSlice.reducer;
