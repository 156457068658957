import React, { useEffect, useState } from 'react'
import getImageFromIPFS from '../utils/ipfs_image'

const TestImage = () => {
    const [images, setImages] = useState();
    useEffect(()=>{
        getImageFromIPFS("QmYqwDKLYdo3oayzGUwSL4oRFcYbSaYioHMe3g5NArgEmo").then(x => {
            setImages(x);
            console.log(x, "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxs");
        })
    }, [])
  return (
    <div>
        {images && (
        <img src={images} />
        )}
    </div>
  )
}

export default TestImage