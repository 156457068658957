import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import noproject from "../assets/noProject.png";
import { motion as m } from "framer-motion";

const GenericNotFound = () => {
    let navigate = useNavigate();
  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100 mx-auto">
<m.div initial={{opacity: 0.0}} animate={{opacity: 2}} transition={{duration: 1, ease: "easeOut"}} className="container flex flex-col items-center justify-center px-5 mx-auto my-8">        <img className="mx-auto" src={noproject} alt="" width={300} height={300} />
		<div className="max-w-md text-center">
			<h2 className="mb-2 mt-5 font-extrabold text-4xl dark:text-gray-600">
				<span className="sr-only">Error</span>404
			</h2>
			<p className="text-2xl font-semibold md:text-3xl">Sorry, we couldn't find this page.</p>
			<p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>
			<button onClick={() => {  navigate("/")}} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Back to Dashboard</button>
		</div>
	</m.div>
</section>
  );
};

export default GenericNotFound;
