import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { getAllUsers, user, usersList } from "../Slices/Auth";
import { getAllProjects } from "../Slices/data";
import { getDateInFormat } from "../utils/dateFormat";
import { openCustomNotificationWithIcon } from "../utils/Notification";
import { getAllSubsListThunk } from "../Slices/Stripe";


const UsersList = () => {
    const [projects, setProjects] = useState([]);
    const [paidIds, setPaidIds] = useState([]);
    const { isLoggedin, user:userdata } = useSelector(user);
    const usersData = useSelector(usersList);
    console.log(usersData, "usersData");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(userdata.username !== "admin1"){
            navigate("/dashboard");
        }else{
            dispatch(getAllUsers())
            // .unwrap().then(x=>{
            //     csv();
            // })
            dispatch(getAllSubsListThunk()).unwrap().then(x=>{
                console.log(x, "getAllSubsListThunk");
                setPaidIds(x?.map(data => data.customer));
            });
        }
    }, []);

    console.log(paidIds, "paidIds");

    // For downloading user list (Not Used)
    const csv = () => {
        let newData = [];
        for(let x of usersData){
            const date = new Date(x.createdAt).getDate() + "/" + (new Date(x.createdAt).getMonth() + 1) + "/" + new Date(x.createdAt).getFullYear();
            newData.push([ date, x.email, x.username ].join(","))
            
        }
        newData = newData.join("\n")
        console.log(newData, "newData");
        const csvFile = new Blob([newData], { type: "text/csv" });
        const url = URL.createObjectURL(csvFile);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "userlist.csv";
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const getProject = (id, setLoading) => {
        console.log("user id = " + id);
        dispatch(getAllProjects(id)).unwrap().then(data => {
            if(data.length === 0){
                openCustomNotificationWithIcon("info", "User has no Project");
            }
            setLoading(false);
            setProjects(data);
        })
    }

    if(projects.length > 0){
        return(
            <div className="container mt-4 h-full w-10/12 mx-auto md:max-w-11/12 bg-white px-10 max-h-full items-center">
                <div className="pb-8">
                <nav className="grid grid-cols-2 mt-5">
                    <div className="text-2xl font-medium mt-4">Projects</div>
                    <div className="text-right items-center">
                        <button 
                            className="mt-4 inline-flex items-center rounded-3xl border ml-4  px-4 py-3 text-sm  leading-4 bg-orange-500 text-white font-medium border-gray-300 hover"
                            onClick={()=>{setProjects([])}}
                        >Back</button>
                    </div>
                </nav>
                <div className="mt-8 flex flex-col item">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="">
                            <tr>
                                <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                Name
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                CID
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                Format
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                Size
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                Date
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {projects?.map((project) => (
                                <tr key={project.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {project.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <a
                                        href={`https://ipfs.io/ipfs/${project?.CID}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "blue" }}
                                        >
                                        {project?.CID}
                                        </a>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project?.type}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project?.size}MBs
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {project.createdAt}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }

    return(
        <div className="container mt-4 h-full w-10/12 mx-auto md:max-w-11/12 bg-white px-10 max-h-full items-center">
            <div className="pb-8">
            <nav className="grid grid-cols-2 mt-5">
                <div className="text-2xl font-medium mt-4">Users</div>
                {/* <div className="text-right items-center">
                </div> */}
            </nav>
            <div className="mt-8 flex flex-col item">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="">
                        <tr>
                            <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                            S.No
                            </th>
                            <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            User Name
                            </th>
                            <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            Email
                            </th>
                            <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            Created Date
                            </th>
                            <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Paid
                            </th>
                            <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            <nobr>User's Project</nobr>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {usersData?.map((user, i) => {
                            {/* if(user.username === "admin1") {
                                return;
                            } */}
                            return(
                                <tr key={user.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {i+1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {user.username}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {user.email}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {getDateInFormat(user.createdAt)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {paidIds.includes(user.stripeCustomerId) ? "True": "False"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <MyButton id={user.id} getProject={getProject} />
                                    </td>
                                </tr>
                        )})}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}


const MyButton = ({ id, getProject }) => {
    const [loading, setLoading] = useState(false);
    const onClicked = (id) => {
        setLoading(true);
        getProject(id, setLoading);
    }
    return(
        <button
            disabled={loading}
            className={`flex rounded-full bg-indigo-600 ${loading ? "":"hover:bg-indigo-800 focus:ring-indigo-800"} text-white pl-4 pr-4 pt-2 pb-2 `}
            onClick={() => onClicked(id)}
            >
                
                Project List
                <span>
                    <ClipLoader
                      cssOverride={{ display: "block", marginLeft: "1rem", borderColor: "white" }}
                      color={"black"}
                      loading={loading}
                      size={20}
                    />
                  </span>
                  
            </button>
    );
}

export default UsersList;