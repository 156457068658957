import authHeader from "./auth-header";
import authApi from "./axiosConfig";

class NotificationService {
  //   getPublicContent() {
  //     return axios.get(API_URL + 'all');
  //   }

  getAllNotification({ userId }) {
    return authApi.get(`get-all-notificaion/${userId}`, {
      headers: authHeader(),
    });
  }

  updateAllNotification(userId) {
    console.log(userId, "userIDdfromService");
    return authApi.put(`update-all-notificaion`,{userId: userId}, { headers: authHeader() });
  }
}
export default new NotificationService();
