import axios from 'axios';
import authHeader from './auth-header';
import authApi from './axiosConfig';

const API_URL = `http://localhost:8080/api/`;




class UserService {
  getPublicContent() {
    return authApi.get('all');
  }

  getUserBoard() {
    return authApi.get('user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return authApi.get('mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return authApi.get('admin', { headers: authHeader() });
  }
  // updateUser(data, id){
  //   // console.log(username + ' ' + password, email, id);
  //   return axios.put(API_URL + "user/update",{
  //     data,
  //     id: id,
  //   }, {headers: authHeader()});
  // }
  updatedUserInfo(data,id) {
    return authApi
      .put("user/update",{...data,id},{ headers: {...authHeader(), 'Content-Type': 'multipart/form-data'}});
  }
  
  getAllUsersData(){
    return authApi.get("admin/getusers", { headers: authHeader() });
  }
  
}

export default new UserService();
