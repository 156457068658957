import { Image } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addMintLayoutInDeployedContract } from '../Slices/contract';
import { openCustomNotificationWithIcon } from '../utils/Notification';
import { ClipLoader } from "react-spinners";
import MyOverLayLoading from '../Components/OverlayLoader';
// import mint1 from "../assets/"
// import mint2 from "../../public/Mint2.jpg"

const override = {
    display: "block",
    marginLeft: "1rem",
    borderColor: "white",
  };

const mintingPageData = [
    {id:1, img: "./1.jpg", url: 'http://localhost:8080/mint1/1'},
    // {id:2, img: "./2.jpg", url: 'http://localhost:8080/mint1/2'},
    {id:3, img: "./3.jpg", url: 'http://localhost:8080/mint1/3'},
    // {id:4, img: "./4.jpg", url: 'http://localhost:8080/mint1/4'},
    {id:5, img: "./5.jpg", url: 'http://localhost:8080/mint1/5'},
    {id:6, img: "./6.jpg", url: 'http://localhost:8080/mint1/6'},
]

const MintSelection = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    console.log(state, "state");

    const selectmintPage = (id) => {
        console.log(id, "idddddddddddddd");
        setLoading(true);
        dispatch(addMintLayoutInDeployedContract({ layout: id, cid: state?.cid , address: state?.address }))
        .unwrap()
        .then(x => {
            console.log("MintPage", x);
            if(x?.error){
                openCustomNotificationWithIcon("error", x?.error, 3);
            }
            else if(x?.success){
                openCustomNotificationWithIcon("success", "Successfully Mint Your page", 3);
            }
            setLoading(false);
            navigate("/projects");
        })
    }

if(!state){
    navigate("/projects")
}

  return (
    <div className="pb-5">
        {loading && 
            <MyOverLayLoading newOverlay={true}>
                <ClipLoader
                    cssOverride={override}
                    color={"black"}
                    loading={loading}
                    size={20}
                />
            </MyOverLayLoading>}
        <div className='ml-3 bg-white py-4 pb-8 px-2 mr-3'>
            <div className="mt-1">
                <h1 className='text-center text-3xl'>Select Mint Page</h1>
            </div>
            <div className="flex flex-wrap w-full justify-center">
                {mintingPageData.map((data, index) => (
                    <div className='mt-4 ml-4 w-[300px] h-[300px]'>
                        {/* <a href={data.url} target="_blank" rel="noreferrer" > */}
                            <Image
                                onClick={() => selectmintPage(data.id)}
                                width={300}
                                height={300}
                                src={data.img}
                                preview={{ 
                                    mask: <span>Click Here to Select Your Mint Page</span>,
                                    visible: false,
                                }}
                            />
                        {/* </a> */}
                </div>))}
            </div>
        </div>
    </div>
  )
}

export default MintSelection