import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useSelector } from 'react-redux'
import { ClimbingBoxLoader } from 'react-spinners'

function Progress() {
  const percentage = useSelector(state => state.storefiles.percentage)

  return (
    <div className='flex justify-center sm:justify-start items-center'>
        <div style={{ width: 200, height: 200 }}>
         {percentage===100?
         <div>
         <ClimbingBoxLoader color="#F05428" />
         <h1>Creating Directory</h1>
         </div>
         : <CircularProgressbar value={percentage} text={`${percentage}%`}
            strokeWidth={2}
            styles={{
              // Customize the root svg element
              root: {},
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                stroke: `#F05428`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'square',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: '#d6d6d6',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the text
              text: {
                // Text color
                fill: '#F05428',
                // Text size
                fontSize: '16px',
              },
              // Customize background - only used when the `background` prop is true

            }}
          />}
        </div>
      </div>
  )
}

export default Progress