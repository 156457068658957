import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { user } from "../../Slices/Auth";
import { downloadPrice } from "../../Slices/GeneratorSlice";
import { createCustomSessionThunk } from "../../Slices/Stripe";
import CheckoutForm from "../billing_components/CustomCheckout";
import StripeWrapper from '../billing_components/StripeWrapper';
import MyOverLayLoading from "../OverlayLoader";
import loaderIMg from "../../assets/NftLoader.svg";

const DownloadBtn = ({ downloadAfterPayment, uploadToIPFS }) => {
    const [payPopup, setPayPopup] = useState(false);
    const [loadingdownload, setLoadingDownload] = useState(false);
    const [intent, setIntent] = useState({});
    const [preview, setPreview] = useState(false);

    // Redux 
    const amount = useSelector(downloadPrice);
    const users = useSelector(user);

    const dispatch = useDispatch();

    const handleClickDonwload = async () => {
        setPayPopup(prev => true);
    };

    const closeModal = () => {
        setPayPopup(false);
        setLoadingDownload(false);
        setPreview(false);
      }
    
      const handleCheckout = () => {
        setPayPopup(false);
        setLoadingDownload(true);
        dispatch(createCustomSessionThunk({ id: users.user.stripeCustomerId, amount: amount })).unwrap().then(data => {
          setIntent(data.data);
          setPreview(true);
        })
      }

      const download = async () => {
        setLoadingDownload(true);
        await downloadAfterPayment()
        setTimeout(()=>{
            setLoadingDownload(false)
        },1000);
      }

    return(
        <div>
            {!payPopup && loadingdownload && 
                <MyOverLayLoading closeModal={closeModal}>
                    <ClipLoader cssOverride={{
                        display: "block",
                        marginLeft: "1rem",
                        borderColor: "black",
                    }}
                    color="black"
                    loading={true}
                    size={30}
                    />
                </MyOverLayLoading>}
            {preview ? (
                <StripeWrapper clientSecret={intent.client_secret}>
                    <CheckoutForm
                        show = {preview}
                        onHide={() => closeModal()}
                        intent={intent}
                        downloadAfterPayment={download}
                        setPayPopup={setPayPopup}
                        closeModal={closeModal}
                    />
                </StripeWrapper>
                ): (<></>)
            }
            {payPopup && <MyOverLayLoading closeModal={closeModal}>
                <img src={loaderIMg} style={{ margin: "auto" }} alt=""/>
                <h2 style={{fontSize:"20px", padding: '5px 25px', borderRadius: '6px', textAlign: 'center'}}>For Downloading Images and Metadata You have to Pay $20</h2>
                <div className="border-b-2 border-gray-400 w-4/6"></div>
                <h2 style={{fontSize:"20px", padding: '5px 25px', borderRadius: '6px', textAlign: 'center'}}>Or You can directly <span className="text-blue-700 font-bold">Upload</span> it on IPFS <span className="text-blue-700 font-bold">Free</span></h2>
                <div className="flex mt-4 justify-center">
                <button className="ml-6 inline-flex items-center rounded-md border border-transparent hover:bg-indigo-800
                                    bg-indigo-700 px-7 py-3 text-sm font-medium text-white shadow-sm focus:outline-none 
                                    focus:ring-2 focus:ring-indigo-900 focus:ring-offset-2"
                        onClick={() => handleCheckout()}>Pay Now</button>
                {/* <button className="ml-6 inline-flex items-center rounded-md border border-transparent hover:bg-gray-300
                                    bg-gray-200 px-7 py-3 text-sm font-medium text-black shadow-sm focus:outline-none 
                                    focus:ring-2 focus:ring-offset-2" onClick={() => closeModal()}>Cancel</button> */}
                <button className="ml-6 inline-flex items-center rounded-md border border-transparent hover:bg-gray-300
                                    bg-gray-200 px-7 py-3 text-sm font-medium text-black shadow-sm focus:outline-none 
                                    focus:ring-2 focus:ring-offset-2" onClick={uploadToIPFS}>Upload</button>
                </div>
                {/* <p style={{fontSize:"12px", background: "blue",color: 'white', padding: '5px 15px', borderRadius: '6px'}}>Your Download Start Quickly</p> */}
            </MyOverLayLoading>}
            <button
                onClick={handleClickDonwload}
                disabled={loadingdownload}
                className="ml-6 inline-flex items-center rounded-md border border-transparent bg-white px-7 py-3 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                Download NFTs
            </button>
        </div>
    );
}


export default DownloadBtn;