import Web3 from "web3";
import { openCustomNotificationWithIcon } from "../../utils/Notification";
let isItConnected = false;
const networks = {
  bsc: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: "Avalanche Fuji Testnet",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: [
      // "https://api.avax-test.network/ext/bc/C/rpc"
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};
const changeNetwork = async ({ networkName }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName],
        },
      ],
    });
  } catch (err) {
    console.log("not found");
  }
};
const handleNetworkSwitch = async (networkName) => {
  await changeNetwork({ networkName });
};
export const getAccounts = async () => {
  if (typeof window.web3 === "undefined") {
    // Handle the case when web3 is not available
    console.log("Web3 is not available.");
    return { error: "Web3 is not available." };
  }

  const web3 = window.web3;
  try {
    const accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (error) {
    console.log("Error while fetching accounts: ", error);
    return null;
  }
};
export const disconnectMetamask = async () => {
  if (typeof window.ethereum !== "undefined" && window.ethereum.request) {
    try {
      return { success: false, message: "Meta Mask Disconnected" };
    } catch (error) {
      console.log("Error disconnecting Metamask:", error);
      return { success: false, message: error };
    }
  } else {
    console.log("Metamask disconnection not supported");
    return { success: false, message: "Metamask disconnection not supported" };
  }
};

let web3;

export const loadWeb3 = async (selectedNetwork) => {
  console.log(window.ethereum.selectedAddress, "etherumconsolee");
  // debugger
  try {
    const { ethereum } = window;
    if (ethereum) {
      console.log("etherumnn providerre detected");
      await ethereum.request({ method: "eth_requestAccounts" });
      web3 = new Web3(ethereum);
      const switchNetworkResponse = await SwitchNetwork(selectedNetwork);
      if (switchNetworkResponse.success) {
        const accounts = await web3.eth.getAccounts();
        return {
          success: switchNetworkResponse.success,
          message: switchNetworkResponse.message,
          accounts: accounts[0],
        };
      } else {
        return {
          success: switchNetworkResponse.success,
          message: switchNetworkResponse.message,
        };
      }

      // window.web3 = new Web3(window.ethereum);
      // await window.web3.eth.getChainId((err, netId) => {
      // console.log("networkId==>", netId);
      // switch (netId.toString()) {
      //   case "97":
      //     isItConnected = true;
      //     break;
      //   default:
      //     handleNetworkSwitch("bsc");
      //     isItConnected = false;
      // }
      // });
      //   if (true) {
      //     let accounts = await getAccounts();
      //     return accounts[0];
      //   } else {
      //     let res = "Wrong Network";
      //     return res;
    } else {
      console.log("disconnected");
      return { success: false, error: "No Wallet Found" };
    }
    // } else {
    //   let res = "No Wallet";
    //   return res;
    // }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getCurrentChainId = async () => {
  const currentChainId = await web3.eth.getChainId();
  console.log(currentChainId, "currentChainn IDDD");
  return currentChainId;
};

const SwitchNetwork = async (chainId) => {
  try {
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId != chainId) {
      // Rest of the code...
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
      return { success: true, message: "Switched to network successfully" };
    } else {
      return {
        success: true,
        message: "Connected and already on same Network",
      };
    }
  } catch (error) {
    if (error.code === 4001) {
      
      return { success: false, message: "You have rejected connecting wallet" };
      
    } else if (error.code === 4902 && chainId === 137) {
      const addNetworkResponse = await addNetwork(polygon);
      if (!addNetworkResponse.success) {
        return { success: false, message: addNetworkResponse.message };
      }
      return { success: true, message: "Switching network to Polygon" };
    } else if (error.code === 4902 && chainId === 56) {
      const addNetworkResponse = await addNetwork(bnb);
      if (!addNetworkResponse.success) {
        return { success: false, message: addNetworkResponse.message };
      }
      return { success: true, message: "Switching network to BNB" };
    } else if (error.code === 4902 && chainId === 1) {
      const addNetworkResponse = await addNetwork(ethererum);
      if (!addNetworkResponse.success) {
        return { success: false, message: addNetworkResponse.message };
      }
      return { success: true, message: "Switching network to Ethereum" };
    } else if (error.code === -32603) {
      return {
        success: false,
        message: "User rejected Request to add network",
      };
    } else {
      return { success: false, message: error.message };
    }
  }
  // const currentChainId = await web3.eth.getChainId();
  // if (currentChainId != chainId) {
  //   try {
  //     console.log(`switched to chainId:${chainId} successfully `);
  //     return { success: true, message: "Switched to network successfully" };
  //   } catch (error) {
  //     if (error.code === 4001) {
  //       return { success: false, error: "You have rejected connecting wallet" };
  //     } else if (error.code === 4902 && chainId === 137) {
  //       addNetwork(polygon);
  //       return { success: true, message: "Switching network to Polygon" };
  //     } else if (error.code === 4902 && chainId === 56) {
  //       addNetwork(bnb);
  //       return { success: true, message: "Switching network to BNB" };
  //     } else {
  //       return { success: false, error: error.message };
  //     }
  //   }
  // } else {
  //   return { success: false, error: "something went wrong" };
  // }
};

const polygon = {
  chainId: Web3.utils.toHex(137),
  chainName: "Polygon Mainnet",
  nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18 },
  rpcUrls: ["https://polygon-rpc.com/"],
  // blockExplorerUrl:["https://polygonscan.com"],
};

const ethererum = {
  chainId: Web3.utils.toHex(1),
  chainName: "Ethereum Mainnet",
  nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  rpcUrls: ["https://mainnet.infura.io/v3/"],
  // blockExplorerUrl:["https://polygonscan.com"],
};

const bnb = {
  chainId: Web3.utils.toHex(56),
  chainName: "BNB Smart Chain (previously Binance Smart Chain Mainnet)",
  nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
  rpcUrls: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.binance.org",
    "https://bsc-dataseed2.binance.org",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed4.binance.org",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed4.ninicoin.io",
    "wss://bsc-ws-node.nariox.org",
  ],
  // blockExplorerUrl:"https://bscscan.com/",
};

const addNetwork = async (networkDetail) => {
  debugger
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [networkDetail],
    });
    return { success: true, message: "Network added successfully" };
  } catch (error) {
    // This error code indicates that the user rejected adding the network
    if (error.code === 4001) {
      return { success: false, message: error.message };
    } else {
      return { success: false, message: error.message };
    }
  }
};
