import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProjects } from "../../Slices/data";
import ContractForm from "./ContractForm";


const ContractBtn = ({ cid ,externalContract, projectId }) => {
    const [contract, setContract] = useState({
        openForm: false,
        cid: null,
      });

    const dispatch = useDispatch();

    const handleContract = () => {
        console.log("cid = " + cid);
        setContract(prev => ({ ...prev, openForm: true }));
    }

    const handleCancel = () => {
        dispatch(getProjects());
        setContract({ cid: null, openForm: false });
    }

    

    return(
        <>
            {externalContract ?
            <h3 onClick={handleContract}>Blockchain Contract </h3>
            :
            <Link onClick={handleContract}
                className="text-[#F05428] hover:bg-[#F05428] hover:text-white px-2 py-1 rounded-md"
                // className="text-white bg-indigo-600 pt-2 pb-2 pl-4 pr-4 hover:bg-indigo-800 focus:bg-indigo-800 rounded-full"
            >Contract</Link>}
            {contract.openForm && <ContractForm projectId={projectId} externalContract={externalContract} cid={cid} cancel={handleCancel} />}
        </>
    );
}

export default ContractBtn;