import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import smartContract from '../Services/contract.service';

const initialState = {
    solidityFileData: null,
    byteCodeAndAbi: null,
}

export const createSolFile = createAsyncThunk(
    "smartContract/createSolFile",
    async (data, thunkAPI) => {
        console.log(data, "thunk:createSolFile");
        const file = await smartContract.getSolidityFile(data);
        // console.log(file, "getSolidityFile");
        return file;
    }
);

export const getByteCodeAndAbi = createAsyncThunk(
    "smartContract/getByteCodeAndAbi",
    async (data, thunkAPI) => {
        const result = await smartContract.getByteCodeAndAbi(data);
        console.log(result, "slice: getByteCodeAndAbi");
        return result;
    }
)

export const addDeployContractAddressInDB = createAsyncThunk(
    "smartContract/addDeployContractAddressInDB",
    async (data, thunkAPI) => {
        const result = await smartContract.addAddress(data);
        console.log(result.data, "addDeployContractAddressInDB");
        return result.data
    }
)

export const addMintLayoutInDeployedContract = createAsyncThunk(
    "smartContract/addMintLayoutInDeployedContract",
    async (data, thunkAPI) => {
        const result = await smartContract.addLayout(data);
        console.log(result.data, "addMintLayoutInDeployedContract");
        return result.data
    }
)

export const contractSlice = createSlice({
    name: "smartContract",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(createSolFile.fulfilled, (state, action) => {
            console.log(action.payload, "payloaddddsoliditfile");
            state.solidityFileData = action.payload;
        });
        builder.addCase(getByteCodeAndAbi.fulfilled, (state, action) => {
            state.byteCodeAndAbi = action.payload;
        });
    }
})


export default contractSlice.reducer;