import React, { useEffect, useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { user } from "../../Slices/Auth";
import { ClipLoader } from "react-spinners";
import {
  createSessionThunk,
  getPriceList,
  getSubsListThunk,
  priceList,
  stripeSession,
  subscriptionList,
  upSub,
} from "../../Slices/Stripe";
import { centsToDollar } from "../../utils/centsToDollar";
import { openCustomNotificationWithIcon } from "../../utils/Notification";
import { motion as m } from "framer-motion";

const Plan = () => {
  const navigate = useNavigate();
  const pricesL = useSelector(priceList);
  const { user: userdata } = useSelector(user);
  const session = useSelector(stripeSession);
  const subsList = useSelector(subscriptionList);
  console.log(subsList, "SUBSLISTTFBKAS")
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  let [color, setColor] = useState("black");
  console.log("SESSION", prices);

  console.log({subsList});

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };
  console.log(subsList, "SubsssListss");
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(getPriceList());
    dispatch(getSubsListThunk(userdata.stripeCustomerId));
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  // useEffect(() => {
  //   if(session){

  //     window.location.href=session?.data?.url
  //     // navigate()
  //   }
  // }, [session])

  const handleCheckout = (priceItem) => {

    console.log("clickes")
    dispatch(createSessionThunk({ priceItem, userId: userdata.id })).then(
      (data) => {
        window.location.href = data?.payload?.data?.url;
      }
    );
  };
  useEffect(() => {
    calSubsClasses();
  }, [pricesL]);

  const handleUpgrade = (subscriptionId, priceId) => {
    //APPIII

    dispatch(upSub({ subscriptionId, priceId })).then((data) => {
      console.log(data, "DATATATATATAT");
    });
  };

  const calSubsClasses = () => {
    const classVal = "border-double border-4 border-sky-500";
    // border-double border-4 border-sky-500
    setLoading(true);
    // if(subsList.length === 0){
    //   openCustomNotificationWithIcon("warning", "You're Logged in from Test Account, Please Create Another Account");
    //   navigate("/dashboard")
    //   return
    // }
    if (subsList?.data?.length === 0) {
      const tempPrices = pricesL?.map((x) => {
        if (x?.name === "Beginner") {
          return { ...x, classList: classVal };
        } else {
          return x;
        }
      });
      console.log("IFFFFFFF");
      setLoading(false);
      setPrices(tempPrices);
    } else {
      // debugger
      const tempPrices = pricesL?.map((x) => {
        if (x?.default_price === subsList?.data[0]?.plan?.id) {
          return { ...x, classList: classVal };
        } else {
          return x;
        }
      });
      setPrices(tempPrices);

      console.log(tempPrices, "TEMP PRICESSS");
    }
  };
  console.log(prices, "PRICESSSS");
  return (
    <m.div initial={{opacity: 0.0}} animate={{opacity: 2}} transition={{duration: 1, ease: "easeOut"}} className="container flex flex-col items-center md:flex-row md:items-start  w-full">
      <div className="w-full md:w-1/3  border-2 rounded-lg  pb-12">
        <div className="p-4 pt-5 ">
          {prices
            ?.filter((x) => x?.classList)
            ?.map((str) => (
              <strong className="text-lg text-blue-800">
                Current Plan: {str?.name}
              </strong>
            ))}



          {prices
            ?.filter((x) => x?.classList)
            ?.map((x) => {
              return x?.description?.split(",")?.map((str) => {
                const fistStr = str?.split(":")[0];
                const SecStr = str?.split(":")[1];
                console.log(fistStr, "STRRRRRRRR");
                return (
                  <div className="flex justify-between mt-4 text-lg">
                    <h3 className="font-normal">{fistStr}</h3>
                    <h3 className="font-normal">{SecStr}</h3>
                  </div>
                );
              });
            })}
          {/*        
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Total Gateway</h3>
            <h3 className="font-normal">0 / 1 GB</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">No. of items pin</h3>
            <h3 className="font-normal">4 / 20000</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Minutes of Video</h3>
            <h3 className="font-normal">0 / 50</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Montly Metrics</h3>
            <h3 className="font-normal">Montly Usage</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Gateway Bandwidth</h3>
            <h3 className="font-normal">0 / 100 GB</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">No. of images</h3>
            <h3 className="font-normal">0 / 5000</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Min of Video</h3>
            <h3 className="font-normal">0 / 500</h3>
          </div>
          <div className="flex justify-between mt-4 text-lg">
            <h3 className="font-normal">Total Requests</h3>
            <h3 className="font-normal">0 / 100000</h3>
          </div> */}
          {/* <Link className="font-semibold underline flex justify-end  text-blue-800">
            Update all package
          </Link> */}
        </div>
      </div>
      <div className="w-full md:w-7/12 mx-auto scroll-edit overflow-y-auto p-5 md:h-[600px]">
        {loading == true ? (
          <div className="flex h-full w-full justify-center items-center ">
            {" "}
            <ClipLoader
              cssOverride={override}
              color={color}
              loading={loading}
              size={120}
            />{" "}
          </div>
        ) :
          (
            <>
              {
                prices?.map((x) => {
                  // if (x?.for === 'nft_art_genration') {
                    return (
                      <>
                        {/* <h3>Generator</h3> */}
                        <div className={`relative  rounded-lg p-5 px-3 pt-6 mb-5 bg-blue-50 ${x?.classList} `}>
                          <div class="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-xl sm:text-3xl font-semibold text-blue-800 mb-0">
                                {x.name}
                              </p>
                              {/* <p className="mb-2  ">{x.description}</p> */}
                            </div>

                            <div className="flex justify-end">
                              <div className=" font-normal text-gray-500 flex flex-col ">
                                <span>
                                  <span className="font-bold text-2xl text-black">
                                    {centsToDollar(x?.priceItem?.unit_amount)}/
                                  </span>
                                  <span className="text-2xl">month</span>
                                </span>{" "}
                                {x?.name === "Beginner" ? (
                                  <></>
                                ) : (
                                  <>
                                    <button
                                      class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                                      onClick={
                                        subsList?.data?.length === 0
                                          ? () => handleCheckout(x?.priceItem?.id)
                                          : () =>
                                            handleUpgrade(
                                              subsList[0]?.id,
                                              x?.priceItem?.id
                                            )
                                      }
                                    >
                                      {`${subsList?.data?.length > 0 ? "Upgrade" : "Paynow"
                                        }`}
                                    </button>
                                  </>
                                )}
                                {/* <button
                      onClick={() => handleCheckout(x?.priceItem?.id)}
                      class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                    >
                      
                    </button> */}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between "></div>
                          <div className="flex w-4/2 justify-between">
                            {x?.description?.split(",")?.map((str) => (
                              <p className="mt-3 flex items-center">
                                <span className="text-xl mr-1">
                                  <BsCheck2 />
                                </span>{" "}
                                {str}
                              </p>
                            ))}

                            {/* <p className="mt-3 flex items-center">
                  <span className="text-xl mr-1">
                    <BsCheck2 />
                  </span>{" "}
                  50 GB of Storage
                </p>
                <p className="mt-3  flex items-center">
                  <span className="text-xl mr-1">
                    <BsCheck2 />
                  </span>{" "}
                  1 Gateway
                </p> */}
                          </div>
                          <div className="flex justify-end align-bottom"></div>
                        </div>
                      </>
                    );
                  // }
                //   else if (x?.for === 'manual_upload_ipfs') {
                //     return (
                //       <>
                //         <h3>Ipfs</h3>
                //         <div
                //           className={`relative  rounded-lg p-5 px-3 pt-6 mb-5 bg-blue-50 ${x?.classList} `}
                //         >

                //           {/* card header */}

                //           <div class="grid grid-cols-2 gap-4">
                //             <div>
                //               <p className="text-3xl font-semibold text-blue-800 mb-0">
                //                 {x.name}
                //               </p>
                //               <p className="mb-2  ">{/* {x.description} */}</p>
                //             </div>

                //             <div className="flex justify-end">
                //               <div className=" font-normal text-gray-500 flex flex-col ">
                //                 <span>
                //                   <span className="font-bold text-2xl text-black">
                //                     {centsToDollar(x?.priceItem?.unit_amount)}/
                //                   </span>
                //                   <span className="text-2xl">month</span>
                //                 </span>{" "}
                //                 {x?.name === "Beginner" ? (
                //                   <></>
                //                 ) : (
                //                   <>
                //                     <button
                //                       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //                       onClick={
                //                         subsList?.data?.length === 0
                //                           ? () => handleCheckout(x?.priceItem?.id)
                //                           : () =>
                //                             handleUpgrade(
                //                               subsList[0]?.id,
                //                               x?.priceItem?.id
                //                             )
                //                       }
                //                     >
                //                       {`${subsList?.data?.length > 0 ? "Upgrade" : "Paynow"
                //                         }`}
                //                     </button>
                //                   </>
                //                 )}
                //                 {/* <button
                //       onClick={() => handleCheckout(x?.priceItem?.id)}
                //       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //     >
                      
                //     </button> */}
                //               </div>
                //             </div>
                //           </div>
                //           <div className="flex justify-between "></div>
                //           <div className="flex w-4/2 justify-between">
                //             {x?.description?.split(",")?.map((str) => (
                //               <p className="mt-3 flex items-center">
                //                 <span className="text-xl mr-1">
                //                   <BsCheck2 />
                //                 </span>{" "}
                //                 {str}
                //               </p>
                //             ))}

                //             {/* <p className="mt-3 flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   50 GB of Storage
                // </p>
                // <p className="mt-3  flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   1 Gateway
                // </p> */}
                //           </div>
                //           <div className="flex justify-end align-bottom"></div>
                //         </div>
                //       </>
                //     );
                //   }
                //   else if (x.for === 'smart_contract') {
                //     return (
                //       <>
                //         <h3>Smart Contract</h3>
                //         <div
                //           className={`relative  rounded-lg p-5 px-3 pt-6 mb-5 bg-blue-50 ${x?.classList} `}
                //         >

                //           {/* card header */}

                //           <div class="grid grid-cols-2 gap-4">
                //             <div>
                //               <p className="text-3xl font-semibold text-blue-800 mb-0">
                //                 {x.name}
                //               </p>
                //               <p className="mb-2  ">{/* {x.description} */}</p>
                //             </div>

                //             <div className="flex justify-end">
                //               <div className=" font-normal text-gray-500 flex flex-col ">
                //                 <span>
                //                   <span className="font-bold text-2xl text-black">
                //                     {centsToDollar(x?.priceItem?.unit_amount)}/
                //                   </span>
                //                   <span className="text-2xl">month</span>
                //                 </span>{" "}
                //                 {x?.name === "Beginner" ? (
                //                   <></>
                //                 ) : (
                //                   <>
                //                     <button
                //                       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //                       onClick={
                //                         subsList?.data?.length === 0
                //                           ? () => handleCheckout(x?.priceItem?.id)
                //                           : () =>
                //                             handleUpgrade(
                //                               subsList[0]?.id,
                //                               x?.priceItem?.id
                //                             )
                //                       }
                //                     >
                //                       {`${subsList?.data?.length > 0 ? "Upgrade" : "Paynow"
                //                         }`}
                //                     </button>
                //                   </>
                //                 )}
                //                 {/* <button
                //       onClick={() => handleCheckout(x?.priceItem?.id)}
                //       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //     >
                      
                //     </button> */}
                //               </div>
                //             </div>
                //           </div>
                //           <div className="flex justify-between "></div>
                //           <div className="flex w-4/2 justify-between">
                //             {x?.description?.split(",")?.map((str) => (
                //               <p className="mt-3 flex items-center">
                //                 <span className="text-xl mr-1">
                //                   <BsCheck2 />
                //                 </span>{" "}
                //                 {str}
                //               </p>
                //             ))}

                //             {/* <p className="mt-3 flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   50 GB of Storage
                // </p>
                // <p className="mt-3  flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   1 Gateway
                // </p> */}
                //           </div>
                //           <div className="flex justify-end align-bottom"></div>
                //         </div>
                //       </>
                //     );
                //   }
                //   else if(x?.for == undefined) {
                //     return (
                //       <>
                //       <h3>Others</h3>
                //         <div
                //           className={`relative  rounded-lg p-5 px-3 pt-6 mb-5 bg-blue-50 ${x?.classList} `}
                //         >

                //           {/* card header */}

                //           <div class="grid grid-cols-2 gap-4">
                //             <div>
                //               <p className="text-3xl font-semibold text-blue-800 mb-0">
                //                 {x.name}
                //               </p>
                //               <p className="mb-2  ">{/* {x.description} */}</p>
                //             </div>

                //             <div className="flex justify-end">
                //               <div className=" font-normal text-gray-500 flex flex-col ">
                //                 <span>
                //                   <span className="font-bold text-2xl text-black">
                //                     {centsToDollar(x?.priceItem?.unit_amount)}/
                //                   </span>
                //                   <span className="text-2xl">month</span>
                //                 </span>{" "}
                //                 {x?.name === "Beginner" ? (
                //                   <></>
                //                 ) : (
                //                   <>
                //                     <button
                //                       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //                       onClick={
                //                         subsList?.data?.length === 0
                //                           ? () => handleCheckout(x?.priceItem?.id)
                //                           : () =>
                //                             handleUpgrade(
                //                               subsList[0]?.id,
                //                               x?.priceItem?.id
                //                             )
                //                       }
                //                     >
                //                       {`${subsList?.data?.length > 0 ? "Upgrade" : "Paynow"
                //                         }`}
                //                     </button>
                //                   </>
                //                 )}
                //                 {/* <button
                //       onClick={() => handleCheckout(x?.priceItem?.id)}
                //       class="bg-blue-800 hover:bg-blue-800  text-white font-bold py-1 p3-4 rounded-lg"
                //     >
                      
                //     </button> */}
                //               </div>
                //             </div>
                //           </div>
                //           <div className="flex justify-between "></div>
                //           <div className="flex w-4/2 justify-between">
                //             {x?.description?.split(",")?.map((str) => (
                //               <p className="mt-3 flex items-center">
                //                 <span className="text-xl mr-1">
                //                   <BsCheck2 />
                //                 </span>{" "}
                //                 {str}
                //               </p>
                //             ))}

                //             {/* <p className="mt-3 flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   50 GB of Storage
                // </p>
                // <p className="mt-3  flex items-center">
                //   <span className="text-xl mr-1">
                //     <BsCheck2 />
                //   </span>{" "}
                //   1 Gateway
                // </p> */}
                //           </div>
                //           <div className="flex justify-end align-bottom"></div>
                //         </div>
                //       </>
                //     );
                //   }
                })
              }
            </>
          )
        }

        {/* <div className=" border-2 rounded-lg">
          <h1>Free</h1>
        </div>
        <div className=" border-2 rounded-lg">
          <h1>Free</h1>
        </div> */}
      </div>
    </m.div>
  );
};

export default Plan;
