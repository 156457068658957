import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationService from "../Services/notification.service";

const initialState = {
  notification: [],
  loadingPopup: false,
};

export const getAllNotification = createAsyncThunk(
  "notification/getAllNotification",
  async (userId, thunkAPI) => {
    console.log(userId, "userIddddddd");
    const response = await notificationService.getAllNotification(userId);
    console.log(response, "userIddResponseeee");
    return response.data;
  }
);

export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async (userId, thunkAPI) => {
    console.log(userId, "userIddddddd");
    const response = await notificationService.updateAllNotification(userId);
    console.log(response, "userIddResponseeee");
    return response;
  }
);

export const GetNotification = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setipfs: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotification.fulfilled, (state, { payload }) => {
      console.log(payload, "projectthunk");

      state.loadingPopup = false;
      state.notification = payload;
    });
    builder.addCase(updateNotification.fulfilled, (state, { payload }) => {
      console.log(payload, "updatepayloadddddnotification");

    
    });
  },
});



export const {
  setipfs,
  // setDownloadable,
} = GetNotification.actions;

export default GetNotification.reducer;
