import axios from "axios";
import authHeader from "./auth-header";
import authApi from "./axiosConfig";

const API_URL = `http://localhost:8080/api/project/`;

class Projectervice {
  //   getPublicContent() {
  //     return axios.get(API_URL + 'all');
  //   }

  createProject({ name, CID, userId, type, size, count }) {
    return authApi.post(
      "project/createproject",
      { name, CID, userId, type, size, count },
      { headers: authHeader() }
    );
  }
  getAllProject() {
    return authApi.get("project/getProject", { headers: authHeader() });
  }
  deleteProject(projectID) {
    return authApi.delete(`project/delete-project/${projectID}`, {
      headers: authHeader(),
    });
  }

  getAllProjectByUserId(id) {
    return authApi.get(`project/getUserProject/${id}`, {
      headers: authHeader(),
    });
  }

  uploadImageToS3Bucket({ images }) {
    console.log(images, "projService: uploadImageToS3Bucket");
    return authApi.post(
      "/project/uploadImages",
      { images },
      { headers: { ...authHeader(), "Content-Type": "multipart/form-data" } }
    );
  }

  adds3AddMeta({ cid_metadata, cid_image, s3_url }) {
    console.log(cid_metadata, cid_image, s3_url, "projService: adds3AddMeta");
    return authApi.post(
      "/project/add-s3url-metadata",
      { cid_metadata, cid_image, s3_url },
      { headers: authHeader() }
    );
  }

  getLayers() {
    return authApi.get("layers/get-layer-products", { headers: authHeader() });
  }

  
  AddLayers(formData) {
    return authApi
    .post(
      `/layers/add-layers-market`,
      formData,
      { headers: { ...authHeader(), "Content-Type": "multipart/form-data" } }
    )
  }
}

export default new Projectervice();
