// import {FolderUpload} from "../IPFS_Service/IPFS"

import { DocumentIcon, FolderIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { setipfs } from "../../Slices/data";
import { useSelector } from "react-redux";
import './stepher.css'

const UploadNft = ({ handleCurrent, rocket }) => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.storefiles.file);

  const handleFolderInput = (e) => {
    dispatch(setipfs(e.target.files));
    handleCurrent(1);
  };

  console.log(files, "upload");
  return (
    // <FolderUpload handleUrl={handleUrl}/>
    <div className="flex items-center py-8 md:px-14">
      <div className="mt-3 sm:mt-0 hidden sm:block md:hidden min-w-[150px] ml-5">
        <img src={rocket} width={250} height={250} alt="" />
      </div>
      <div className="">
        <h1 className="font-medium text-center md:text-left">Upload your NFT's here</h1>
        <p className="w-full px-6 sm:px-1 sm:ml-16 md:ml-0 sm:w-4/6 text-xs text-center md:text-left text-gray-400">
          Upload a whole folder of NFTs by uploading the folder and all the files will be uploaded automatically and folder would be created on IPFS or just upload one file on the IPFS and get your project going.
        </p>

      <div className="break-715 flex flex-col items-center px-6 md:px-0 md:flex-row mt-8 md:mt-12">
        <label
          htmlFor="folder-upload"
          className="ml-715 inline-flex cursor-pointer items-center rounded-3xl border  px-4 py-3 text-sm font-medium leading-4 border-gray-300"
        >
          {
            <FolderIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-orange-500"
              aria-hidden="true"
            />
          }
          Select your folder
        </label>

        <input id="folder-upload"
          onChange={handleFolderInput}
          type="file"
          directory=""
          webkitdirectory=""
          className="hidden"
        />

        <label
          htmlFor="file-upload"
          className="ml-715 mt-715 inline-flex ml-0 mt-2 mb-10 md:mt-0 sm:mb-0 md:ml-4 cursor-pointer items-center rounded-3xl border  px-4 py-3 text-sm font-medium leading-4 border-gray-300"
        >
          {
            <DocumentIcon
              className="-ml-0.5 mr-2 h-4 w-9 text-orange-500"
              aria-hidden="true"
            />
          }
          Select your file
        </label>
        <input
          id="file-upload"
          onChange={handleFolderInput}
          type="file"
          multiple
          className="hidden"
        />
        </div>
        {/* { loading && <ClipLoader color="#F05428" className='ml-4 text-orange-500' loading={loading}  size={45} />} */}
      </div>
    </div>
  );
};

export default UploadNft;
