import { useState } from "react";
import { Button, Drawer, Space } from 'antd';


const GenImageJsonModal = ({ img, jsonMetaData, index }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Drawer
            title={"#" + index}
            placement={"right"}
            closable={false}
            onClose={() => {setOpen(false)}}
            open={open}
            key={"right"}
            extra={
            <Space>
                <Button onClick={() => {setOpen(false)}}>X</Button>
            </Space>
            }
            >
                <div className="flex justify-center flex-col items-center">
                    <img src={img}
                            alt=""
                            height={"250px"}
                            width={"250px"}
                            className="pointer-events-none object-cover group-hover:opacity-75"
                        />
                </div>
                <div className="mt-4">
                    <h2 className="text-xl font-bold mb-0">The Apes Fight Club</h2>
                    <p className="text-gray-400 text-xs">Owned by you</p>
                </div>
                <div className="flex flex-wrap">
                    {jsonMetaData.attributes.map(attr => (
                        <div className="w-1/2">
                            <h2 className="font-bold mb-0">{attr["trait_type"]}</h2>
                            <p className="text-gray-400 text-xs">{attr["value"]}</p>
                        </div>
                    ))}
                </div>
            </Drawer>
            <div key={index} onClick={() => {setOpen(true)}} className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                    src={img}
                    alt=""
                    className="pointer-events-none object-cover group-hover:opacity-75"
                />
            </div>
        </>
      );
}


export default GenImageJsonModal;