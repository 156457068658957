import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StripeServices from "../Services/subs.services";

const initialState = {
  priceList: [],
  downloadable: false,
  subscriptionList: [],
};
 
export const getPriceList = createAsyncThunk(
  "users/getPriceList",
  async (data, thunkAPI) => {
    const response = await StripeServices.getAllPrices();
    console.log(response, "PricesTHUNK");
    return response;
  }
);

export const createSessionThunk = createAsyncThunk(
  "users/createSession",
  async (data, thunkAPI) => {
    // const response = await StripeServices.createSession(data)
    // console.log(response, "createSession");
    return StripeServices.createSession(data);
  }
);

export const createCustomSessionThunk = createAsyncThunk(
  "users/createCustomSession", 
  async (data, thunkAPI) => {
    console.log(data, "thunkData");
    return StripeServices.createCustomSession(data)
  }
);

export const getSubsListThunk = createAsyncThunk(
  "users/getSubsListThunk",
  async (data, thunkAPI) => {
    const response = await StripeServices.getSubsList(data);
    console.log(response?.data?.data, "subsList");
    return response.data;
  }
);

// For Admin
export const getAllSubsListThunk = createAsyncThunk(
  "users/getAllSubsListThunk",
  async (_, thunkAPI) => {
    const response = await StripeServices.getAllSubsList();
    console.log(response?.data?.data, "AllSubsList");
    return response.data?.data;
  }
);

export const upSub = createAsyncThunk(
  "users/upSub",
  async (data, thunkAPI) => {
    const response = await StripeServices.UpdateSubscription(data)
    console.log(response?.data?.data, "subsList");
    return response.data;
  }
);


export const Stripe = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setDownloadable: (state, action) => {
      state.downloadable = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPriceList.fulfilled, (state, { payload }) => {
      const priceListedSorted = payload.data.filter((data) => data);
      const sortedPrice = priceListedSorted.sort(
        (a, b) => a.priceItem.unit_amount - b.priceItem.unit_amount
      );

      state.priceList = sortedPrice;
    }).addCase(getSubsListThunk.fulfilled, (state, { payload }) => {
    
      state.subscriptionList = payload;
      console.log(payload,"UPGRADEEEEE")

    }).addCase(upSub.fulfilled, (state, { payload }) => {
    
      state.subscriptionList = {
        data:[payload]
      }
    }).addCase(createCustomSessionThunk.fulfilled, (state, action) => {
      // 
    })
  },
  // {
  //   [registerUser.fulfilled]: (state, action) => {

  //       state.isLoggedin=false
  //   },
  //   [registerUser.rejected]: (state, action) => {

  //       state.isLoggedin=false
  //   },
  //   [loginUser.fulfilled]: (state, {payload}) => {
  //       console.log(payload,"PAYLOAD")
  //       state.isLoggedin=true
  //       state.user=payload.user
  //   },
  //   [loginUser.rejected]: (state, {payload}) => {
  //     console.log(payload,"PAYLOAD")

  //       state.isLoggedin=false
  //       state.user=null
  //   },
  //   [logoutUser.fulfilled]: (state, {payload}) => {

  //       state.isLoggedin=false
  //       state.user=null
  //   },

  // },
});

export const priceList = (state) => state.stripe.priceList;
export const stripeSession = (state) => state.stripe.stripeSession;
export const subscriptionList = (state) => state.stripe.subscriptionList;
// Action creators are generated for each case reducer function
export const { logout, setDownloadable } = Stripe.actions;

export default Stripe.reducer;
