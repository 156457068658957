import { Tabs } from "antd";
import PaymentInfo from "../Components/billing_components/PaymentInfo";
import BillingHistory from "../Components/billing_components/BillingHistory";
import { useNavigate } from "react-router-dom";
import noproject from "../assets/noProject.png";
import Plan from "../Components/billing_components/Plan";
import PlanDummy from "../Components/billing_components/PlanDummy";
const Billing = () => {
  const navigate = useNavigate();
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className="container mx-auto w-5/6 p-12 mt-4 mb-12 h-5/6 bg-white ">
      <h1 className="text-3xl font-medium mb-8">Billing</h1>

      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        items={[
          {
            label: `Plan`,
            key: "1",
            children: <Plan />,
          },
          // {
          //   label: `Payment Info `,
          //   key: "2",
          //   children: <PaymentInfo />,
          // },
          // {
          //   label: `Billing History`,
          //   key: "3",
          //   children: <BillingHistory />,     
          // },
        ]}
      />
    </div>
    // <div className="flex justify-center items-center flex-col no-project-height">
    //   <div>
    //     <img src={noproject} alt="" width={300} height={300} />
    //   </div>
    //   <div className="mt-4 text-2xl">Coming Soon....</div>

    //   <button
    //     type="button"
    //     onClick={() => {
    //       navigate("/");
    //     }}
    //     className="inline-block px-8 py-2.5 mt-4 rounded-3xl bg-blue-600 text-white font-medium text-xs leading-tight   shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
    //   >
    //     {"Back to Dashboard"}
    //   </button>
    // </div>
  );
};

export default Billing;
