import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Brand from "../assets/Brand.png";
import { openCustomNotificationWithIcon } from '../utils/Notification';
// import AuthService from "../Services/auth.service";
import authService from '../Services/auth.service';
import { BsShieldCheck } from 'react-icons/bs'

const ForgetPassword = () => {
    const [isDisable, setisDisable] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const validateMessages = {
        required: "${label} is required!",
        types: {
          number: "${label} is not a valid number!",
        },
        number: {
          range: "${label} must be between ${min} and ${max}",
        },
      };

      const onSubmit = async (email) => {
        // dispatch()
        try {
          const response  = await authService.forgetpassword(email)
          const {status} = response
           if(status === 200){
            console.log("success")
            setisDisable(true)
            openCustomNotificationWithIcon("success", "Request sent to your email address");
          }
          
        } catch (error) {
          console.log(error, "eroreereree")
          openCustomNotificationWithIcon("error", error.response.data.message);
          setisDisable(false)
        }
        
       
        //   .then((x) => {
        //     console.log("LOgin", x);
        //     if (x.id) {
        //       openCustomNotificationWithIcon("success");
        //     }
        //   })
        //   .catch((x) => {
        //     const error = x.response.data.message;
        //     openCustomNotificationWithIcon("error", error);
        //   });
      };

      const PrevhandleButton = () => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
      } else {
          navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
      }
      
      }
       


  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="text-center">
            <img
              src={Brand}
              alt=""
              height={40}
              width={80}
              className="mx-auto h-12 w-auto"
            />
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Forgotten Password
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md ">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> <div className="-mx-5 mb-4">
              <Button
                className="ant-btn anticon rounded-lg"
                icon={<AiOutlineArrowLeft />}
                onClick={PrevhandleButton}
                // shape="circle"
              />
            </div>
            {isDisable == true ? (
              <div className='font-medium text-center flex flex-col text-lg justify-center p-4 items-center'><span className='text-indigo-600 text-4xl pb-3'><BsShieldCheck/></span> Password Request sent to your email address</div>
            ) : (
              <Form validateMessages={validateMessages} onFinish={onSubmit}>
              <div>
              <Form.Item
                  name="email"
                  size="small"
                  rules={[{ min: 6, type: "email" }]}
                  label={
                    <span className="text-gray-800 font-medium">Enter your email address</span>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <div className="rounded-lg">
                    <Input
                      size="large"
                  
                      placeholder="please provide your email"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Form.Item>
                <p className="text-red-500">{isDisable ? "Please check your email": ""}</p>
              </div>
              {/* <div>
                <Form.Item
                  name="password"
                  size="large"
                  rules={[{ required: true, min: 6 }]}
                  label={
                    <span className="text-gray-800 font-medium">Password</span>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <div className="rounded-lg">
                    <Input.Password
                      size="large"
                      placeholder="please provide a password"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Form.Item>
              </div>
             */}
              <div>
                <button
              
                  type="submit"
                  className={isDisable ? "flex w-full justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-gray-400 shadow-sm focus:ring-offset-2 mt-2 focus:outline-none focus:ring-2" : "flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-2"}
                >
                  Submit
                </button>
              </div>
            </Form>
            )}

            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
  )
}

export default ForgetPassword