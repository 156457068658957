import { Button, Form, Input, InputNumber } from "antd";
import React, { useState } from "react";
import { FolderViewOutlined, DeleteOutlined } from "@ant-design/icons";

const DynamicFIeld = ({ setInputValue, inputValue }) => {
  const [percentageLeft, setPercentageLeft] = useState(100);

  const calculateRemainingPercentage = () => {
    const totalPercentageUsed = inputValue.addresses.reduce(
      (total, addressObj) => total + Number(addressObj.percentage),
      0
    );
    setPercentageLeft(100 - totalPercentageUsed);
  };

  const handleAddAddress = () => {
    const minimumPercentage = 1; // Set the minimum percentage you want to allow for each new address

    const totalPercentageUsed = inputValue.addresses.reduce(
      (total, addressObj) => total + Number(addressObj.percentage),
      0
    );

    if (
      percentageLeft >= minimumPercentage &&
      totalPercentageUsed + minimumPercentage <= 100
    ) {
      setInputValue({
        ...inputValue,
        addresses: [...inputValue.addresses, { address: "", percentage: "" }],
      });
      calculateRemainingPercentage();
    }
  };

  //   const totalPercentage = calculateTotalPercentage();
  const getTotalPercentage = () => {
    const totalPercentage = inputValue.addresses.reduce(
      (total, addressObj) => total + parseInt(addressObj.percentage || 100),
      0
    );
    return totalPercentage;
  };
  const handleRemoveAddress = (index) => {
    const removedPercentage = Number(inputValue.addresses[index].percentage);
    const newAddresses = [...inputValue.addresses];
    newAddresses.splice(index, 1);
    setInputValue({ ...inputValue, addresses: newAddresses });
    setPercentageLeft((prevPercentage) => prevPercentage + removedPercentage);
  };

  const handleChangeAddress = (index, event) => {
    console.log(index, event, "eventnnnntntntnt");
    const { name, value } = event.target;
    const newAddresses = [...inputValue.addresses];
    newAddresses[index][name] = value;

    if (name === "percentage") {
      const newPercentage = Number(value);
      const totalPercentageUsed = newAddresses.reduce(
        (total, addressObj, currentIndex) =>
          currentIndex === index
            ? total + newPercentage
            : total + Number(addressObj.percentage),
        0
      );

      // Make sure the new percentage does not exceed the remaining percentage
      if (totalPercentageUsed <= 100) {
        setInputValue({ ...inputValue, addresses: newAddresses });
      }
    } else {
      setInputValue({ ...inputValue, addresses: newAddresses });
    }

    calculateRemainingPercentage();
  };

  return (
    <>
      <div className="top-0 left-3/4 absolute ">Remaining Percentage: {percentageLeft}%</div>
      {inputValue.addresses.map((addressObj, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            style={{ marginRight: "8px", width:"100%" }}
            size="small"
            name="address"
            rules={[{ required: true }]}
            label={<span className="text-gray-800 font-medium">Address</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="rounded-lg">
              <Input
                type="text"
                size="large"
                style={{ borderRadius: "5px" }}
                name="address"
                placeholder="Address"
                value={addressObj.address}
                onChange={(e) => handleChangeAddress(index, e)}
              />
            </div>
          </Form.Item>
          <Form.Item
            style={{ width: "10%", marginRight: "4px" }}
            size="small"
            name="percentage"
            rules={[{ required: true }]}
            label={
              <span className="text-gray-800 font-medium">%</span>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="rounded-lg">
              <InputNumber
                size="large"
                name="percentage"
                style={{ borderRadius: "5px" }}
                placeholder="Percentage"
                value={addressObj.percentage}
                onChange={(value) =>
                  handleChangeAddress(index, {
                    target: { name: "percentage", value },
                  })
                }
                min={0}
                max={percentageLeft + Number(addressObj.percentage)}
              />
            </div>
          </Form.Item>
          <Button
            className="ml-20 mt-3 "
            onClick={() => handleRemoveAddress(index)}
            icon={<DeleteOutlined />}
          />
        </div>
      ))}

      <Button
        onClick={handleAddAddress}
        disabled={percentageLeft === 0 || percentageLeft > 100}
        type={"primary"}
      >
        Add Address
      </Button>
    </>
  );
};

export default DynamicFIeld;
