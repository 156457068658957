import { create } from 'ipfs-http-client';
import {Buffer} from 'buffer';
// const ipfs = ipfsClient.create('https://ipfs.infura.io');

const ipfs = create({ host: 'ipfs.io', port: '443', protocol: 'https' });
// const projectId = '2DFiH4d22xQn6oE02uxa80hchAz'
// const projectSecret = '91e29be959d93bc74bdbd6224f4039c1'
// const projectIdAndSecret = `${projectId}:${projectSecret}`;
// const authorization = `Basic ${Buffer.from(projectIdAndSecret).toString('base64')}`
// const ipfs = create({
//   host: 'ipfs.infura.io',
//   port: 5001,
//   protocol: 'https',
//   headers: {
//     authorization,

//   },
// })

async function getImageFromIPFS(cid) {
  try {
    const file = await ipfs.cat(cid);
    // console.log(file, "file:getImageFromIPFS");
    // const file = await ipfs.get(cid);
    const url = URL.createObjectURL(new Blob([file.content]));
    return url;
    // const hash = '<your image hash here>';
    const data = await ipfs.cat(cid);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default getImageFromIPFS;













// const DEFAULT_IPFS_GATEWAY_URL = 'https://infura-ipfs.io/ipfs';

// const DEFAULT_IPFS_GATEWAY_URL = 'https://ipfs.io/ipfs';

// export const cleanUpHash = (hash) => {
//   // remove the leading 'ipfs://' if it exists
//   if (hash.startsWith('ipfs://')) {
//     return hash.substring(7);
//   }
//   return hash;
// };

// export const getImgUriFromTokenUriHash = async (tokenUriHash, ipfsGatewayUrl = DEFAULT_IPFS_GATEWAY_URL) => {
//     const ipfsHash = cleanUpHash(tokenUriHash);
//     const ipfsUrl = `${ipfsGatewayUrl}/${ipfsHash}`;
//     const res = await (await fetch(ipfsUrl)).json();
//   console.log(res, "getImgUriFromTokenUriHash");;
//   return res.image;
// };