import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import mergeImages from "merge-images";
import CollectionSideBar from "./CollectionGroup/CollectionSideBar";
import { Dropdown, Menu, Space, Upload, InputNumber } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  setUploadingFormAssets,
  setipfs,
  setFoldername,
  setRarityInAssets,
  setImagesData,
  setCheckbox,
  updateRarity,
  setRender,
  setSelectedLayer,
} from "../../Slices/data";
import { ClipLoader } from "react-spinners";
import { Slider } from "antd";
import { DeleteOutlined, FolderViewOutlined } from "@ant-design/icons";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import {
  countSelector,
  layersSelector,
  raritySelector,
  setCount,
  setGeneratedData,
  setGenerating,
  setLayers,
} from "../../Slices/GeneratorSlice";
import { startCreating } from "../../utils/Generatorutils/main";
import { setNumOfImageGenerated } from "../../Slices/GeneratorSlice";
import {
  getPriceList,
  getSubsListThunk,
  priceList,
  subscriptionList,
} from "../../Slices/Stripe";
import { user } from "../../Slices/Auth";
import _, { cloneDeep } from "lodash";
import { openCustomNotificationWithIcon } from "../../utils/Notification";

const CollectionDetailDummy = () => {
  const CollectionData = useSelector(
    (state) => state.storefiles.collectionForm
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [fileList, setFileList] = useState([]);
  const folderManiplutaion = useSelector(
    (state) => state.storefiles.uploadingFormAssets
  );
  const subsList = useSelector(subscriptionList);
  const products = useSelector(priceList);
  const { user: userdata } = useSelector(user);
  const props = {
    action: "",
    multiple: true,
    directory: true,
    listType: "file",
    name: "file",
  };
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };
  let [color, setColor] = useState("black");
  const [filterData, setfilterData] = useState();
  const [loading, setLoading] = useState(false);
  const countValue = useSelector(countSelector);
  const [allowedCount, setAllowedCount] = useState(100);
  const checkbox = useSelector((state) => state.storefiles.checkbox);
  const SelectedLayer = useSelector((state) => state.storefiles.selectedLayer);
  console.log(folderManiplutaion, "folderManipulationnn");
  console.log(checkbox, "checkbox");
  console.log(SelectedLayer, "selectedLayer");
  const render = useSelector((state) => state.storefiles.render);
  const [searchData, setSearchData] = useState(SelectedLayer);

  useEffect(() => {
    setSearchData(SelectedLayer);
    setRender(true);
  }, [SelectedLayer]);
  console.log("xxxxxxxeww", searchData);

  const onChange = (value, imageName, folderName) => {
    console.log("valueFromAlq", value);
    if (value > 100) {
      value = 100;
    }
    const tempFilterData = cloneDeep(SelectedLayer);

    for (let i = 0; i < tempFilterData.imagesArray.length; i++) {
      if (tempFilterData.imagesArray[i].name === imageName.name) {
        tempFilterData.imagesArray[i].rarity = value;
      }
    }

    dispatch(setSelectedLayer(tempFilterData));
    setSearchData(tempFilterData);
    const tempFolderManipulation = folderManiplutaion.map((x) => {
      return cloneDeep(x);
    });
    let updatedFolderManipulation = tempFolderManipulation.map((folder) => {
      if (folder.folderName === folderName) {
        console.log(folder.imagesArray, "imageearayyy");
        folder.imagesArray = folder.imagesArray.map((image) => {
          if (image.name === imageName.name) {
            console.log(image.rarity, "imggeee");
            image.rarity = value;
          }
          return image;
        });
      }
      return folder;
    });
    dispatch(setImagesData(updatedFolderManipulation));
  };

  console.log(filterData, "FIlter data");

  const updateNumOfImageGen = (value) => {
    dispatch(setNumOfImageGenerated(value));
  };

  const searchHandle = (e) => {
    console.log(e.target.value, "eventttttt");
    const searchText = e.target.value;
    const data = SelectedLayer.imagesArray.filter((image) => {
      return image.name.toLowerCase().includes(searchText.toLowerCase());
    });
    console.log(
      { folderName: SelectedLayer.folderName, imagesArray: data },
      "dataaaaaaaa"
    );
    // setfilterData(filteredData);
    setSearchData({ folderName: SelectedLayer.folderName, imagesArray: data });
  };

  // FUNCTION OF RADOMISE PICTURE
  function get_random(list) {
    let tempArray = [];
    for (let i = 0; i < list.length; i++) {
      tempArray.push(list[Math.floor(Math.random() * list.length)]);
    }
    setImage(tempArray);
  }
  console.log(image, "IMAGEEEEE");

  //COMPONSING IMAGE FOR PREVIEWS PART
  const composeImages = async () => {
    function getAllCombinations(folders, maxSize = 8) {
      setLoading(true);
      let result = [[]];
      let temp = [];
      for (let i = 0; i < folders.length; i++) {
        temp = result.slice();
        result = [];
        for (let j = 0; j < temp.length; j++) {
          for (let k = 0; k < folders[i].imagesArray.length; k++) {
            if (result.length < maxSize) {
              result.push(
                temp[j].concat(
                  URL.createObjectURL(folders[i].imagesArray[k].imageFile)
                )
              );
            } else {
              break;
            }
          }
        }
      }
      return result;
    }

    const possibleCombination = getAllCombinations(folderManiplutaion);
    console.log(possibleCombination, "possible combination");

    //   let result = allPossibleCases(bk);

    let tempArray = [];

    if (typeof possibleCombination[0] === "string") {
      const imageComponse = await mergeImages(possibleCombination);
      tempArray.push(imageComponse);
      setImage(tempArray);
      setLoading(false);
      return;
    } else {
      for (let i = 0; i < possibleCombination.length; i++) {
        const imageComponse = await mergeImages(possibleCombination[i]);
        console.log(imageComponse, "imageeeComponseee");
        tempArray.push(imageComponse);
      }
      setImage(tempArray);
      dispatch(setRender(false));
      setLoading(false);
    }
  };

  // EXTRACTING THE DATA FROM FILES OF IMAGES

  useEffect(() => {
    dispatch(getSubsListThunk(userdata.stripeCustomerId))
      .unwrap()
      .then((subsList) => {
        console.log(subsList, "subsList");
        if (subsList.data.length <= 0) {
          dispatch(getPriceList())
            .unwrap()
            .then((products) => {
              const total = Number(
                products.data[0].description.split(":")[
                  products.data[0].description.split(":").length - 1
                ]
              );
              setAllowedCount(total);
            });
        } else {
          subsList.data.forEach((sub, x) => {
            console.log(sub, "PRODUCT ID");
            dispatch(getPriceList())
              .unwrap()
              .then((productsList) => {
                console.log(productsList, "productsList");
                // productsList.data[]
              });
            const pId = sub.items.data[0].plan.product;
            // products.filter((pr)=> pr.id === pId)
            console.log(products, "PIPI");
          });
        }
      });
  }, []);

  useEffect(() => {
    if (composeImages) {
      console.log("composeeeeimageee");
      composeImages()
        .then((x) => console.log(x, "Theeeeeen"))
        .catch((x) => console.log(x, "Catchhhhh"));
    }
  }, [render]);

  // HANDLE DRAG AND DROP
  const handleDragDrop = (result) => {
    if (!result.destination) return;
    const items = Array.from(folderManiplutaion);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items, "handledraggg");
    dispatch(setImagesData(items));
    dispatch(setRender(true));
  };

  const deleteHandle = () => {
    if (checkbox) {
      const updatedData = folderManiplutaion?.filter(
        (item) => item.folderName !== SelectedLayer.folderName
      );
      dispatch(setCheckbox(false));
      dispatch(setImagesData(updatedData));
      dispatch(setRender(true));
    }
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
    dispatch(setUploadingFormAssets(newFileList));
    dispatch(setRender(true));
    setLoading(true);
  };

  const handleSelection = () => {
    dispatch(setCheckbox(false));
  };

  //FILTER SEARCH BAR

  // console.log(originalPath, "rrrrrrrrr");

  const handleGenerate = () => {
    // dispatch(setLayers(folderManiplutaion))
    console.log(folderManiplutaion, "MANIpulation");
    let data = folderManiplutaion.map((x) => {
      return _.cloneDeep(x);
    });

    let items = [];

    for (let i = 0; i < data.length; i++) {
      let tempArray = [];
      let imagesCollection = data[i];
      for (let j = 0; j < imagesCollection.imagesArray.length; j++) {
        tempArray.push({
          name: imagesCollection.imagesArray[j].name,
          file: imagesCollection.imagesArray[j].imageFile,
          rarity: imagesCollection.imagesArray[j].rarity,
        });
      }
      items.push(tempArray.length);
    }

    const layerConfigurations = [
      {
        growEditionSizeTo: 20,
        items: items,
        layersOrder: data,
      },
    ];
    let maxCountNum = layerConfigurations[0]?.items?.reduce((a, b) => a * b);
    let maxCount = Intl?.NumberFormat("en", {
      notation: "compact",
    })?.format(layerConfigurations[0]?.items?.reduce((a, b) => a * b));
    console.log({ maxCount });
    if (countValue > maxCountNum) {
      openCustomNotificationWithIcon(
        "error",
        `Possible Collection Size is ${maxCount} please add more Layers or reduce Collection Size`
      );
    } else {
      dispatch(setGenerating(true));
      startCreating(layerConfigurations, countValue, updateNumOfImageGen).then(
        (x) => {
          console.log(x, "imagesssgenr");
          dispatch(setGeneratedData(x));
          dispatch(setGenerating(false));
        }
      );
      navigate("/generated-result");
    }

    console.log(layerConfigurations, "RESUls");
    // state.layers = layerConfigurations;
  };

  const handleDelete = (name, folderName) => {
    const tempFolderManipulation = folderManiplutaion.map((x) => {
      return cloneDeep(x);
    });
    const updatedLayer = SelectedLayer.imagesArray.filter((folder) => {
      return folder.name !== name;
    });
    setSearchData({ folderName: folderName, imagesArray: updatedLayer });
    dispatch(
      setSelectedLayer({ folderName: folderName, imagesArray: updatedLayer })
    );

    const updateFolderManipulation = tempFolderManipulation.map((folder) => {
      if (folder.folderName == SelectedLayer.folderName) {
        folder.imagesArray = folder.imagesArray.filter((x) => x.name !== name);
      }
      return folder;
    });
    dispatch(setImagesData(updateFolderManipulation));
    console.log(updatedLayer, "updatedddfolderMani");
  };
  useEffect(() => {
    if (checkbox === false) {
      dispatch(setRender(true));
    }
  }, [checkbox]);
  console.log(CollectionData, "CollectionData");
  return (
    <div className="px-4">
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full md:w-[40%] lg:w-[30%] md:h-screen bg-white p-2 overflow-x-hidden overflow-y-auto pb-20 relative">
          <div className="">
            <div className="flex justify-between item-center mx-4">
              <h1 className="text-xl font-bold">Groups</h1>
              {checkbox === false ? (
                <Upload {...props} onChange={handleChange} fileList={[]}>
                  <span className="text-md text-blue-600 underline font-medium cursor-pointer">
                    + Add a group
                  </span>
                </Upload>
              ) : (
                <div>
                  <span
                    onClick={deleteHandle}
                    className="text-md text-blue-600 underline font-medium cursor-pointer"
                  >
                    Delete Selection
                  </span>
                  <span
                    className="font-medium ml-4 text-xl cursor-pointer "
                    onClick={handleSelection}
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            <DragDropContext onDragEnd={handleDragDrop}>
              <Droppable droppableId="folderManiplutaion">
                {(provided) => (
                  <ul
                    className="mb-32"
                    {...provided?.droppableProps}
                    ref={provided.innerRef}
                  >
                    {folderManiplutaion?.map((item, index) => {
                      console.log(item, "itemmmmmmmmmmmmm", provided);
                      return (
                        <Draggable
                          key={item?.folderName}
                          draggableId={item?.folderName}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              {...provided?.draggableProps}
                              {...provided?.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <CollectionSideBar
                                key={item?.folderName}
                                name={item?.folderName}
                                folder={item}
                                setfilterData={setfilterData}
                                filterData={filterData}
                                handleUpload={handleChange}
                                provided={provided}
                              />
                            </li>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided?.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="flex flex-col  items-center w-full justify-center">
            <button
              className=" w-3/4 h-12 py-2 px-4 rounded-full bg-transparent hover:bg-[#b7b8c6] text-gray-700  hover:text-white border border-gray-700 hover:border-transparent"
              onClick={() => get_random(image)}
              disabled={folderManiplutaion?.length < 1}
            >
              Regenerate
            </button>

            <button
              onClick={handleGenerate}
              class="bg-[#3243D4] mt-4 w-3/4 h-12 hover:bg-blue-700 text-white  py-2 px-4 rounded-full"
            >
              Generate Collections
            </button>
          </div>
        </div>
        <div className="w-full md:w-[60%] lg:w-[70%] ml-3 md:h-screen p-2 overflow-x-hidden overflow-y-auto pb-20">
          <div className="flex flex-col lg:flex-row lg:items-center">
            <h1 className="text-xl  text-black font-bold">
              {checkbox === true
                ? SelectedLayer?.folderName
                : CollectionData?.name}
            </h1>
            <div className="flex flex-row ml-10">
              {checkbox === true ? (
                <div>
                  <h1>items: {SelectedLayer?.imagesArray?.length}</h1>
                </div>
              ) : (
                <div className="flex items-center">
                  <div>
                    <h1>
                      <nobr>items: {image?.length} </nobr>
                    </h1>
                  </div>
                  <div className="ml-5 lg:ml-10">
                    <h1>
                      <nobr>
                        Possible Unique NFTs :{" "}
                        {folderManiplutaion.length > 0 ? (
                          Intl?.NumberFormat("en", {
                            notation: "compact",
                          })?.format(
                            folderManiplutaion
                              ?.map((i) => i?.imagesArray?.length)
                              ?.reduce((a, b) => a * b)
                          )
                        ) : (
                          <span>0</span>
                        )}
                      </nobr>
                    </h1>
                  </div>
                </div>
              )}
            </div>
            <div>
              {!checkbox && (
                <div className="flex flex-row items-center mb-2 lg:ml-10">
                  <div className="flex flex-row items-center">
                    <h1 className="mb-0 text-center">
                      <nobr>Collection Size </nobr>
                    </h1>
                    <input
                      onChange={(e) => {
                        let value = parseInt(e.target.value);
                        dispatch(setCount(value));
                      }}
                      value={countValue}
                      type="number"
                      class=" appearance-none border rounded w-20 py-2 ml-4 px-3 text-gray-700 leading-tight focus:outline-none "
                      id="count"
                      name="count"
                      placeholder="0"
                    />
                  </div>
                  <div className="flex flex-row items-center ml-6 sm:ml-10">
                    <button
                      id="multiLevelDropdownButton"
                      data-dropdown-toggle="dropdown"
                      class="text-black text-sm px-4 py-[7px] text-center inline-flex items-center bg-white"
                      type="button"
                    >
                      <nobr>First to Last</nobr>{" "}
                      <svg
                        class="ml-2 w-4 h-4"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="w-full flex">
            <div className="w-full flex flex-col lg:flex-row">
              <h1 className="text-2xl text-black font-bold">
                {checkbox === true
                  ? SelectedLayer?.folderName
                  : CollectionData?.name}
              </h1>
              <div className="flex justify-between items-baseline mb-4 ">
                {checkbox === true ? (
                  <div>
                    <h1>items: {SelectedLayer?.imagesArray?.length}</h1>
                  </div>
                ) : (
                  <>
                    <div>
                      <h1>items: {image?.length} </h1>
                    </div>
                    <div>
                      <h1>
                        Possible Unique NFTs :{" "}
                        {folderManiplutaion?.length > 0 ? (
                          Intl?.NumberFormat("en", {
                            notation: "compact",
                          })?.format(
                            folderManiplutaion
                              ?.map((i) => i?.imagesArray?.length)
                              ?.reduce((a, b) => a * b)
                          )
                        ) : (
                          <span>0</span>
                        )}
                      </h1>
                    </div>

                    <div className=" flex flex-row justify-center align-middle items-center">
                      <h1>Collection Size </h1>
                      <input
                        onChange={(e) => {
                          let value = parseInt(e.target.value);
                          dispatch(setCount(value));
                        }}
                        value={countValue}
                        type="number"
                        class=" appearance-none border rounded w-20 py-2 ml-4 px-3 text-gray-700 leading-tight focus:outline-none "
                        id="count"
                        name="count"
                        placeholder="0"
                      />
                    </div>
                  </>
                )}
                <div className="mr-4">
                  {checkbox === true ? (
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-black-500 mr-4 p-2"
                      placeholder="Search"
                      onChange={searchHandle}
                    />
                  ) : null}
                  <button
                    id="multiLevelDropdownButton"
                    data-dropdown-toggle="dropdown"
                    class="text-black text-sm px-4 py-2.5 text-center inline-flex items-center bg-white"
                    type="button"
                  >
                    <nobr>First to Last</nobr>{" "}
                    <svg
                      class="ml-2 w-4 h-4"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <div
                    id="dropdown"
                    class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
                  >
                    <ul
                      class="py-1 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="multiLevelDropdownButton"
                    >
                      <li>
                        <a
                          href="#"
                          class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Dashboard
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className={`${loading === false ? "grid grid-cols-1 sm:grid-flow-col-2 md:grid-cols-3 lg:grid-flow-col-4 gap-4" : "w-full h-2/4 flex items-center justify-center"}`}> */}
          <div
            className={`${
              loading === false
                ? "flex flex-wrap"
                : "w-full h-2/4 flex items-center justify-center"
            }`}
          >
            {loading === true && folderManiplutaion?.length > 0 ? (
              <div className="w-full  flex justify-center items-center ">
                {" "}
                <ClipLoader
                  cssOverride={override}
                  color={color}
                  loading={loading}
                  size={80}
                />{" "}
              </div>
            ) : checkbox === false && folderManiplutaion?.length > 0 ? (
              image?.map((img, i) => {
                console.log(img, "imagesss");
                return (
                  <div
                    className="bg-white p-4 m-2 w-full sm:w-[30%] md:w-[44%] lg:w-[30%] xl:w-[20%]"
                    key={i}
                  >
                    <img src={img} alt="" width="500px" height="500px" />
                  </div>
                );
              })
            ) : (
              searchData?.imagesArray?.map((layer, i) => {
                console.log(layer, "layerrr");
                return (
                  <>
                    <div className="bg-white p-4 m-2 w-full sm:w-[30%] md:w-[44%] lg:w-[30%] xl:w-[20%]">
                      {" "}
                      {/* <h5>{layer?.name?.split("#")[1]}</h5> */}
                      <div className="h-3/4 ">
                        <img
                          src={URL.createObjectURL(layer?.imageFile)}
                          alt="imageNameArray"
                          className=""
                        />
                        <div className="flex justify-between items-center">
                          <p className=" mt-2 text-black font-semibold">
                            {layer?.name?.split(".")[0]}
                          </p>
                          <p>
                            <Dropdown
                              overlay={
                                <Menu
                                  items={[
                                    {
                                      label: "Delete",
                                      key: "1",
                                      icon: (
                                        <DeleteOutlined
                                          onClick={() =>
                                            handleDelete(
                                              layer?.name,
                                              SelectedLayer.folderName
                                            )
                                          }
                                        />
                                      ),
                                    },
                                    // {
                                    //   label: "View more",
                                    //   key: "2",
                                    //   icon: <FolderViewOutlined />,
                                    // },
                                  ]}
                                />
                              }
                            >
                              <span>
                                <Space>
                                  <EllipsisVerticalIcon
                                    className="-ml-0.5 mr-2 h-4 w-4  text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Space>
                              </span>
                            </Dropdown>
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center mt-4">
                        <div className="w-4/5">
                          <Slider
                            min={0}
                            max={100}
                            onChange={(e) =>
                              onChange(e, layer, SelectedLayer?.folderName)
                            }
                            value={layer?.rarity}
                          />
                        </div>
                        <div className="mx-2 flex items-center">
                          <input
                            type="number"
                            className="hideArrow p-1 text-xs"
                            onChange={(e) =>
                              onChange(
                                Number(e.target.value),
                                layer,
                                SelectedLayer?.folderName
                              )
                            }
                            value={layer?.rarity}
                            min={1}
                            max={100}
                          />
                          <p className="mb-0 ml-1">%</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionDetailDummy;
