import React, { useEffect, useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { user } from "../../Slices/Auth";
import { getProjects } from "../../Slices/data";
import { setImageInFile, } from "../../Slices/GeneratorSlice";
import {
  getPriceList,
  getSubsListThunk,
  priceList,
  subscriptionList,
} from "../../Slices/Stripe";
import { openCustomNotificationWithIcon } from "../../utils/Notification";
import UpgradeStripeModal from "../billing_components/UpgradeStripeModal";
import { ClipLoader } from "react-spinners";
import { useSearchParams } from "react-router-dom";

const free = [];
function Summary({ handleCheckPackage, rocket }) {
const { user: userdata } = useSelector(user);
  const files = useSelector((state) => state.storefiles.file);
  const folder = useSelector((state) => state.storefiles.folderName);
  const generatedData = useSelector((state) => state.generator.generatedData);
  const { data } = useSelector(subscriptionList);
  const allProjects = useSelector((state) => state.storefiles.projects);
  const products = useSelector(priceList);
  const [currentProduct, setcurrentProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  let [color, setColor] = useState("black");
  const [availableData, setAvailableData] = useState({
    filesCount: 0,
    folderSize: 0,
  });
  const [queryFetch] = useSearchParams()
  const dataquery = queryFetch.get("y")
  console.log(dataquery, "queryyyyyfetchh");

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
    borderSize: "2px"
  };

  console.log(files, folder, "files and folder");
  const dispatch = useDispatch();

  console.log(allProjects, "FILESS");

  // function dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], filename, { type: mime });
  // }


  useEffect(() => {
    dispatch(getPriceList());
    dispatch(getProjects());
    dispatch(getSubsListThunk(userdata.stripeCustomerId));
  }, []);

  useEffect(() => {
    setLoading(true)
    console.log(loading, "xxxxxxxxxx");
    // debugger
    let usage;
    let previousUsedStorag = 0;
    let limit = 0;
    if (products?.length > 0 && data?.length > 0) {
      let prodId = data[0]?.plan?.product;

      let filtered = products.filter((x) => x.productId === prodId);

      limit = parseInt(filtered[0]?.description?.match(/(\d+)/)[0]) * 1024;

      setcurrentProduct(filtered[0]);
    } else {
      limit =
        parseInt(
          products
            .filter((x) => x.name === "Beginner")[0]
            ?.description?.match(/(\d+)/)[0]
        ) * 1024;
      setcurrentProduct(products.filter((x) => x.name === "Beginner")[0]);
    }

    if (files.length > 0) {
      console.log(files, "Alqama");
      let folderSize = 0;
      for (let i = 0; i < files?.length; i++) {
        if(queryFetch == "manual"){

          folderSize = folderSize + files.item(i).size;
        }else {
          folderSize = folderSize + files[i].size;
        }
      }

      let folderSizeInMbs = (folderSize / (1024 * 1024)).toFixed(2);
      let filesCount = files.length;
      allProjects.forEach((x) => {
        previousUsedStorag = previousUsedStorag + parseFloat(x.size);
      });
      usage = parseFloat(previousUsedStorag) + parseFloat(folderSizeInMbs);

      console.log(usage, "FOLDERSIZ", limit);

      // setLoading(false)
      setAvailableData({
        ...availableData,
        folderSize: folderSizeInMbs,
        filesCount,
        usage,
        limit,
      });
    }

    // if (generatedData?.images?.length > 0) {
    //   setLoading(true)
    //   const data = [];
    //   for (let i = 0; i < generatedData.images.length; i++) {
    //     data[i] = dataURLtoFile(generatedData.images[i], i + ".png");
    //   }
    //   dispatch(setImageInFile(data));
    //   let folderSize = 0;
    //   for (let i = 0; i < data.length; i++) {
    //     folderSize = folderSize + data[i].size;
    //   }
    //   console.log("Folderrrrrrrrrrr", folderSize);
    //   let folderSizeInMbs = (folderSize / (1024 * 1024)).toFixed(2);
    //   let filesCount = files.length;
    //   allProjects.forEach((x) => {
    //     previousUsedStorag = previousUsedStorag + parseFloat(x.size);
    //   });
    //   usage = parseFloat(previousUsedStorag) + parseFloat(folderSizeInMbs);

    //   console.log(previousUsedStorag, "prevesiossss");
    //   console.log(usage, "usssaaaggge", limit, "limit");

    //   // setLoading(false)
    //   setAvailableData({
    //     ...availableData,
    //     folderSize: folderSizeInMbs,
    //     filesCount,
    //     usage,
    //     limit,
    //   });
    // }
  }, [products, files, data]);


  useEffect(() => {
    console.log("object");
    console.log(availableData);
    if(availableData){

      setLoading(false)
    }
  }, [availableData])

  console.log(currentProduct, "CURRENT");
  const handleStep = () => {
    if (false) {
      openCustomNotificationWithIcon("error", "Folder name is required");
    } else {
      handleCheckPackage(3);
    }
  };

  return (
    <div className="border-2 border-amber-500 rounded-2xl p-4 md:px-2 mb-8 py-8 md:ml-1 mar-pad-left-804">
      {loading === true ? (
      <div className="flex justify-center items-center">
      
          <ClipLoader
            cssOverride={override}
            color={color}
            loading={loading}
            size={80}
          />
        </div>
      ) : (
        <div className="grid grid-cols-2 justify-center">
          <div className="flex flex-col w-4/2 justify-between">
            <h1>Data Summary</h1>
            <p className="mt-3 flex items-center">
              <span className="text-xl mr-1">
                <BsCheck2 />
              </span>{" "}
              {availableData?.filesCount} Pinned Files
            </p>

            <p className="mt-3 flex items-center">
              <span className="text-xl mr-1">
                <BsCheck2 />
              </span>{" "}
              {availableData?.folderSize} Mbs of Data
            </p>
            <p className="mt-3 flex items-center">
              <span className="text-xl mr-1">
                <BsCheck2 />
              </span>{" "}
              {availableData?.usage?.toFixed(2)} MBs of Storage Used
            </p>
          </div>

          <div className="flex flex-col w-4/2 ">
            {currentProduct?.description?.split(",").map((str, i) => (
              <p className="mt-3 flex items-center" key={i}>
                <span className="text-xl mr-1">
                  <BsCheck2 />
                </span>{" "}
                {str}
              </p>
            ))}
          </div>
        </div>
      )}

      <div>
        <div className="text-right">
          {availableData.usage > availableData.limit ? (
            <>
              <span className="text-red-500 text-xs mr-4">
                Data is exceeding the Allowed amount !!
              </span>
              <UpgradeStripeModal subsList={data} pricesL={products} />
            </>
          ) : (
            <button
              type="button"
              onClick={handleStep}
              className="inline-flex items-center rounded-3xl border mt-4 bg-blue-700 text-white  px-4 py-2 text-sm font-medium leading-4 border-gray-300 "
            >
              Upload
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Summary;
