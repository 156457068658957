import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import noproject from "../assets/noProject.png";

const DesignNftByDesigner = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center flex-col no-project-height">

      <div>
        <img src={noproject} alt="" width={300} height={300} />
      </div>
      <div className="mt-4">This Features Comming Soon!</div>

      <button
        type="button"
        onClick={() => {
          navigate("/dashboard");
        }}
        className="inline-block px-8 py-2.5 mt-4 rounded-3xl bg-blue-600 text-white font-medium text-xs leading-tight   shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      >
        {"Back to Dashboard"}
      </button>
    </div>
  );
};

export default DesignNftByDesigner;
