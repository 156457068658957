import axios from "axios";
import { openCustomNotificationWithIcon } from "../utils/Notification";
import { useNavigate } from "react-router-dom";
// import persistor from "./../Store"

const BASEURL = `${process.env.REACT_APP_BAKREE_API_URL}/api/`;
const authApi = axios.create({
  baseURL: BASEURL,
  // withCredentials: true,
});

// authApi.defaults.headers.common["Content-Type"] = "application/json"

authApi.interceptors.response.use(
  (response) => {
    // handle success
    return response;
    // console.log(response, "response from axios");
  },
  (error) => {
    // const navigate = useNavigate()
    // handle error
    if (error.response.status == 403) {
      // network error
      openCustomNotificationWithIcon(
        "error",
        "Session Expired! Please Login Again"
      );
      window.localStorage.removeItem("persist:root");
      window.localStorage.removeItem("user");
      window.location.href = "/";

      console.log(error, "network error");

      // localStorage.removeItem("user")
    } else {
      console.log(error.response.data, "errororrororor");
      console.log(error.response.status);
      console.log(error.response.headers);
    }

    return Promise.reject(error);
  }
);

export default authApi;
