import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  disconnectMetamask,
  disconnectWallet,
  loadWeb3,
} from "../Components/SmartContract/connectWeb3";
import projectService from "../Services/project.service";
import { openCustomNotificationWithIcon } from "../utils/Notification";
const initialState = {
  file: [],
  folderName: "",
  projectCreated: false,
  projects: [],
  loadingPopup: false,
  loading: false,
  percentage: 0,
  collectionForm: {},
  uploadingFormAssets: [],
  checkbox: false,
  selectedLayer: [],
  render: false,
  address: "Connect Wallet",
};

export const createProjectThunk = createAsyncThunk(
  "users/createProjectThunk",
  async (data, thunkAPI) => {
    console.log(data, "createprojectPayload");
    const response = await projectService.createProject(data);
    console.log(response, "USERRRRasdasasd");
    return response.status;
  }
);

export const imageUploadToS3 = createAsyncThunk(
  "users/imageUploadToS3",
  async (data, thunkAPI) => {
    console.log(data, "createprojectPayload");
    const response = await projectService.uploadImageToS3Bucket(data);
    console.log(response, "USERRRRasdasasd");
    return response.data;
  }
);
export const adds3UrlAddressForMetaData = createAsyncThunk(
  "users/imageUploadToS3",
  async (data, thunkAPI) => {
    console.log(data, "adds3UrlAddressForMetaData");
    const response = await projectService.adds3AddMeta(data);
    console.log(response, "USERRRRasdasasd");
    return response.data;
  }
);

export const getProjects = createAsyncThunk(
  "users/getproject",
  async (data, { getState }) => {
    const { auth } = getState();
    console.log(auth);
    const id = auth.user.id;
    const response = await projectService.getAllProject();
    // const newData = response.data.filter((x) => x.userId === id);
    console.log(response, "get all project", auth);
    return response.data;
  }
);

export const getAllProjects = createAsyncThunk(
  "users/getallproject",
  async (id, { getState }) => {
    const response = await projectService.getAllProjectByUserId(id);
    console.log(response, "get all project by user id");
    return response.data;
  }
);

export const connectWeb3 = createAsyncThunk(
  "users/connectWeb3",
  async (selectedNetwork, { getState }) => {
    console.log(selectedNetwork, "selectNetworkkkdispatch");
    try {
      const response = await loadWeb3(selectedNetwork);
      console.log(response, "WEB#");
      return response;
    } catch (error) {
      console.log(error, "erorororwebad");
      const errorMessage = "Please check or install Metamask";
      openCustomNotificationWithIcon("warning", errorMessage);
    }
  }
);

export const disconnectWeb3 = createAsyncThunk(
  "users/disconnectWeb3",
  async (data, { getState }) => {
    console.log("disconnectWeb3");
    const disconnect = await disconnectMetamask();
    console.log(disconnect, "disconnect");
    return disconnect;
  }
);

const fetchFile = async (url, imageName) => {
  try {
    console.log(url, "urllllffetchhhh");
    const response = await fetch(url);
    console.log("functionruninggg");
    const blob = await response.blob();
    console.log(url, "urlllllllllllllllll");
    console.log(response, "Ressssssssssssssssssss");
    return {
      imageFile: new File([blob], url.split("/").pop(), { type: blob.type }),
      rarity: 1,
      name: imageName,
    };
  } catch (error) {
    console.log(error);
  }
};

const fetchFolderFiles = async (directoryName, folderName, imageNames) => {
  console.log("fetchimggggggggggggggg");
  const filePromises = imageNames.map((imageName) => {
    const url = `https://bakeree-app.s3.amazonaws.com/${directoryName}/${folderName}/${imageName}`;
    return fetchFile(url, imageName);
  });
  return Promise.all(filePromises);
};

export const DefaultLayer = createAsyncThunk(
  "app/setDefaultLayer",
  async (imagesArray, { getState }) => {
    console.log(imagesArray, "imagesArray");
    const directoryname = imagesArray.map((x) => x.split("/")[3])[0];
    const folders = {};

    for (let i = 0; i < imagesArray.length; i++) {
      const imagePath = imagesArray[i];
      const [folderName, imageName] = imagePath.split("/").slice(-2);
      if (!folders[folderName]) {
        folders[folderName] = [imageName];
      } else {
        folders[folderName].push(imageName);
      }
    }
    const folderFilePromises = [];
    for (const folderName in folders) {
      const imageNames = folders[folderName];
      folderFilePromises.push(
        fetchFolderFiles(directoryname, folderName, imageNames).then(
          (files) => {
            folders[folderName] = files;
          }
        )
      );
    }

    console.log("runingggggggggggggg");
    await Promise.all(folderFilePromises);
    const keys = Object.keys(folders);
    const newFolder = keys.map((key) => ({
      folderName: key,
      imagesArray: folders[key],
    }));
    console.log(newFolder, "foldersssssssssssssss");
    return newFolder;
  }
);

export const dataIPFS = createSlice({
  name: "storefiles",
  initialState,
  reducers: {
    setipfs: (state, action) => {
      console.log(action.payload, "payloaadddddd from data");
      state.file = action.payload;
      // console.log(action.payload);
    },
    setFoldername: (state, action) => {
      state.folderName = action.payload;
    },
    setRefresh: (state) => {
      state.projectCreated = false;
    },
    setLoadingPopup: (state, action) => {
      console.log(action.payload, "Paylooooooooooad");
      state.loadingPopup = action.payload;
    },
    setPercentage: (state, action) => {
      console.log(action.payload, "percentage");
      state.percentage = action.payload;
    },
    setCollectionForm: (state, { payload }) => {
      console.log("setCollectionForm", payload);
      state.collectionForm = payload;
    },
    setUploadingFormAssets: (state, { payload }) => {
      console.log("setCollectionForm", payload);
      let folderName = "";
      const arr = [];

      for (let i = 0; i < payload?.length; i++) {
        let fName = "";
        if (
          folderName !==
          payload[i]?.originFileObj?.webkitRelativePath?.split("/")[1]
        ) {
          fName = folderName =
            payload[i]?.originFileObj?.webkitRelativePath?.split("/")[1];
          arr.push({
            folderName: folderName,
            imagesArray: payload
              ?.map((data) => {
                if (
                  data.originFileObj.webkitRelativePath.split("/")[1] === fName
                ) {
                  return {
                    name: data.originFileObj.webkitRelativePath.split("/")[2],
                    imageFile: data.originFileObj,
                    rarity: 1,
                  };
                }
                return null;
              })
              .filter((x) => x !== null),
            // imageName: payload
            //   .map((data) => {
            //     if (
            //       data.originFileObj.webkitRelativePath.split("/")[1] === fName
            //     ) {
            //       return data.originFileObj.webkitRelativePath.split("/")[2];
            //     }
            //     return null;
            //   })
            //   .filter((x) => x !== null),
            // imageurl: payload
            //   .map((data, i) => {
            //     if (
            //       data.originFileObj.webkitRelativePath.split("/")[1] === fName
            //     ) {
            //       return data.originFileObj;
            //     }
            //     return null;
            //   })
            //   .filter((x) => x !== null),
          });
        }
      }
      state.uploadingFormAssets = arr;
      console.log(arr, "arrrrrrrr");
    },
    setRarityInAssets: (state, { payload }) => {
      console.log("setCollectionForm", payload);
      state.uploadingFormAssets = payload;
    },
    setLoading: (state, action) => {
      console.log("payloaddddd loading", action.payload);
      state.loading = action.payload;
    },
    setImagesData: (state, { payload }) => {
      state.uploadingFormAssets = payload;
    },
    setCheckbox: (state, { payload }) => {
      state.checkbox = payload;
    },
    setSelectedLayer: (state, { payload }) => {
      state.selectedLayer = payload;
    },
    setRender: (state, { payload }) => {
      state.render = payload;
    },
    setDisconnectWeb3: (state, { payload }) => {
      // state.address = "Connect Wallet";
    },
    setDefaultLayer: (state, { payload }) => {
      const imagesArray = payload;
      const directoryname = imagesArray.map((x) => x.split("/")[3])[0];
      const folders = {};

      const fetchFile = (url) => {
        return fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            return new File([blob], url.split("/").pop(), { type: blob.type });
          });
      };

      const fetchFolderFiles = async (folderName, imageNames) => {
        const filePromises = imageNames.map((imageName) => {
          const url = `https://bakeree-app.s3.amazonaws.com/${directoryname}/${folderName}/${imageName}`;
          return fetchFile(url);
        });

        return Promise.all(filePromises);
      };

      for (let i = 0; i < imagesArray.length; i++) {
        const imagePath = imagesArray[i];
        const [folderName, imageName] = imagePath.split("/").slice(-2);
        if (!folders[folderName]) {
          folders[folderName] = [imageName];
        } else {
          folders[folderName].push(imageName);
        }
      }

      const folderFilePromises = [];
      for (const folderName in folders) {
        const imageNames = folders[folderName];
        folderFilePromises.push(
          fetchFolderFiles(folderName, imageNames).then((files) => {
            folders[folderName] = files;
          })
        );
      }

      return Promise.all(folderFilePromises).then(() => {
        state.uploadingFormAssets = folders;
        console.log(folders, "folderssssssssss");
      });
    },

    // setDefaultLayer: (state, { payload }) => {
    //   console.log(payload, "payloaddddddd default layers");
    //   const imagesArray = payload;
    //   const directoryname = imagesArray.map((x) => x.split("/")[3])[0];
    //   // const folderName = imagesArray.map(x => x.split("/")[1])
    //   console.log(directoryname, "directoryyys");
    //   // console.log(folderName, "directoryyys");

    //   const folders = {};

    //   for (let i = 0; i < imagesArray.length; i++) {
    //     const imagePath = imagesArray[i];
    //     const [folderName, imageName] = imagePath.split("/").slice(-2);
    //     if (!folders[folderName]) {
    //       folders[folderName] = [imageName];
    //     } else {
    //       folders[folderName].push(imageName);
    //     }
    //   }
    //   for (const folderName in folders) {
    //     folders[folderName] = folders[folderName].map((imageName) => {
    //       // console.log(folderName, "foldername");
    //       // console.log(folders[folderName], "foldersssss");
    //       const urls = `https://bakeree-app.s3.amazonaws.com/${directoryname}/${folderName}/${imageName}`;

    //       fetch(urls)
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //           const file = new File([blob], imageName, {
    //             type: blob.type,
    //           });
    //           console.log(file);
    //         });
    //     });
    //   }

    //   console.log(folders, "folderrrrrrr");
    //   state.uploadingFormAssets = folders;
    //   // return folders;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProjectThunk.fulfilled, (state, { payload }) => {
        console.log(payload, "projectthunk");
        if (payload === 200) {
          state.loadingPopup = false;
          console.log(state.loadingPopup, "Payloaaad");
          state.projectCreated = true;
        }
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.projects = payload;
      })
      .addCase(connectWeb3.fulfilled, (state, { payload }) => {
        console.log(payload, "PAYLOD");
        state.address = payload;
      })
      .addCase(disconnectWeb3.fulfilled, (state, { payload }) => {
        console.log(payload, "disconnected payloaddddd");
        state.address = payload;
      })
      .addCase(DefaultLayer.fulfilled, (state, { payload }) => {
        console.log(payload, "payloaddddddddd");
        state.uploadingFormAssets = payload;
      });
  },
});

// export const storefile = (state) => state.storefiles

// console.log("storefile", storefile);

// Action creators are generated for each case reducer function
export const {
  setipfs,
  setFoldername,
  setRefresh,
  setLoadingPopup,
  setPercentage,
  setCollectionForm,
  setUploadingFormAssets,
  setRarityInAssets,
  setLoading,
  setImagesData,
  setCheckbox,
  setSelectedLayer,
  setRender,
  setDisconnectWeb3,
  setDefaultLayer,
} = dataIPFS.actions;

export default dataIPFS.reducer;
