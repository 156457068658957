import { Steps } from "antd";
import React, { useState } from "react";
import CollectionDetail from "../Components/CreatingCollection/CollectionDetail";
import CollectionForm from "../Components/CreatingCollection/CollectionForm";
import UploadingForm from "../Components/CreatingCollection/UploadingForm";
import UploadingProgress from "../Components/CreatingCollection/UploadingProgress";

const CreateCollection = () => {
  let [current, setCurrent] = useState(0);
  const [progressVal, setprogressVal] = useState(0);


  const onChange = (value) => {
    console.log("onChange:", current);
    setCurrent(value);
  };

  const handleChange = () => {
    onChange(current + 1);
  };

  const setprogressval = (value) => {
    console.log(value, "PROGRESS");
    setprogressVal(value);
  };

  const step = [
    {
      step: 0,
      component: (
        <CollectionForm
          onChange={onChange}
          current={current}
          handleChange={handleChange}
        />
      ),
    },
    {
      step: 1,
      component: (
        <UploadingForm
          onChange={onChange}
          current={current}
          handleBtn={handleChange}
        />
      ),
    },
    {
      step: 2,
      component: <UploadingProgress handleBtn={handleChange} />,
    }
    
  ];
  const Step = Steps.Step;

  const stepStyle = {
    marginBottom: 60,
    boxShadow: "0px -1px 0 0 #e8e8e8 inset",
  };
  const disabled = !current ? true : false || current === 2 ? true : false;
  return (
    <div className="md:h-4/6 ">
      <div className="flex flex-col  justify-center items-center   ">
        <div className="  md:w-6/12 gap-4  mt-20 bg-white px-10 py-4 mb-20">
          <Steps
            type="navigation"
            size="small"
            current={current}
            onChange={onChange}
            style={stepStyle}
            className={`font-bold`}
          >
            <Step
              title="Creating Collection"
              disabled={current == 2 ? true : false}
            />
            <Step
              title={current == 2 ? "Uploading..." : "Upload Files"}
              disabled={disabled}
            />
            {/* {<Step title="Uploading" disabled={!current ? true : false} /> } */}
          </Steps>
          {step.map(({ step, component }) =>
            step === current
              ? component
              : step === current
              ? component
              : step === current
              ? component
              : null
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCollection;
