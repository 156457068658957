import { Form, Input, Steps } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCollectionForm } from "../../Slices/data";

const CollectionForm = ({current, onChange, handleChange}) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    description: "",
    dimension1 : "",
    dimension2: "",
    count: ""
  })
  const dispatch = useDispatch()
  
  



  const onChangeHandler = async (e) => {
    setInputValue({...inputValue,[e.target.name] : e.target.value})
    // console.log(inputValue)
    
  }
  
  const onsubmit = () => {
    console.log(inputValue,"inputtt value")
    dispatch(setCollectionForm(inputValue))
    handleChange()
  }
  const validateMessages = {
    required: "${label} is required!",
    types: {
      number: "${label} is not a valid number!",
      email: "${label} is not a valid email!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <>
   
      <Form
        className="space-y-6"
        validateMessages={validateMessages}
        onFinish={onsubmit}
      >
        <div>
          <Form.Item
            size="small"
            name="name"
            rules={[{ required: true }]}
            label={<span className="text-gray-800 font-medium">Name</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="rounded-lg">
              <Input
                name="name"
                size="large"
                placeholder="E.g John Doe"
                style={{ borderRadius: "5px" }}
                onChange={onChangeHandler}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="description"
            size="small"
            label={
              <span className="text-gray-800 font-medium">Description</span>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="rounded-lg">
              <Input.TextArea
                name="description"
                placeholder="Enter Image Description Here..."
                size="large"
                style={{ borderRadius: "5px" }}
                rows="4"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={onChangeHandler}
              />
            </div>
          </Form.Item>
        </div>
        <div className="flex">
          <div className="mr-4">
            <Form.Item
              name="dimension1"
              size="small"
              label={
                <span className="text-gray-800 font-medium">Dimension</span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="w-80"
            >
              <Input
                size="large"
                name="dimension1"
                placeholder="Enter Image Width"
                type="number"
                min={0}
                style={{ borderRadius: "5px" }}
                onChange={onChangeHandler}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="dimension2"
            size="small"
            label={<span className="text-gray-800 font-medium"></span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="w-1/2"
          >
            <Input
            name="dimension2"
              size="large"
              type="number"
              placeholder="Enter Image Height"
              style={{ borderRadius: "5px" }}
              min={0}
              onChange={onChangeHandler}
            />
          </Form.Item>
        </div>
        {/* <div>
          <Form.Item
            name="count"
            size="small"
            rules={[{ required: true }]}
            label={<span className="text-gray-800 font-medium">Count</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="rounded-lg">
              <Input
                size="large"
                name="count"
                type="number"
                placeholder="please provide your count"
                style={{ borderRadius: "5px" }}
                onChange={onChangeHandler}
              />
            </div>
          </Form.Item>
        </div> */}

        {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div> */}

        <div className="flex justify-end">
          <button
            className={`flex justify-end rounded-2xl border mt-8 border-transparent bg-gray-400 mr-2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`flex justify-end rounded-2xl border mt-8 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
          >
            Create Collection
            <span>
              {/* <ClipLoader
                      cssOverride={override}
                      color={color}
                      loading={loading}
                      size={20}
                    /> */}
            </span>
          </button>
        </div>
      </Form>
    </>
  );
};

export default CollectionForm;
