import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {Buffer} from 'buffer';
import { create } from 'ipfs-http-client';
import { createProjectThunk, setLoadingPopup, setLoadingSteps, setRefresh,setPercentage, setipfs, setFoldername, setLoading, imageUploadToS3, adds3UrlAddressForMetaData } from '../../Slices/data';
import { json, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { ClimbingBoxLoader } from 'react-spinners';
import Progress from './Progress';
// import { createProjectThunk } from '../../Slices/data';
import { generatedDataSelector, imageFileSelector, setGeneratedData, setGenerating } from '../../Slices/GeneratorSlice';
import { openCustomNotificationWithIcon } from '../../utils/Notification';
import { Modal } from 'antd';
const all = require('it-all')
const projectId = '2DFiH4d22xQn6oE02uxa80hchAz'
const projectSecret = '91e29be959d93bc74bdbd6224f4039c1'
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const authorization = `Basic ${Buffer.from(projectIdAndSecret).toString('base64')}`
const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  apiPath: '/api/v0',
  headers: {
    authorization,

  },
})

let interval = undefined;


const Upload = ({ setprogressval, handleAddFolderName, rocket }) => {

  const navigate = useNavigate();
  const [jsonFile, setJsonFile] = useState([]);
  const [running, setRunning] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [files, setFiles] = useState([]);
  // const [file, setFile] = useState('');
  // const [isBtn, setIsBtn] = useState(false)
  const [folderUrl, setFolderUrl] = useState('')
  // const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false);
  const [url, setUrl] = useState("");
  const [isJsonUpload, setIsJsonUpload] = useState(false);
  const [isAgainModal, setIsAgainModal] = useState(false);
  const [isAgainUpload, setIsAgainUpload] = useState(false);
  const [isAgainRun, setIsAgainRun] = useState(false);
  const [s3BucketImg, sets3BucketImg] = useState([]);
  // const [percentage, setpercentage] = useState(0)


  const files = useSelector(state => state.storefiles.file)
  const imageFileIpfs = useSelector(imageFileSelector);
  const projectStatus = useSelector(state => state.storefiles.projectCreated)
  // const collectionData = useSelector(state => state.storefiles.collectionForm)
  const data = useSelector(generatedDataSelector);
  const user = useSelector(state => state.auth.user)
  const folder = useSelector(state => state.storefiles.folderName);
  const dispatch= useDispatch()
  console.log(files, folder,"ghghghggh");
  console.log(user);
  const loadingpop = useSelector(state => state.storefiles.loadingPopup)
  const [queryFetch] = useSearchParams()
  const dataquery = queryFetch.get("y")
  console.log(dataquery, "queryyyyyfetchh");
  // const percentage = useSelector(state => state.storefiles.percentage)
  


  console.log(imageFileIpfs, "generatedDataaaaa")
  console.log(data.metadata, "jsonMetaData111");

  useEffect(() => {
    setRunning(true);
  }, [])

  useEffect(() => {
    if (running) {
      interval = setInterval(() => {
        setProgress((prev) => prev + 10);
      }, 500);
    } else {
      clearInterval(interval);
    }
  }, [progress]);

  useEffect(() => {
    if (progress >= 100) {
      setRunning(false);
      clearInterval(interval);
      setprogressval(progress)

    }

  }, []);
  
  
  
  
  useEffect(() => {
    if(projectStatus && dataquery==='manual'){
      navigate("/projects",{ replace: true })
    }else if(projectStatus && dataquery==='ipfs' && isJsonUpload){
      navigate("/projects",{ replace: true });
    }
  }, [projectStatus, isJsonUpload ])
  
  let progressSizes = {}
  const calcSizeInbytes=(files)=>{
    console.log(files, "itemsssssssssssssss")
    let folderSize = 0;
    for (let i = 0; i < files.length; i++) {
      if(dataquery == "manual"){

        folderSize = folderSize + files.item(i).size;
      }else{
        folderSize = folderSize + files[i].size;  
      }
    }
    return folderSize
  }
  const calcSizeInbyteFromNft=(files)=>{
    let folderSize = 0;
    for (let i = 0; i < files.length; i++) {
      folderSize = folderSize + files[i].size;
    }
    return folderSize
  }
  
  let progress_func = function(len,path) {
    if(files.length >0){
    console.log(len,path,"UPLOAD Function Ptogress")
    progressSizes[path] = len
    const percentage = Math.round(((Object.values(progressSizes).reduce((a, b) => a + b)) / calcSizeInbytes(files)) * 100)
    console.log(percentage, "progresssssssssss")
    // setpercentage(percentage)
    dispatch(setPercentage(percentage))
  }else{
    console.log(len,path,"UPLOAD Function Ptogressssss")
    progressSizes[path] = len
    const percentage = Math.round(((Object.values(progressSizes).reduce((a, b) => a + b)) / calcSizeInbyteFromNft(imageFileIpfs)) * 100)
    console.log(percentage, "progresssssssssss")
    // setpercentage(percentage)
    dispatch(setPercentage(percentage))
  }
    
    
  };
    
  
  const uploadFiles = async (files) => {
    console.log(files, "thisisfile")
    try {
      let fileObjectsArray = Array.from(files)?.map((file, i) => {
        return {
          path: file.name,
          content: file
        }
      })
      
      console.log("fileObjectsArray", fileObjectsArray)
      
      dispatch(setLoadingPopup(true))
      const results = await all(
        ipfs.addAll(fileObjectsArray, { wrapWithDirectory: true ,progress:progress_func})
        )
        
        console.log("Results after uploading ", results)
        
        // dispatch(setLoadingSteps(false))
        return results
    } catch (e) {
      console.log("Error while uploading files", e);
      throw new Error(e);
    }

  }
  
  const returnFilesUrl = async (files) => {

    try {
      dispatch(setLoading(true))
      
      console.log("BEFORE CALL")
      const results = await uploadFiles(files)
      const length = results?.length
      console.log("AFTERCALL", results)
      const FilesHash = results[length - 1]?.cid?._baseCache?.get('z')
      console.log("FilesHash", FilesHash)
      const FilesUrl = 'https://ipfs.io/ipfs/' + FilesHash
      setUrl(FilesHash)
      setFolderUrl(FilesUrl)
      
      setUploaded(true)
      return FilesUrl
    } catch (e) {
      console.log("error while returning files Url", e);
      // return e
      throw new Error(e);
    }

  }

  const calculateSize=(files)=>{
    
    let folderSize = 0;
    for (let i = 0; i < files.length; i++) {

      if(dataquery == "manual"){

        folderSize = folderSize + files.item(i).size;
      }else{
        folderSize = folderSize + files[i].size;  
      }
    }
    
    console.log(folderSize, "FOLDERSIZ");
    let folderSizeInMbs = (folderSize / (1024 * 1024)).toFixed(2);
    let filesCount = files.length;
    
    return {
      size:folderSizeInMbs,
      count:filesCount
    }
  }

  // const calculateSizeForCollection = (files) => {
    
  //   let folderSize = 0;
  //   for (let i = 0; i < files.length; i++) {
  //     folderSize = folderSize + files[i].size;
  //   }
    
  //   let folderSizeInMbs = (folderSize / (1024 * 1024)).toFixed(2);
  //   let filesCount = files.length;
  //   console.log(folderSize, "FOLDERSIZEINCOLL", folderSizeInMbs);
    
  //   return {
  //     size:folderSizeInMbs,
  //     count:filesCount
  //   }
        
  //     }
      
    //   function dataURLtoFile(dataurl, filename) {
 
    //     var arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), 
    //         n = bstr.length, 
    //         u8arr = new Uint8Array(n);
            
    //     while(n--){
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
        
    //     return new File([u8arr], filename, {type:mime});
    // }

    const uploadJsonMetaData = async (cid) => {
      let layerData = [];
      for(let i = 0; i < data?.metadata?.length; i++){
        layerData[i] = new File(
                        [JSON.stringify({...data?.metadata[i], image: `ipfs.io/ipfs/${cid}/${i+1}.png`, compiler: 'Bakeree Art Engine'})], 
                        (i+1)+".json", {type:"text/json"}
                      );
      }
      console.log(layerData, "layerDataaaaaa");
      const sizeAndCount = calculateSize(layerData);
      console.log(sizeAndCount, "sizeAndCount");
      // setJsonFile(layerData);
      try {
        let fileObjectsArray = Array.from(layerData)?.map((file, i) => {
          return {
            path: file.name,
            content: file
          }
        })
        
        const results = await all(ipfs.addAll(fileObjectsArray, { wrapWithDirectory: true }))
          
        // console.log("Results after uploading ", results)
        const length = results?.length;
        const FilesHash = results[length - 1]?.cid?._baseCache?.get('z');
        const FilesUrl = 'https://ipfs.io/ipfs/' + FilesHash;
        setUrl(FilesHash)
        setFolderUrl(FilesUrl);
        setUploaded(true)
        return { FilesHash, size: sizeAndCount.size, count: sizeAndCount.count };
      } catch (e) {
        console.log("Error while uploading files", e);
      }
    }

    const convertToFormData = (images) => {
      const formData = new FormData();
      images.forEach(x => {
        formData.append("images", x);
      });
      return images;
    }

    const ipfsUpload = async () => {
      console.log(files, "files->Upload");
      // const tenImageForMint = files.slice(0, 10);
      // console.log(tenImageForMint, "tenImageForMint");
      if (dataquery === "manual" && files?.length > 0 && loadingpop === false) {
        // console.log("Runinggggggggg if");

        let dataToUpl = Array.from(files)?.map((file, i) => {
            return file;
        });

        let s3Img;
        if(dataToUpl.length > 6){
          s3Img = [...dataToUpl.slice(0, 6)];
        }
        else{
          s3Img = [...dataToUpl];
        }
        console.log(s3Img, "manually: s3Img");
        dispatch(imageUploadToS3({ images: convertToFormData(s3Img) }))
        .unwrap().then(s3Res => {
          if(s3Res.success){
            returnFilesUrl(files).then(x => {
              console.log(x,"URLLLLLLLLLLLLL")
              if(x){
                console.log("s3BhenchoImage", s3BucketImg);
                // dispatch(imageUploadToS3(convertToFormData(s3BucketImg))).unwrap().then(x => { console.log(x.data, "imageUploadToS3"); });
                console.log("xxxxxxxxxxxxxxxxxxxxxxxx", files,folder,x);

                dispatch(adds3UrlAddressForMetaData({ cid_metadata: x.split("ipfs/")[1], cid_image: x.split("ipfs/")[1], s3_url: s3Res.folderName }))
                  .unwrap().then(x => { 
                    console.log("man->res: adds3UrlAddressForMetaData", x);
                  }).catch(e => {
                    console.log("man->error: adds3UrlAddressForMetaData", e);
                  })

                dispatch(createProjectThunk({
                  name:folder,
                  CID:x.split("ipfs/")[1],
                  userId:user.id,
                  type: files.length > 1 ? "Folder" : "Image",
                  size:calculateSize(files).size,
                  count:calculateSize(files).count,
                  images: s3BucketImg,
                }))
              }
              
              
              
              dispatch(setLoading(false))
              dispatch(setipfs([]))
              dispatch(setFoldername(""))
            }).catch(x => {
              console.log(x, "CATCH")
              setIsAgainModal(true);
            });
          }
          else {
            openCustomNotificationWithIcon("error", "Error On Uploading Image to s3");
            setIsAgainModal(true);
          }
        })


  } else if (dataquery === "ipfs" && files?.length > 0 && loadingpop === false) {
      let s3Img;
      if(files.length > 6){
        s3Img = [...files.slice(0, 6)];
      }else{
        s3Img = [...files];
      }

      // console.log("s3BhenchoImage", s3Img);
      dispatch(imageUploadToS3({ images: convertToFormData(s3Img) }))
        .unwrap().then(s3Res => { 
          console.log(s3Res, "response: imageUploadToS3"); 
          if(s3Res.success){
            returnFilesUrl(files).then(x => {
            console.log(x,"URLLLLLL")
            if(x){
              dispatch(createProjectThunk({
                name:folder + " (Images)",
                CID:x.split("ipfs/")[1],
                userId:user.id,
                type: files.length > 1 ? "Folder" : "Image",
                size:calculateSize(files).size,
                count:calculateSize(files).count,
              }));
              uploadJsonMetaData(x.split("ipfs/")[1]).then(data => {
                dispatch(adds3UrlAddressForMetaData({ cid_metadata: data.FilesHash, cid_image: x.split("ipfs/")[1], s3_url: s3Res.folderName }))
                  .unwrap().then(x => { 
                    console.log("res: adds3UrlAddressForMetaData", x);
                  }).catch(e => {
                    console.log("error: adds3UrlAddressForMetaData", e);
                  })
                // console.log(data, "dataAfterJson");
                dispatch(createProjectThunk({
                  name:folder + " (Metadata)",
                  CID: data.FilesHash,
                  userId: user.id,
                  type: files.length > 1 ? "Folder" : "Image",
                  size: data.size,
                  count: data.count,
                }));

                setIsJsonUpload(true);
                dispatch(setLoading(false))
                dispatch(setipfs([]))
                dispatch(setFoldername(""));
              })
            }
          }).catch(x => {
            console.log(x, "CATCH");
            setIsAgainModal(true);
          });
          }
          else {
            openCustomNotificationWithIcon("error", "Error On Uploading Images to s3", 2);
            setIsAgainModal(true);
          }
        }).catch(e => {
          console.log(e, "error: imageUploadToS3");
        });

}
    }
    
    useEffect(() => {
      ipfsUpload();
    //     if(imageFileIpfs?.length > 0){
    //       // const data = [];
    //       // for(let i=0; i<generatedData.images.length; i++){
    //       //   data[i] = dataURLtoFile(generatedData.images[i], i+".png")
    //       // } 
    //       // console.log(data, "daaaattttaaaa");
    //       returnFilesUrl(imageFileIpfs).then(x => {
    //         console.log(x,"URLLLLLL")
    //         if(x){
    //           dispatch(createProjectThunk({
    //             name: collectionData.name,
    //             CID:x.split("ipfs/")[1],
    //             userId:user.id,
    //             type: "Folder",
    //             ...calculateSizeForCollection(imageFileIpfs),
    //           }));
    //         }
    //         // dispatch(setLoading(false));
    //         dispatch(setGeneratedData([]))
    //         dispatch(setGenerating(false))
    //       }).catch(x => {
    //         console.log(x, "CATCH")
    //       })
    // }



  }, [files, folder, isAgainRun])

  const failUploadCancel = () => {
    dispatch(setLoading(false));
    dispatch(setipfs([]));
    dispatch(setFoldername(""));
    dispatch(setLoadingPopup(false));
    setIsAgainModal(false);
    if(dataquery==="manual"){
      navigate("/dashboard")
      // navigate("/createproject/?y=manual&f=ret");
      // handleCurrent(1);
    }else{
      navigate("/nft-generator/?y=ipfs");
    }
  }

  const uploadAgainAfterFailed = () => {
    setIsAgainModal(false);
    console.log("ipfsUpload again run");
    setIsAgainRun(true);
    // ipfsUpload();
    // dispatch(setPercentage(0));
    // setIsAgainUpload(true)
    // handleCheckPackage(3);
    // handleAddFolderName(2)
    console.log("ipfsUpload 2");
  }

  return (

    // <div className="pb-20 sm:pb-6">
    <div className="flex  py-8 md:px-14 justify-center md:justify-start mb-14 md:mb-0">
      <div className="mt-3 sm:mt-0 hidden sm:block md:hidden min-w-[150px] sm:w-[40%] ml-16">
        <img src={rocket} width={300} height={300} alt="" />
      </div>
      <div className='sm:pl-16 md:pl-4 flex'>
        <div className="py-3 sm:py-0 flex flex-col items-center sm:items-start">
          <h1 className="font-medium text-center sm:text-left">Details</h1>
          <p className="w-4/6 text-xs text-gray-400 mb-2 sm:mb-8 text-center sm:text-left">
          Please wait while we quickly upload your NFTs on IPFS cloud and prepare your delight in our Bakeree.
          </p>
        </div>

        <Progress/>
      </div>
      <Modal title="Basic Modal" open={isAgainModal} onOk={()=>{}} onCancel={failUploadCancel}>
        <p><strong>Infura Error:</strong> Failed to upload Data</p>
        <div className='flex w-full justify-end mt-6'>
          <button onClick={failUploadCancel} className='bg-gray-400 text-white rounded mr-4 p-2 hover:bg-gray-500'>Please Try Again</button>

        </div>
      </Modal>

      {/* <div className='flex justify-center items-center'>
        <div style={{ width: 200, height: 200 }}>
         {percentage===100?
         <div>
         <ClimbingBoxLoader color="#F05428" />
         <h1>Creating Directory</h1>
         </div>
         : <CircularProgressbar value={percentage} text={`${percentage}%`}
            strokeWidth={2}
            styles={{
              // Customize the root svg element
              root: {},
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                stroke: `#F05428`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'square',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: '#d6d6d6',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the text
              text: {
                // Text color
                fill: '#F05428',
                // Text size
                fontSize: '16px',
              },
              // Customize background - only used when the `background` prop is true

            }}
          />}
        </div>
      </div> */}
    </div>

  );
};

export default Upload;
