
import { useEffect } from "react";

const MyOverLayLoading = ({ children, closeModal, newOverlay = false }) => {
    const styles = {
      background: 'rgba(0, 0, 0, 0.7)',
      position: "fixed",
      zIndex: 999,
      width: '100%',
      height: '100vh',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
    }
    // console.log(children, "children");
    useEffect(() => {
        document.body.style.overflow = "hidden"; 
        return () => {
          document.body.style.overflow = "auto"; 
        }
    }, []);   

    if(newOverlay){
      return(
        <div style={styles}>
          {/* <div className="bg-white w-full md:w-6/12 min-h-96 rounded flex justify-center flex-col p-8 relative"> */}
            {children}
          {/* </div> */}
        </div>
      );
    }

    return (
      <div style={styles}>
        <div className="bg-white w-full md:w-6/12 min-h-96 rounded flex justify-center flex-col p-8 relative">
          <div onClick={closeModal} className="absolute top-4 right-8 hover:text-red-500 cursor-pointer text-lg">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center', color: 'black', }}>
            {children}
          </div>
        </div>
      </div>
    );
}

export default MyOverLayLoading;