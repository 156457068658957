/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { UserIcon } from "@heroicons/react/20/solid";
import { Link, useLocation } from "react-router-dom";
import brand from "../../assets/Brand.png";
import brandNew from "../../assets/BrandNew.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, user } from "../../Slices/Auth";
import { Avatar, Dropdown, Space, Menu as DMenu } from "antd";
import formatRelativeTime from "../../utils/convertRelativeTime";
import {
  getAllNotification,
  updateNotification,
} from "../../Slices/Notification";
const navigation = [
  // { name: "Dashboard", href: "/dashboard", current: true },
  // { name: 'Collections', href: '/projects', current: false },
  // { name: "Payments", href: "/Billing", current: false },
  //
  // { name: 'Collection', href: '/nft-generator', current: false },
  // { name: 'Products', href: '#', current: false },
  // { name: 'Setting', href: '/profile', current: false },
];

const navLinks = [
  "dashboard",
  "projects",
  "Billing",
  "profile",
  "product",
  "nft-generator",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const [dispText, setDispText] = useState("Dashboard");

  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [read, setRead] = useState(false);
  const notifications = useSelector(
    (state) => state?.notifications?.notification
  );
  console.log("uuuuuuuuuu", user);

  const handleCLick = () => {
    dispatch(logout());
  };
  const unreadNotification = notifications
    .filter((x) => x.isRead === false);
  console.log(unreadNotification, "notificationsssdev");

  const UpdateNotificationFunction = () => {
    if (unreadNotification.length > 0) {
      dispatch(updateNotification(user?.id))
    }
  };

  const dropDownNotificationsMenu = (
    <DMenu>
      {unreadNotification.length > 0 ? (
        unreadNotification?.map((x, i) => (
          <DMenu.Item key={i}>
            {
              <div className="flex justify-between p-1">
                <p>{x?.content}</p>
                <p className="text-gray-500 text-xs mb-2 ml-2  ">
                  {formatRelativeTime(x?.createdAt)}
                </p>
              </div>
            }
          </DMenu.Item>
        ))
      ) : (
        <div className="p-2 text-gray-600">No new notification yet!</div>
      )}
    </DMenu>
  );
  // console.log(dropDownNotifications, "navnotification");

  useEffect(() => {
    setDispText(location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <Disclosure as="nav" className="top-0 z-50 bg-white fixed w-full">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl max-w-full px-8 sm:px-6">
            <div className="relative flex h-16 items-center justify-between">
              {/* <div className="flex grow items-center justify-between sm:items-stretch sm:justify-between"> */}
              <div className="flex grow items-center justify-between">
                {/* <div className=" items-center sm:hidden"> */}
                {/* Mobile menu button*/}
                {/* <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button> */}
                {/* </div> */}
                <div className="flex flex-shrink-0 items-center">
                  <div className="block h-8 w-auto lg:hidden">
                    <img
                      className=""
                      src={brandNew}
                      alt="Your Company"
                      width="25px"
                      height="30px"
                    />
                  </div>
                  <Link to={"/dashboard"}>
                    <div className="hidden h-8 w-auto lg:block">
                      <img
                        className=""
                        src={brandNew}
                        alt="Your Company"
                        width="25px"
                        height="30px"
                      />
                    </div>
                  </Link>
                  <div className="ml-10 text-base text-2xl font-medium ">
                    {dispText.charAt(0).toUpperCase() +
                      dispText.slice(1).split("/")[0]}
                  </div>
                </div>

                {/* <div className="hidden sm:ml-6 sm:block pt-2">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          "px-3 py-2 rounded-md text-sm text-gray-800 font-medium hover:text-gray-800"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                    { user.username === "admin1" && <Link
                        to={"/allusers"}
                        className={classNames(
                          "px-3 py-2 rounded-md text-sm text-gray-800 font-medium hover:text-gray-800"
                        )}
                      >
                        Analytics
                      </Link> }
                  </div>
                </div> */}
                <div className=" flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button
                    type="button"
                    className="rounded-full bg-fuchsia-100 p-1 text-orange-400 border-2	border-purple-200"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* <button
                    type="button"
                    onClick={handleCLick}

                    className="inline-flex items-center rounded-3xl border ml-4  px-4 py-2 text-sm  bg-orange-500 text-white leading-4 border-gray-300"
                  >
                    Sign out
                  </button> */}
                  {/* <div className="ml-4 justify-center items-center hidden h-8 w-auto lg:flex">
                          {" "}
                          {user.username}{" "}
                          <ChevronDownIcon
                            className="block h-4 w-4 ml-1 font-bold"
                            aria-hidden="true"
                          />
                        </div> */}
                  {/* <span
                    onClick={handleCLick}
                    className={classNames(
                      // active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm font-500 text-gray-700 cursor-pointer defaultcolor"
                    )}
                  >
                    Sign out
                  </span> */}
                  <div
                    className="mr-4 relative"
                    onClick={() => UpdateNotificationFunction()}
                  >
                    <Dropdown
                      overlay={dropDownNotificationsMenu}
                      trigger={["click"]}
                      placement="bottomLeft"
                    >
                      {/* <a onClick={(e) => e.preventDefault()}> */}
                      <Space>
                        <svg
                          width="40"
                          height="42"
                          viewBox="0 0 40 42"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            opacity="0.1"
                            cx="20"
                            cy="22"
                            r="20"
                            fill="#F79027"
                          />
                          {unreadNotification.length > 0 ? (
                            <circle
                              cx="33"
                              cy="7"
                              r="6"
                              fill="#FF8100"
                              stroke="white"
                              stroke-width="2"
                            />
                          ) : null}
                          <path
                            d="M18.3999 27.8667C18.3999 28.7504 19.1162 29.4667 19.9999 29.4667C20.8836 29.4667 21.5999 28.7504 21.5999 27.8667"
                            stroke="#F79027"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.9278 16.6665C17.9953 16.6665 16.3718 18.1196 16.1584 20.0403L15.7333 23.8665L14.4111 26.0702C14.2612 26.3199 14.3564 26.6407 14.6255 26.7521C15.5393 27.1305 17.6224 27.8665 20 27.8665C22.3775 27.8665 24.4606 27.1305 25.3744 26.7521C25.6435 26.6407 25.7387 26.3199 25.5888 26.0702L24.2666 23.8665L23.6795 19.9033C23.404 18.0437 21.8078 16.6665 19.9278 16.6665V16.6665Z"
                            stroke="#F79027"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 16.6665C20.5891 16.6665 21.0667 16.189 21.0667 15.5999C21.0667 15.0108 20.5891 14.5332 20 14.5332C19.4109 14.5332 18.9333 15.0108 18.9333 15.5999C18.9333 16.189 19.4109 16.6665 20 16.6665Z"
                            stroke="#F79027"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            d="M15.7332 23.8667C15.7332 23.8667 17.5998 24.6667 19.9998 24.6667C22.3998 24.6667 24.2665 23.8667 24.2665 23.8667"
                            stroke="#F79027"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>
                      </Space>
                      {/* </a> */}
                    </Dropdown>
                  </div>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3 ">
                    <div className="flex justify-center items-center">
                      <Menu.Button className="flex rounded-full  text-sm justify-center items-center">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                          {user.avatar === "" || user.avatar === undefined ? (
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          ) : (
                            <img src={user.avatar} alt="" />
                          )}
                        </span>
                        <div className="ml-4 flex justify-center items-center hidden h-8 w-auto lg:flex">
                          {" "}
                          {user.username}{" "}
                          <ChevronDownIcon
                            className="block h-4 w-4 ml-1 font-bold"
                            aria-hidden="true"
                          />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 defaultcolor"
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item> */}
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/profile"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 defaultcolor"
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleCLick}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer defaultcolor"
                              )}
                            >
                              Sign out
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
