import { PlusIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProjects, setLoadingPopup, setRefresh } from "../Slices/data";
import noproject from "../assets/noProject.png";
import { getSubsListThunk } from "../Slices/Stripe";
import { user } from "../Slices/Auth";
import Dropdown from "../Components/Dropdown";
import { Button, Tooltip } from "antd";
import ContractBtn from "../Components/SmartContract/ContractBtn";
import ContractForm from "../Components/SmartContract/ContractForm";
import { openCustomNotificationWithIcon } from "../utils/Notification";
import { getDateInFormat } from "../utils/dateFormat";
import { CopyOutlined } from "@ant-design/icons";
import getImageFromIPFS from "../utils/ipfs_image";
import { motion as m } from "framer-motion";


var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

let todayDate = mm + "/" + dd + "/" + yyyy;
const ProjectList = () => {
  const allProjects = useSelector((state) => state.storefiles.projects);
  const { isLoggedin, user: userdata } = useSelector(user);
  console.log(allProjects, "PROJECTSssssssssssssss");
  const projectStatus = useSelector((state) => state.storefiles.projectCreated);
  const files = useSelector((state) => state.storefiles.file);

  const [item, setitem] = useState([]);
  const navigate = useNavigate();
  const { history } = useNavigate();
  const loadingpop = useSelector((state) => state.storefiles.loadingPopup);
  const dispatch = useDispatch();

  // useEffect(() => {

  //   if(allProjects.length===0){
  //     navigate("/noproject")
  //   }

  // }, [allProjects])

  //   const router = useRouter()

  // useEffect(() => {

  //   const item = [state]
  //   if(!item[0]?.folderName){
  //     navigate("/noproject")

  //   }
  //   setitem(item)
  // }, [])

  useEffect(() => {
    // dispatch(setLoadingPopup(false))
    dispatch(getProjects());
    dispatch(getSubsListThunk(userdata.stripeCustomerId));
    dispatch(setRefresh());
  }, [loadingpop]);

  // useEffect(() => {
  // getImgUriFromTokenUriHash("QmUYy1i6Pq2vZdVwmhjAT6aDXYKMdaNYXrKstxzNYzCQQ9");
  // }, []);

  // const [imageUrl, setImageUrl] = useState(null);

  // useEffect(() => {
  //   async function fetchImage() {
  //     const url = await getImageFromIPFS("QmVJeCBHsKKhDwZMECUpS5UBhtN5FvMRuirq78CyZqyrJH")
  //     setImageUrl(url);
  //   }
  //   fetchImage();
  // }, []);

  // console.log(imageUrl, "abdashbdahsbdahanajsnancac");

  const handleMint = (address, cid) => {
    console.log("heleeellllooooo");
    navigate("/mint-page-select", { state: { cid, address } });
  };

  // console.log(files, "filesssssssssss");

  // const [contract, setContract] = useState({
  //   openForm: false,
  //   cid: null,
  // });

  //   const handleContract = (cid) => {
  //       setContract({
  //         openForm: true,
  //         cid: cid
  //       });
  //   }

  //   const handleCancel = () => {
  //     setContract({
  //       openForm: false,
  //       cid: null,
  //     })
  //  }

  // if(contract.openForm){
  //   if(contract.cid){
  //     return <ContractForm cid={contract.cid} cancel={handleCancel} />
  //   }else{
  //     handleCancel();
  //     openCustomNotificationWithIcon("error", "Invalid CID")
  //   }
  // }
  return (
    <m.div initial={{opacity: 0.0}} animate={{opacity: 2}} transition={{duration: 1, ease: "easeOut"}} className="container mt-4 h-full w-10/12 mx-auto md:max-w-11/12 bg-white px-10 max-h-full items-center">
      {allProjects?.length > 0 ? (
        <div className="pb-8">
          <nav className="grid grid-cols-2 mt-5">
            <div className="text-2xl font-medium mt-4">Projects</div>
            <div className="text-right items-center">
              <Link
                type="button"
                to="/createproject/?y=manual"
                className="mt-4 inline-flex items-center rounded-3xl border ml-4  px-4 py-3 text-sm  leading-4 bg-orange-500 text-white font-medium border-gray-300 hover"
              >
                <PlusIcon
                  className="-ml-0.5 mr-2 h-4 w-4 font-bold text-white"
                  aria-hidden="true"
                />
                Upload
              </Link>
            </div>
          </nav>
          <div className="mt-8 flex flex-col item">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          CID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Format
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Size
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Deployed Contract
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Mint
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                        >
                          Smart Contract
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {allProjects?.map((project) => (
                        <tr key={project.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {project.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-center">
                            <a
                              rel="noreferrer"
                              href={`https://ipfs.io/ipfs/${project?.CID}`}
                              target="_blank"
                              style={{
                                color: "blue",
                                display: "inline-block",
                                width: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {project?.CID}
                            </a>
                            <CopyButton cid={project?.CID} tooltip="Copy CID" />
                            {/* <button style={{ border: '1px solid blue', }} className=" text-xs ml-10 px-2 py-1 rounded">Copy</button> */}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {project?.type}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {project?.size}MBs
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {getDateInFormat(project.createdAt)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {project.address ? (
                              <div className="flex items-center">
                                <a
                                  rel="noreferrer"
                                  href={`https://etherscan.io/address/${project?.address}`}
                                  target="_blank"
                                  style={{
                                    color: "blue",
                                    display: "inline-block",
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {project?.address}
                                </a>
                                <CopyButton
                                  cid={project?.address}
                                  tooltip="Copy Address"
                                />
                              </div>
                            ) : (
                              <>Not Deployed</>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {project?.layout ? (
                              <a
                                rel="noreferrer"
                                href={`${process.env.REACT_APP_BAKREE_API_URL}/mint/${project?.id}`}
                                target="_blank"
                                style={{
                                  color: "blue",
                                  display: "inline-block",
                                }}
                              >
                                Watch Your Mint Page
                              </a>
                            ) : (
                              <>
                                {project?.address ? (
                                  <p
                                    onClick={() =>
                                      handleMint(project?.address, project?.CID)
                                    }
                                    // to={"/mint-page-select"}
                                    className="text-green-500 mb-0 cursor-pointer py-1 px-2 hover:bg-green-600 hover:text-white focus:text-white focus:bg-green-600 rounded-md transition-all duration-300 ease-in-out"
                                  >
                                    Mint Your Contract
                                  </p>
                                ) : (
                                  <p className="mb-0">
                                    {" "}
                                    Contract Not Deployed{" "}
                                  </p>
                                )}
                              </>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {/* <button onClick={() => handleContract(project?.CID)} 
                              className="text-white bg-indigo-600 pt-2 pb-2 pl-4 pr-4 hover:bg-indigo-800 focus:bg-indigo-800 rounded-full"
                          >Contract</button> */}
                            <ContractBtn
                              cid={project?.CID}
                              projectId={project?.id}
                            />
                          </td>
                          <td className="relative whitespace-nowrap flex items-center min-h-[68.5px] py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Dropdown project={project} />
                            {/* <span className="sr-only">, {project.name}</span> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col no-project-height">
          <div>
            <img src={noproject} alt="" width={300} height={300} />
          </div>
          <div className="mt-4">OHH! Don’t have any project yet?</div>

          <button
            type="button"
            onClick={() => {
              navigate("/createproject/?y=manual");
            }}
            className="inline-block px-8 py-2.5 mt-4 rounded-3xl bg-blue-600 text-white font-medium text-xs leading-tight   shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            {"Let's Create"}
          </button>
        </div>
      )}
</m.div>
  );
};

const CopyButton = ({ cid, tooltip }) => {
  let [titleCopy, setTitleCopy] = useState(tooltip);
  const handleCopyText = (cid) => {
    navigator.clipboard.writeText(cid);
    setTitleCopy("Copied");
  };
  return (
    <Tooltip title={titleCopy}>
      <Button
        onClick={() => handleCopyText(cid)}
        style={{
          marginLeft: "10px",
          borderRadius: "6px",
          display: "inline-block",
          width: "20px",
          height: "20px",
        }}
        icon={<CopyOutlined />}
      />
    </Tooltip>
  );
};

export default ProjectList;
