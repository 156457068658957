import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFoldername } from "../../Slices/data";
import { openCustomNotificationWithIcon } from "../../utils/Notification";

const Details = ({ handleAddFolderName, rocket }) => {
  const [folderName, setfolderName] = useState("");
  const dispatch = useDispatch();
  const handlefolder = () => {
    if (folderName.length < 1) {
      openCustomNotificationWithIcon("error", "Folder name is required");
    } else {
      dispatch(setFoldername(folderName));
      handleAddFolderName(2);
    }
  };
  // dispatch(setFoldername(folderName))
  return (
    <div className="flex items-center py-8 md:px-14 justify-center md:justify-start">
      <div className="mt-3 sm:mt-0 hidden sm:block md:hidden min-w-[150px] sm:w-[40%] ml-16">
        <img src={rocket} width={300} height={300} alt="" />
      </div>
      <div className="flex flex-col ml-8 sm:ml-10 md:ml-1 sm:w-[60%]">
        <h1 className="font-medium">Details</h1>
        <p className="w-4/6 text-xs text-gray-400 mb-8">
        Please provide name of your folder or the file to display on IPFS.
        </p>

        <input type="text"
          className="form-control block min-w-4/6 max-w-[230px] px-3 py-1.5 text-base font-normal text-gray-700
          bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl transition 
          ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="exampleFormControlInput1"
          required={true}
          placeholder="Name"
          onChange={(e) => {
            setfolderName(e.target.value);
          }}
        />
        <div>
          <button
            type="button"
            onClick={handlefolder}
            className="inline-flex items-center rounded-3xl border mt-4 bg-blue-700 text-white  px-4 py-2 text-sm font-medium leading-4 border-gray-300 "
          >
            Upload
          </button>
        </div>

      </div>
    </div>
  );
};

export default Details;
