import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Slices/Auth";
import StoreFiles from "./Slices/data";
import stripeReducer from "./Slices/Stripe";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import  generatorReducer  from "./Slices/GeneratorSlice";
import smartContractReducer from  './Slices/contract';
import Layers from "./Slices/layer";
import  GetNotification, { getAllNotification } from "./Slices/Notification";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  storefiles: StoreFiles,
  stripe: stripeReducer,
  generator: generatorReducer,
  ImageRarity: generatorReducer,
  smartContract: smartContractReducer,
  Layers: Layers,
  notifications: GetNotification,

});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
